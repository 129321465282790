import { useModalReturnType, useOne } from "@refinedev/core";
import FormFieldUser from "components/pages/users/FormFieldUser";
import { Modal } from "components/popups";
import { useEffect, useState } from "react";
import { useForm } from "@refinedev/react-hook-form";
import { USERS_CONFIG } from "resources-config/users-config";
import {
  capitalizeStr,
  getErrorNotif,
  getSuccessNotif,
} from "utils/data-formatter";
import { useParams } from "react-router-dom";
import { handleMutation } from "utils/handle-mutation";
import { FormsGrid, SelectField, TextField } from "components/forms";
import { Button } from "components/buttons";
import { baseApi } from "providers/customDataProvider";
import { toast } from "sonner";
import { formatAddressAny } from "utils/address";
type Props = {
  modal: useModalReturnType;
  id: any;
  userId: any;
};
type FieldType = {
  name: string;
  email: string;
  phoneNumber: string;
  addresses: { [key: string]: string }[];
  address: any;
  birthDate?: string;
};

const EditProfileModal = (props: Props) => {
  const params = useParams();
  const { refetch: refetchUser } = useOne({
    resource: USERS_CONFIG.name,
    id: params.id,
  });
  const { modal, id, userId } = props;
  const [showForm, setShowForm] = useState(false);
  const [btnDisabled, disableBtn] = useState(false);

  const [defaultUnitType, setDefaultUnitType] = useState<string[]>();
  const {
    refineCore: { onFinish, queryResult, mutationResult },
    handleSubmit,
    control,
    setError,
    clearErrors,
    setValue,
    watch,
  } = useForm<MiscObj>({
    refineCoreProps: {
      resource: USERS_CONFIG.name,
      action: "edit",
      id: params.id,
      successNotification(data: any) {
        let actionUrl = undefined;
        if (data && data.data) {
          actionUrl = `/users/${data.data.id}`;
        }
        return getSuccessNotif("update", "user", actionUrl);
      },
      errorNotification(data: any) {
        return getErrorNotif("update", "user", data);
      },
    },
  });

  const onFinishHandler: any = async (data: FieldType) => {
    try {
      await onFinish({
        ...data,
        email: data.email ?? null,
        birthDate: !data.birthDate ? null : data.birthDate,
      });
      if (userId && data.address) {
        await handleSetDefault(userId, data.address);
      }
    } catch (err) {
      console.error("Error in onFinishHandler:", err);
    }
  };

  useEffect(() => {
    if (!queryResult || !queryResult.data || showForm) return;

    setDefaultUnitType(
      queryResult.data.data.addresses.map((addr: any) => addr.unitType)
    );
    setShowForm(true);
  }, [queryResult]);

  useEffect(() => {
    handleMutation(mutationResult, setError, clearErrors, disableBtn);
  }, [mutationResult, setError, clearErrors, disableBtn]);

  useEffect(() => {
    if (queryResult?.data?.data && !showForm) {
      const userData = queryResult.data.data;
      const defaultAddress = userData.addresses.find(
        (address: any) => address.isDefault
      );

      setValue("name", userData.name);
      setValue("email", userData.email);
      setValue("phoneNumber", userData.phoneNumber);
      setValue("birthDate", userData.birthDate);
      setValue("address", defaultAddress ? defaultAddress.id : null);
      setShowForm(true);
    }
  }, [queryResult, setValue, showForm]);

  const handleSetDefault = async (userId: any, addressId: number) => {
    try {
      await baseApi.post(
        `/admin/users/${userId}/addresses/${addressId}/default`
      );
    } catch (err: any) {
      console.error("Failed to set default address:", err);
      toast.error("Failed to update primary address!");
    } finally {
      refetchUser();
    }
  };



  return (
    <Modal
      visible={modal.visible}
      close={modal.close}
      heading={`Edit Customer Profile - ${id}`}
      subheading="Edit customer profile data will also change on the customer side"
    >
      <div>
        <form
          onSubmit={handleSubmit((data: any) =>
            onFinishHandler({
              ...data,
              phoneNumber: `${
                data.phoneNumber[0] == "+"
                  ? data.phoneNumber
                  : `+${data.phoneNumber}`
              }`,
            })
          )}
          className="flex flex-col gap-4"
          id={"user-form"}
        >
          <TextField
            control={control}
            fieldName="name"
            required
            label={"Name"}
          />
          <TextField
            control={control}
            fieldName="phoneNumber"
            required
            label={"Contact Number"}
          />
          <TextField
            control={control}
            fieldName="email"
            regex="email"
            label={"Email"}
          />
          <SelectField
            label="Primary Address"
            control={control}
            fieldName="address"
            options={queryResult?.data?.data.addresses.map((data: any) => ({
              value: data.id,
              label: formatAddressAny(data),
            }))}
          />
          <FormsGrid>
            <GenderField
              control={control}
              queryResult={queryResult}
              setValue={setValue}
            />
            <TextField
              control={control}
              fieldName="birthDate"
              label={"Birth Date"}
              type="date"
            />
          </FormsGrid>
          <Button type="submit" form="user-form" isLoading={btnDisabled}>
            Save Changes
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default EditProfileModal;

const GenderField = ({
  control,
  queryResult,
  setValue,
}: {
  control: FormFieldType["control"];
  setValue: FormFieldType["setValue"];
  queryResult: FormFieldType["queryResult"];
}) => {
  const [defaultValue, setDefaultValue] = useState<
    SelectSingleVal | undefined | null
  >(undefined);

  useEffect(() => {
    if (defaultValue === undefined) {
      const defaultVal = queryResult.data
        ? queryResult.data.data.gender || null
        : null;
      if (
        queryResult.fetchStatus === "idle" &&
        queryResult.isFetched === true
      ) {
        setDefaultValue(
          !defaultVal
            ? null
            : { value: defaultVal, label: capitalizeStr(defaultVal) }
        );
      } else if (
        queryResult.fetchStatus === "idle" &&
        queryResult.isFetched === false
      ) {
        setDefaultValue(null);
      }
    }
  }, [queryResult, defaultValue]);

  return defaultValue === undefined ? (
    <></>
  ) : (
    <SelectField
      label="Gender"
      control={control}
      fieldName="gender"
      defaultCfg={{ value: defaultValue, setValue: setValue }}
      options={[
        { value: "male", label: "Male" },
        { value: "female", label: "Female" },
      ]}
    />
  );
};
