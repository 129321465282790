import { useTable } from "@refinedev/core";
import { getUserCreditsHistory } from "api/users";
import Badge from "components/Badge";
import EmptySection from "components/EmptySection";
import { TextField } from "components/forms";
import Pagination from "components/Pagination";
import { SearchIcon } from "components/Svg";
import { Table, TBody, Td, Th, THead } from "components/Table";
import TabletWidgets, {
  TabletWidgetLeft,
  TabletWidgetRight,
} from "components/TableWidgets";
import { useCallback, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { formatFullDate } from "utils/date";
import useTableQuery from "utils/useTableQuery";

const ShowUserCreditsList = ({ userId }: { userId?: any }) => {
  const {
    tableQueryResult,
    pageSize,
    current,
    setCurrent,
    sorters,
    setSorters,
    filters,
    setFilters,
  } = useTable({
    queryOptions: { retry: false },
    pagination: { current: 1, pageSize: 10 },
    resource: `user/${userId}/balance-transactions`,
    filters: {
      initial: [
        {
          field: "userId",
          operator: "eq",
          value: userId,
        },
      ],
    },
    sorters: {
      initial: [
        {
          field: "createdAt",
          order: "desc", 
        },
      ],
    },
  });
  

  const changePage = useCallback(
    (page: number | string) => {
      setCurrent(+page);
    },
    [setCurrent]
  );

  const { control: controlSearch, doFilter } = useTableQuery(
    filters,
    setFilters
  );

  return (
    <>
      <TabletWidgets>
        <TabletWidgetLeft>
          <TextField
            Icon={<SearchIcon />}
            control={controlSearch}
            fieldName="search"
            placeholder="Search by Order ID"
            className="w-full"
            handleEnter={doFilter}
          />
        </TabletWidgetLeft>
        <TabletWidgetRight>
          <div className="flex gap-2 items-center">
            <Badge color="#9DC7F1" className="!text-[#0B5CAD]">
              {tableQueryResult?.data?.data.length || 0}
            </Badge>
            <span className="text-th-sm">Records found</span>
          </div>
        </TabletWidgetRight>
      </TabletWidgets>
      {tableQueryResult.data && tableQueryResult.data.data.length > 0 ? (
        <Table>
          <THead>
            <Th
              sorting={{
                column: "createdAt",
                sorters: sorters,
                setSorters: setSorters,
              }}
            >
              Transaction Date
            </Th>
            <Th>Transaction By</Th>
            <Th>Order ID</Th>
            <Th>Transaction Type</Th>
            <Th>Amount</Th>
            <Th>Action</Th>
          </THead>

          {tableQueryResult.data.data.length > 0
            ? tableQueryResult.data.data.map((item: any) => (
                <TBody key={item.id}>
                  <Td>{formatFullDate(item.createdAt, { showTime: false })}</Td>
                  <Td>
                    {item.invoiceHistory?.createdBy ||
                      item.creatingAdmin?.name ||
                      "-"}
                  </Td>
                  <Td>{item.invoiceHistory?.invoice?.order?.id || "-"}</Td>
                  <Td>
                    {item.invoiceHistory == null && item.type == 2 ? (
                      <Badge color="#BB032A">Customer Withdrawal</Badge>
                    ) : item.invoiceHistory != null && item.type == 2 ? (
                      <Badge color="#24663B">Payment Refund</Badge>
                    ) : item.invoiceHistory != null && item.type == 1 ? (
                      <Badge color="#BB032A">Service Purchase</Badge>
                    ) : (
                      "-"
                    )}
                  </Td>

                  {item.type === 1 ? (
                    <Td className="text-success font-medium">
                      {" "}
                      + ${item.amount}
                    </Td>
                  ) : (
                    <Td className="text-danger font-medium">
                      {" "}
                      - ${item.amount}
                    </Td>
                  )}
                  <Td>
                    <NavLink
                      type="button"
                      className="text-sm font-medium text-primary"
                      to={`/service-orders/${item?.invoice?.order?.id}` }
                    >
                      View
                    </NavLink>
                  </Td>
                </TBody>
              ))
            : "null"}
        </Table>
      ) : (
        <EmptySection
          title="No Credit Transaction"
          body="Customer has never made a transaction using credit"
        />
      )}
      <div className="justify-end mt-4 flex-ct-y">
        <Pagination
          pageSize={pageSize}
          currentPage={current}
          totalItems={tableQueryResult.data?.total || 0}
          clickHandler={changePage}
          range={5}
        />
      </div>
    </>
  );
};

export default ShowUserCreditsList;
