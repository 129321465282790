import { DataProvider } from "@refinedev/core";
import axios from "axios";
import { CrudFilters } from "@refinedev/core";
import { stringify } from "query-string"
import { MetaApiEndpointsType, ResourceMetaType } from "resources-config";

// Error handling with axios interceptors
const axiosInstance = axios.create({
    withCredentials: true, headers: {
        'X-Source-Type': 'web-admin'
    }
});

export const baseApi = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL || '',
    withCredentials: true,
    headers: {
        'X-Source-Type': 'web-admin'
    }
})

baseApi.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const customError: CustomErrorType = {
            ...error,
            message: error.response?.data?.message,
            statusCode: error.response?.status,
        };

        if (customError.statusCode.toString() == '401' && window.location.pathname != '/login') {
            window.location.href = '/login'
        }
        // return customError
        return Promise.reject(customError);
    },
);

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const customError: CustomErrorType = {
            ...error,
            message: error.response?.data?.message,
            statusCode: error.response?.status,
        };
        // return customError
        return Promise.reject(customError);
    },
);

const customDataProvider = (apiUrl: string): DataProvider => ({
    getList: async ({ resource, pagination, sorters, filters, meta }) => {
        const endpoint = generateApiEndpoint(resource, 'getList', meta)
        const url = `${apiUrl}/${endpoint}`;

        const { current = 1, pageSize = 10 } = pagination ?? {};

        const query: {
            page?: number; pageSize?: number; sort?: string; order?: string;
        } = {
            page: current,
            pageSize: pageSize,
        };
        let sortersArr: string[] = []
        if (sorters) {
            sorters.forEach((sorter, index) => {
                sortersArr.push(`sort[${index}][field]=${sorter.field}&sort[${index}][order]=${sorter.order.toUpperCase()}`)
            })
        }
        const queryFilters = generateFilters(filters);
        const { data, headers } = await axiosInstance.get(
            `${url}?${stringify(query)}&${stringify(queryFilters)}${sorters ? '&' + sortersArr.join('&') : ''}`
        );

        return {
            data: data.list || data,
            total: data.total || undefined
        };
    },

    create: async ({ resource, variables, meta }) => {
        const endpoint = generateApiEndpoint(resource, 'create', meta)
        const url = `${apiUrl}/${endpoint}`;

        const { data } = await axiosInstance.post(url, variables);

        return {
            data,
        };
    },

    update: async ({ resource, id, variables, meta }) => {
        const endpoint = generateApiEndpoint(resource, 'update', meta)
        const url = `${apiUrl}/${endpoint}/${id}`;

        const { data } = await axiosInstance.patch(url, variables);

        return {
            data,
        };
    },

    deleteOne: async ({ resource, id, variables, meta }) => {
        const endpoint = generateApiEndpoint(resource, 'deleteOne', meta)
        const url = `${apiUrl}/${endpoint}/${id}`;

        const { data } = await axiosInstance.delete(url, {
            data: variables,
        });

        return {
            data,
        };
    },

    getOne: async ({ resource, id, meta }) => {
        const endpoint = generateApiEndpoint(resource, 'getOne', meta)
        const url = `${apiUrl}/${endpoint}/${id}`;

        const { data } = await axiosInstance.get(url);

        return {
            data,
        };
    },

    custom: async ({ url, method, filters, sorters, payload, query, headers, meta, }): Promise<any> => {
        let response = null
        url = `${apiUrl}/${url}`;
        switch (method) {
            case 'get':
                const queryStr = query ? stringify(query) : ''
                const sorter: { sort?: string; order?: string; } = {};
                if (sorters && sorters.length > 0) {
                    sorter.sort = sorters[0].field;
                    sorter.order = sorters[0].order;
                }
                const queryFilters = generateFilters(filters);
                url += `?${queryStr}&${stringify(queryFilters)}`
                const { data } = await axiosInstance.get(url);
                response = data
                break;
            case 'post':
                break;
            case 'put':
                break;
            case 'patch':
                break;
            case 'delete':
                break;
            default:
                break;
        }
        return response;
    },

    getApiUrl: () => apiUrl,
});

const generateFilters = (filters?: CrudFilters) => {
    const queryFilters: { [key: string]: string | string[] } = {};

    filters?.forEach((filter): void => {
        if ("field" in filter) {
            const { field, value } = filter;
            if (Object.hasOwn(queryFilters, field)) {
                queryFilters[`${field}`] = [
                    queryFilters[`${field}`], value
                ]
            }
            else {
                queryFilters[`${field}`] = value;
            }
        }
    });

    return queryFilters;
};

const generateApiEndpoint = (resource: string, action: keyof MetaApiEndpointsType, meta?: ResourceMetaType) => {
    let endpoint: string | undefined = resource
    if (meta && meta.apiEndpoints) {
        endpoint = meta.apiEndpoints[action]?.split('/').filter(path => path !== '').join('/')
    }
    return endpoint || resource
}

// const mapOperator = (operator: CrudOperators): string => {
//     switch (operator) {
//         case "ne":
//         case "gte":
//         case "lte":
//             return `_${operator}`;
//         case "contains":
//             return "_like";
//         case "eq":
//         default:
//             return "";
//     }
// };

export type CustomErrorType = {
    message: { field: string, error: string[] }[]
    statusCode: number
    [key: string]: any
}

export default customDataProvider