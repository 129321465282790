import { placesServiceConfig } from "components/consts/map"
import { Modal } from "components/popups"
import PopUp, { PopUpProps } from "components/popups/PopUp"
import { useState, useEffect, ChangeEvent } from "react"
import { extractPlaceDetails, PlaceInterface } from "utils/map"
import AddressLocation from "./AddressLocation"
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { CloseIcon, CurrentLocationIcon } from "components/Svg"
import { IoChevronBack, IoSearch } from "react-icons/io5"

interface Props extends PopUpProps {
    openPinPoint: () => void
    handleSetAddress: (place: PlaceInterface) => void
    noticeBannerText?: string
}

const FindAddressPopup = (props: Props) => {
    const { visible, close, openPinPoint, handleSetAddress, noticeBannerText } = props
    const [placeOptions, setPlaceOptions] = useState<PlaceInterface[]>([])
    const [isNoticeBannerShown, setIsNoticeBannerShown] = useState(noticeBannerText != undefined)
    const { placesService, placePredictions, getPlacePredictions } = usePlacesService(placesServiceConfig)



    useEffect(() => {
        if (placePredictions.length > 0 && placesService) {
            setPlaceOptions([])
            placePredictions.forEach(place => {
                placesService.getDetails(
                    {
                        placeId: place.place_id,
                    },
                    (placeDetails) => {
                        setPlaceOptions(state => [...state, extractPlaceDetails(placeDetails)])
                    }
                )
            })
        }
    }, [placePredictions]);

    const handleChoosePlace = (place: PlaceInterface) => {
        handleSetAddress(place)
        close()
    }

    const handleSearchPrediction = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.value) {
            getPlacePredictions({ input: e.currentTarget.value })
        }
        else {
            getPlacePredictions({ input: '' })
            setPlaceOptions([])
        }
    }

    return <PopUp mobileFullScreen visible={visible} close={close} className="" width={'41.325rem'} drawerView>
        <div className="flex flex-col h-full tablet-below:pb-4">
            <div className="tablet-below:hidden flex mb-6 items-center justify-between">
                <p className="text-th-lg font-bold">Find Address Location</p>
                <button type='button' onClick={close} >
                    <CloseIcon className="w-8 cursor-pointer" />
                </button>
            </div>
            <div className='tablet-below:flex py-8 gap-10 justify-between items-center hidden section-bg-2'>
                <div className="flex items-center gap-2">
                    <button type="button" onClick={close}>
                        <div>
                            <IoChevronBack />
                        </div>
                    </button>
                    <p className='text-th-lg font-bold'>Find the Address</p>
                </div>
            </div>
            <div className="relative flex items-start tablet-below:pt-6 z-10  w-full h-full  tablet-below:px-6 pb-4">
                <div className=" w-[100%] flex flex-col gap-4">
                    <div className='flex items-center rounded-lg border-[0.032rem] px-4 py-2'>
                        <div className='flex mr-2'>
                            <IoSearch />
                        </div>
                        <input
                            onChange={handleSearchPrediction}
                            type="text"
                            className=" w-full text-th-sm placeholder-neutral"
                            placeholder="Search address e.g. #02-32 Northpoint Bizhub" >
                        </input>
                    </div>
                    <button type='button' className='flex group gap-3 items-center text-primary rounded-lg border border-primary px-4 py-2 hover:bg-primary hover:text-white transition duration-300' onClick={() => { openPinPoint(); close(); }}>
                        <div>
                            <CurrentLocationIcon color='undefined' className='fill-primary group-hover:fill-white' />
                        </div>
                        <p className=" text-th-sm font-medium">Search Using Maps</p>
                    </button>
                    {placeOptions.length > 0 ?
                        <div className="h-full">
                            {placeOptions.map(place => (
                                <button key={place.id} onClick={() => handleChoosePlace(place)} type="button" className="w-full text-start hover:bg-[#F1F1F1] transition duration-300">
                                    <AddressLocation name={place.name || ''} address={place.address || ''} />
                                </button>
                            ))}
                        </div>
                        :
                        <div className='flex flex-col items-center'>
                            {/* <div className="w-[8.5rem] h-[11.938rem] relative">
                                <Image src={twoGuys} alt='woman' fill className="" />
                            </div> */}
                            <div className="flex flex-col items-center mt-6 tablet-below:mt-[2.5rem] tablet-below:justify-center ">
                                <p className="font-bold text-th-lg">Oops, address not found.</p>
                                <p className="text-light mobile-below:text-th-xs mt-4 text-center text-th-xs">Double-check your address and <br />please try again.</p>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {noticeBannerText && isNoticeBannerShown &&
                <div className="flex px-4 py-2 bg-[#FEF0C7] rounded-lg border-[0.031rem] border-[#EECEB0] justify-between items-center w-full mt-[2rem]">
                    <p className="text-[#5B4407] text-th-xs">{noticeBannerText}</p>
                    <button type='button' onClick={() => setIsNoticeBannerShown(false)}>
                        <CloseIcon className='w-4' />
                    </button>
                </div>
            }
        </div>
    </PopUp>
}

export default FindAddressPopup