import { Controller } from "react-hook-form"
import { BaseFormProps } from "./types"

type Props = BaseFormProps & {
    options: { value: any, label: string }[]
    showLabel?: boolean
}

const RadioField = (props: Props) => {
    const { control, fieldName, options, required, label, showLabel = true, defaultValue, ...rest } = props

    return (
        <Controller
            control={control}
            name={fieldName}
            defaultValue={defaultValue}
            rules={{ required: required ? `${label || 'Field'} is required.` : undefined }}
            render={({ field: { onChange, value } }) => (
                <>
                    {options.map(({ value: _value, label }) => (
                        <div key={_value} className="flex items-center justify-between gap-y-4 mb-4">
                            <label htmlFor={_value} className="mr-2">{label}</label>
                            <input
                                {...rest}
                                onChange={(e) => onChange(e.currentTarget.value)}
                                value={_value}
                                checked={value === _value}
                                type="radio"
                                id={_value}
                                className="ml-auto"
                            />
                        </div>
                    ))}
                </>
            )}
        />
    )
}

export default RadioField
