import { getServiceDetails } from "api/service"
import { round } from "lodash"
import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from "react"
import { toast } from "sonner"

type Props = {
    children: ReactNode
    categoryId: string
    serviceId: string
    setAvailableCategories: Dispatch<SetStateAction<any[]>>
    setAvailableFrequencies: Dispatch<SetStateAction<any[]>>
    setServiceDetail?: Dispatch<SetStateAction<MiscObj>>
    setInitialPricePerUnit?: Dispatch<SetStateAction<number>>
    setValue: FormHookType['setValue']
    name: string
    availableFrequencies: any[]
    quantity: number
    frequencyId: string,
    pricePerUnit: number
}

const ChooseItemEffects = (props: Props) => {
    const { children, serviceId, categoryId, setAvailableCategories, setValue, setAvailableFrequencies, setServiceDetail, name, availableFrequencies, quantity, frequencyId, pricePerUnit, setInitialPricePerUnit } = props
    const [frequenciesList, setFrequenciesList] = useState<any[]>([])

    useEffect(() => {
        if (!serviceId) return
        getServiceDetails(serviceId)
            .then(serviceDetail => {
                const _availableCategories: any[] = []
                const _frequenciesList: any = []
                serviceDetail.frequencies.map((freq: any) => {
                    _frequenciesList.push(freq)
                    if (!_availableCategories.find(category => category.id == freq.category.id))
                        _availableCategories.push(freq.category)
                })
                setFrequenciesList(_frequenciesList)
                setAvailableCategories(_availableCategories)
                if (setServiceDetail) setServiceDetail(serviceDetail)
            })
            .catch(err => {
                console.error(err)
            })
    }, [serviceId])

    useEffect(() => {
        setValue(`${name}quantity`, 1)
        setAvailableFrequencies(frequenciesList.filter(freq => freq.category.id == categoryId))
    }, [categoryId])

    useEffect(() => {
        try {
            const selectedFrequency = availableFrequencies.find((freq: any) => freq.id == frequencyId)
            if (!selectedFrequency) return
            let selectedPricing = selectedFrequency.pricings.find((pricing: any) => pricing.quantity == quantity)
            if (!selectedPricing) {
                if (quantity == 1)
                    selectedPricing = selectedFrequency.pricings[0]
                else
                    selectedPricing = selectedFrequency.pricings.sort((a: any, b: any) => a.quantity - b.quantity)[selectedFrequency.pricings.length - 1]
            }
            const pricePerUnit = selectedPricing.price / selectedFrequency.frequency / selectedPricing.quantity
            setValue(`${name}pricePerUnit`, pricePerUnit)
            setInitialPricePerUnit && setInitialPricePerUnit(pricePerUnit)
        }
        catch (err) {
            console.error(err)
        }
    }, [quantity, frequencyId])

    useEffect(() => {
        try {
            if (!frequencyId || !pricePerUnit) return
            const selectedFrequency = availableFrequencies.find((freq: any) => freq.id == frequencyId)
            if (!selectedFrequency) return
            setValue(`${name}price`, round(pricePerUnit * quantity * selectedFrequency.frequency, 2))
        }
        catch (err) {
            console.error(err)
            toast.error('Error occured. Please contact support.')
        }
    }, [pricePerUnit, quantity])

    return <>
        {children}
    </>
}

export default ChooseItemEffects