import { EmptySectionIcon } from "./Svg";

type Props = {
  title?: string;
  body?: string;
};
const EmptySection = (props: Props) => {
  const { title, body } = props;
  return (
    <div className="flex flex-col justify-center items-center">
      <EmptySectionIcon />
      <h3 className="text-th-base font-medium text-[#404040]">
        {title ? title : "No Order History Found"}
      </h3>
      <p className="text-light">
        {body ? body : "Customer has never placed an order for airple service"}
      </p>
    </div>
  );
};

export default EmptySection;
