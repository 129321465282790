import { useTable, BaseKey, useModal } from "@refinedev/core"
import { Button } from "components/buttons"
import { BaseCard } from "components/cards"
import ConfirmationModal from "components/custom-modals/ConfirmationModal"
import { TextField } from "components/forms"
import PageTitle from "components/PageTitle"
import Pagination from "components/Pagination"
import Pill from "components/Pill"
import { MenuDotsIcon, SearchIcon } from "components/Svg"
import { Table, THead, Th, TBody, Td, TAction, TActionItem } from "components/Table"
import TabletWidgets, { TabletWidgetLeft, TabletWidgetRight } from "components/TableWidgets"
import { baseApi } from "providers/customDataProvider"
import { useCallback, useMemo, useState } from "react"
import { NavLink } from "react-router-dom"
import { ADMIN_FOLLOW_UP_CONFIG } from "resources-config/admin-follow-up-config"
import { toast } from "sonner"
import { formatAddressAny, formatFullAddress } from "utils/address"
import { formatFullDate } from "utils/date"
import useTableQuery from "utils/useTableQuery"


const ListAdminFollowUp = () => {
    const {
        tableQueryResult, pageSize, current, setCurrent, setFilters, filters,
        sorters, setSorters,
    } = useTable({
        queryOptions: { retry: false, },
        pagination: { current: 1, pageSize: 20 },
        resource: ADMIN_FOLLOW_UP_CONFIG.name,
    })
    const { control, doFilter } = useTableQuery(filters, setFilters)
    const [reportId, setReportId] = useState<string | undefined>()
    const modalConfirmation = useModal()
    const changePage = useCallback((page: number | string) => {
        setCurrent(+page)
    }, [setCurrent])


    const handleMarkAsDone = async (reportId: string) => {
        try {
            const data = await baseApi.post(`admin/service-reports/${reportId}/resolve`)
        } catch (err: any) {
            toast.error(err.message)
            console.log(err)
        }
        finally {
            tableQueryResult.refetch()
        }
    }
    const totalItems =  tableQueryResult.data?.total || 0
    const totalPages = useMemo(() => (
        !totalItems || !pageSize ? 0 : Math.ceil(totalItems/pageSize)
    ), [totalItems, pageSize])

    return (<>
        <PageTitle />
        <BaseCard>
            <TabletWidgets>
                <TabletWidgetLeft>
                    <TextField
                        Icon={<SearchIcon />}
                        control={control} fieldName='search' placeholder="Search by ID, name, phone, address or technician"
                        className='w-full' handleEnter={doFilter}
                    />
                </TabletWidgetLeft>
                <TabletWidgetRight>
                    <Pill textColor='#0B5CAD' backgroundColor='#CBE2F9'>{tableQueryResult.data ? tableQueryResult.data.total : 0}</Pill>customers found
                </TabletWidgetRight>
            </TabletWidgets>
            <Table>
                <THead>
                    <Th sorting={{ column: 'orderId', sorters: sorters, setSorters: setSorters }}>Order ID</Th>
                    <Th sorting={{ column: 'scheduleId', sorters: sorters, setSorters: setSorters }}>Job ID</Th>
                    <Th>Technician Notes</Th>
                    <Th>Customer Name</Th>
                    <Th>Phone Number</Th>
                    <Th>Address</Th>
                    <Th>Services</Th>
                    <Th>Technician Name</Th>
                    <Th sorting={{ column: 'status', sorters: sorters, setSorters: setSorters }}>Status</Th>
                    <Th>Reported At</Th>
                    <Th>Action</Th>
                </THead>
                {!tableQueryResult.data ? [[]] : tableQueryResult.data.data.map((report, index) => (
                    <TBody key={index}>
                        <Td>OID{report.schedule.order.id}</Td>
                        <Td>JID{report.schedule.id}</Td>
                        <Td>{report.recommendation}</Td>
                        <Td>{report.schedule.user.name}</Td>
                        <Td>{report.schedule.user.phoneNumber}</Td>
                        <Td>{formatAddressAny(report.schedule.tasks[0]?.item.addresses[0])}</Td>
                        <Td>{report.schedule.tasks.map((task: any) => task.item.service.name).join(', ')}</Td>
                        <Td>{report.schedule.completingTechnicians.map((ct: any) => `${ct.technician.name}${ct.role ? ' (Leader)' : ''}`).join(',')}</Td>
                        <Td className="text-center">{report.respondingAdmin ? <Pill textColor="#24663B" backgroundColor="#CBE3D8">Responded</Pill> : <Pill textColor="#8F4700" backgroundColor="#E9BE74">Need Response</Pill>}</Td>
                        <Td>{formatFullDate(report.createdAt, { showDay: false, showTime: false })}</Td>
                        <TAction id={`report-${report.id}`} toggleText={<MenuDotsIcon />} className='border-none' >
                            <TActionItem>
                                <NavLink className={'text-center'} to={`/quotations/create?user=${report.schedule.user.id}`}>
                                    Create Quotation
                                </NavLink>
                            </TActionItem>
                            <TActionItem>
                                <NavLink  to={`/service-orders/create?user=${report.schedule.user.id}`}>
                                    Create Order
                                </NavLink>
                            </TActionItem>
                            {!report.respondingAdmin &&
                                <TActionItem>
                                    <Button className="text-start" shape="raw" onClick={() => { setReportId(report.id?.toString()); modalConfirmation.show() }}>
                                        Mark As Done
                                    </Button>
                                </TActionItem>
                            }
                        </TAction>
                    </TBody>
                ))}
            </Table>
            <div className='justify-between mt-4 flex-ct-y'>
            <span className='text-[#344054]'>Page {current} of {totalPages}</span>
                <Pagination
                    pageSize={pageSize} currentPage={current} totalItems={tableQueryResult.data?.total || 0}
                    clickHandler={changePage} range={5}
                />
            </div>
        </BaseCard>
        <ConfirmationModal
            modal={modalConfirmation}
            onSuccess={() => handleMarkAsDone(reportId || '')}
            title="Are you sure you want to do this ?"
            accentColor="primary"
        >
            You cannot change your decision after confirming.
        </ConfirmationModal>
    </>)
}

export default ListAdminFollowUp