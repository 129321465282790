import { CrudFilters } from "@refinedev/core"
import { Button, ButtonsGroup } from "components/buttons"
import { ButtonItem } from "components/buttons/ButtonsGroup"
import { BaseCard } from "components/cards"
import { ChevronRightIcon, FilterIcon, PlusScheduleIcon } from "components/Svg"
import { format, isSameDay } from "date-fns"
import { padStart } from "lodash"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { getFilterValue } from "utils/data-formatter"
import { formatFullDate } from "utils/date"

type Props = {
    view: 'month' | 'week' | 'day'
    changeView: (view: string) => void
    goToToday: () => void
    changePage: (direction: boolean) => void
    startDate: string
    filters: CrudFilters
    setFilters: ((filters: CrudFilters) => void) & ((setter: (prevFilters: CrudFilters) => CrudFilters) => void)
    className?: string
}

const INTERACTION_FILTER_KEYS = ['schedule', 'moveSchedule', 'order', 'teams']

const ScheduleHeader = (props: Props) => {
    const { view, changeView, goToToday, changePage, startDate, filters, setFilters, className = "sticky top-16" } = props
    const title = (view == 'month' ? 'Monthly' : view == 'week' ? 'Weekly' : 'Daily') + ' Schedule'
    const dateObjStartDate = new Date(startDate)
    const endWeek = padStart((dateObjStartDate.getDate() + 6).toString(), 2, '0')
    const date = view == 'month' ? format(startDate, 'MMMM yyyy') : view == 'week' ? `${format(startDate, `dd - ${endWeek} MMMM yyyy`)}` : formatFullDate(startDate, { showTime: false })


    const { schedule, moveSchedule, order, teams } = useMemo(() => {
        const schedule = getFilterValue(filters, 'schedule')
        const moveSchedule = getFilterValue(filters, 'moveSchedule')
        const order = getFilterValue(filters, 'order')
        const teams = getFilterValue(filters, 'teams')

        return { schedule, moveSchedule, order, teams }
    }, [filters])

    const showClearInteraction = schedule || moveSchedule || order || teams
    const navigate = useNavigate();
    const handleClearFilters = () => {
        // @ts-ignore
        setFilters(filters => filters.filter(filter => !INTERACTION_FILTER_KEYS.includes(filter.field)))
        // const currentUrl = window.location.href;
        // const url = new URL(currentUrl);
        // const hasJID = url.searchParams.has("JID");
    
        // if (hasJID) {
        //     url.searchParams.delete("JID");
        //     window.history.replaceState({}, "", url.toString());
        //     navigate(url.pathname + url.search); 

        // }
    }

    return <BaseCard className={`grid grid-cols-5 place-items-stretch text-body border ${className} z-50`}>
        <div id="scheduleHeader" className="flex flex-col justify-center bg-white">
            <ButtonsGroup color="gray" shape="outline">
                <ButtonItem isActive={isSameDay(startDate, new Date())} onClick={() => { goToToday() }}>Today</ButtonItem>
                <ButtonItem onClick={() => { changePage(false) }}>
                    <ChevronRightIcon width={22.4} height={22.4} className='rotate-[180deg]' />
                </ButtonItem>
                <ButtonItem onClick={() => { changePage(true) }}>
                    <ChevronRightIcon width={22.4} height={22.4} />
                </ButtonItem>
            </ButtonsGroup>
        </div>
        <div>
            <h3 className="font-medium text-th-lg">{date}</h3>
            <h5 className="text-th-sm">{title}</h5>
        </div>
        <div className="border-x-2 col-span-2 flex items-center justify-evenly">
            {showClearInteraction &&
                <Button onClick={handleClearFilters} shape="outline">
                    Clear Interaction
                    {/* ScheduleId: {schedule ?? '-'} || Move schedule Id: {moveSchedule ?? '-'} || OrderId:{order ?? '-'} || Teams: {teams ?? '-'} */}
                </Button>
            }
            <ButtonsGroup color="gray" shape="outline">
                <ButtonItem isActive={view == 'month'} onClick={() => { changeView('month') }}>Month</ButtonItem>
                <ButtonItem isActive={view == 'week'} onClick={() => { changeView('week') }}>Week</ButtonItem>
                <ButtonItem isActive={view == 'day'} onClick={() => { goToToday() }}>Day</ButtonItem>
            </ButtonsGroup>
        </div>
        <div className="flex justify-end self-center text-light">
            <Button href='/service-orders/create' tag="a" shape="filled" className="gap-2">
                <PlusScheduleIcon color="white" width={32} height={32} />
                Add New Order
            </Button>
        </div>
    </BaseCard>
}

export default ScheduleHeader