import { useModalReturnType, useCustom } from "@refinedev/core"
import { Button } from "components/buttons"
import { AsyncMultiSelectField, Checkbox, MultiSelectField, SelectField } from "components/forms"
import Switch from "components/forms/Switch"
import { Modal } from "components/popups"
import { addHours, addMinutes, format } from "date-fns"
import { flatten, padStart } from "lodash"
import { baseApi } from "providers/customDataProvider"
import { useState, useMemo, useEffect } from "react"
import { useForm } from "react-hook-form"
import { toast } from "sonner"
import { changeTz } from "utils/data-formatter"
import { generateTimeIntervals } from "utils/data-formatter/generate-time-interval"


function AssignTechModal({ modal, viewedTeam, refresh }: { modal: useModalReturnType, viewedTeam: { team: MiscObj, date: string, viewTs: number } | null, refresh: () => void }) {
    const { control, setValue, handleSubmit, watch, reset } = useForm()
    const [defaultValueLeaders, setDefaultValueLeaders] = useState<SelectMultiVal | undefined | null>(undefined)
    const [defaultValueAssistants, setDefaultValueAssistants] = useState<SelectMultiVal | undefined | null>(undefined)
    const [defaultValueBlockers, setDefaultValueBlockers] = useState<SelectMultiVal | undefined | null>(undefined)

    const technicians = watch()
    const blockAll = watch('blockAll')

    const selectedTechnicians = useMemo(() => {
        const leaderTechnicians = technicians.leaderTechnicians || []
        const assistantTechnicians = technicians.assistantTechnicians || []
        return [...leaderTechnicians, ...assistantTechnicians]
    }, [technicians])

    const { data, isFetching, isFetched, refetch } = useCustom({
        url: `service-schedules/team-assignments`,
        method: "get",
        config: {
            query: {
                team: viewedTeam?.team.id || 0,
                date: viewedTeam?.date || ''
            }
        }
    })

    useEffect(() => {
        if (!scheduleBlockOptions) return
        if (blockAll) setValue('hours', scheduleBlockOptions.map(opt => opt.value))
        else setValue('hours', [])
    }, [blockAll])

    const { scheduleBlockOptions } = useMemo(() => {
        if (!viewedTeam?.date) return {}

        const startOrder = +(process.env.REACT_APP_START_ORDER || '8')
        const endOrder = +(process.env.REACT_APP_END_ORDER || '23')
        const scheduleBlockOptions: { value: any, label: string }[] = []
        const formattedDate = format(new Date(viewedTeam.date), 'yyyy-MM-dd')
        const offset = 8 - (new Date().getTimezoneOffset() / 60 * -1)


        for (let i = startOrder; i <= endOrder; i++) {
            for (let minutes = 0; minutes < 60; minutes += 30) {
                const blockTime = addMinutes(
                    addHours(new Date(`${formattedDate} ${padStart(i.toString(), 2, '0')}:00:00`), -offset),
                    minutes
                )
                scheduleBlockOptions.push({ value: blockTime.toISOString(), label: changeTz(blockTime, 'Asia/Singapore', 'HH:mm') })
            }
        }

        return { scheduleBlockOptions }
    }, [viewedTeam?.date])

    const getJobType = async (search: string) => {
        const { data } = await baseApi.get(`/admin/technicians?search=${search}`)
        return data.list.map((tech: MiscObj) => ({
            value: +tech.id, label: `${tech.name}${tech.vrn ? ` - ${tech.vrn}` : ''}`
        }))
    }

    const promiseOptions = (inputValue: string) => new Promise<any[]>((resolve) => {
        resolve(getJobType(inputValue))
    })

    const saveAssignment = handleSubmit(async (formData) => {
        try {
            if (viewedTeam && data) {
                const assignments: any = data
                const body = {
                    hours: formData.hours,
                    assignedTechnicians: [...formData.leaderTechnicians.map((lt: any) => ({ id: lt, role: 1 })), ...formData.assistantTechnicians.map((at: any) => ({ id: at }))],
                    technicianTeam: +viewedTeam.team.id,
                    date: viewedTeam.date
                }
                if (assignments && assignments[0]) {
                    await baseApi.patch(`admin/service-schedules/team-assignments/${assignments[0].id}`, body)
                }
                else {
                    await baseApi.post(`admin/service-schedules/team-assignments`, body)
                }
                refresh()
                modal.close()
            }
        } catch (err: any) {
            console.log(err.message)
            toast.error('Failed saving assignment. ' + err.message)
        }
    })

    useEffect(() => {
        if (!modal.visible) reset()
        else {
            refetch()
        }
    }, [modal.visible])

    useEffect(() => {
        if (!viewedTeam) return
        const assignments: any = data
        if (isFetching || !assignments) {
            setDefaultValueAssistants(null)
            setDefaultValueLeaders(null)
            setDefaultValueBlockers(null)
            return
        }
        else if (isFetched && assignments) {
            setDefaultValueAssistants(
                assignments && assignments[0] ?
                    assignments[0].assignedTechnicians.filter((at: any) => at.role != 1 && at.technician != null).map((val: MiscObj) => ({ value: +val.technician.id, label: `${val.technician.name}${val.technician.vrn ? ` - ${val.technician.vrn}` : ''}` })) : []
            )
            setDefaultValueLeaders(
                assignments && assignments[0] ?
                    assignments[0].assignedTechnicians.filter((at: any) => at.role == 1 && at.technician != null).map((val: MiscObj) => ({ value: +val.technician.id, label: `${val.technician.name}${val.technician.vrn ? ` - ${val.technician.vrn}` : ''}` })) : []
            )
            const defaultBlockers: string[] = (assignments && assignments[0] && flatten(assignments[0].scheduleBlockers.map((sb: any) => generateTimeIntervals(sb.startDate, sb.endDate)))) || []
            setDefaultValueBlockers(
                defaultBlockers.map(hour => ({ value: scheduleBlockOptions?.find(opt => opt.label == hour)?.value, label: hour }))
            )
        }
    }, [isFetching, isFetched, data])

    return (
        <Modal heading={'Assign Technicians'} visible={modal.visible} close={modal.close} width="30rem">
            <div className="flex flex-col gap-6">
                <div>
                    Assign technicians below to <b>{viewedTeam?.team.name}</b> for <b>{viewedTeam ? format(viewedTeam.date, 'dd MMMM yyyy') : ''}</b>
                </div>
                {
                    (defaultValueAssistants && defaultValueLeaders) ?
                        <>
                            <AsyncMultiSelectField
                                control={control}
                                fieldName="leaderTechnicians" loadOptions={promiseOptions}
                                label={'Team Leaders'}
                                defaultCfg={{
                                    value: defaultValueLeaders,
                                    setValue: setValue
                                }}
                                filterOption={({ value }) => !selectedTechnicians.includes(value)}
                            />
                            <AsyncMultiSelectField
                                control={control}
                                fieldName="assistantTechnicians" loadOptions={promiseOptions}
                                label={'Assistants'}
                                defaultCfg={{
                                    value: defaultValueAssistants,
                                    setValue: setValue
                                }}
                                filterOption={({ value }) => !selectedTechnicians.includes(value)}
                            />
                        </>
                        : <></>
                }
                {defaultValueBlockers &&
                    <div className="space-y-2">
                        <MultiSelectField
                            control={control}
                            fieldName="hours"
                            options={scheduleBlockOptions || []}
                            label="Block Time slot"
                            disabled={blockAll}
                            defaultCfg={{
                                value: defaultValueBlockers,
                                setValue: setValue
                            }}
                        />
                        <Switch control={control} label={"Block All For Today"} name="blockAll" />
                    </div>
                }
                <Button confirm onClick={saveAssignment}>Save</Button>
            </div>
        </Modal>
    )
}

export default AssignTechModal