import { useModal, useModalReturnType } from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";
import { patchAddress, postCreateAddress } from "api/address";
import { Button } from "components/buttons";
import {
  Checkbox,
  FormsGrid,
  SelectField,
  TextAreaField,
  TextField,
} from "components/forms";
import RadioField from "components/forms/RadioField";
import AddressLocation from "components/location-picker/AddressLocation";
import FindAddressPopup from "components/location-picker/FindAddressPopup";
import PinpointPopup from "components/location-picker/PinpointPopup";
import { Modal } from "components/popups";
import { flatten } from "lodash";
import { useEffect, useState } from "react";
import {
  Controller,
  FieldValues,
  useFieldArray,
  UseFormSetValue,
} from "react-hook-form";
import { toast } from "sonner";
import { formatBuildingAddress, formatCountry } from "utils/address";
import { PlaceInterface } from "utils/map";

type Props = {
  modal: useModalReturnType;
  userId?: any;
  onAddNewAddress: any;
  defaultValues?: MiscObj | null;
  editMode?: boolean;
  onEditModeChange?: boolean;
  onClose?: any;
};

const UNIT_TYPES = [
  {
    category: "Residential",
    types: ["HDB", "Condominium", "Landed"],
  },
  {
    category: "Commercial",
    types: ["Office", "Shop", "Factory", "Warehouse", "Business Park"],
  },
];
const AddressModal = (props: Props) => {
  const {
    modal,
    userId,
    onAddNewAddress,
    defaultValues,
    editMode = false,
    onClose,
  } = props;

  const [selectedPlace, setSelectedPlace] = useState<
    PlaceInterface | null | undefined
  >();
  const [selectedType, setSelectedType] = useState<string>("");
  const pinpointModal = useModal();
  const findAddressModal = useModal();
  const unitTypeModal = useModal();
  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    setValue,
    watch,
    reset,
  } = useForm<MiscObj>();

  const handleCloseModal = () => {
    reset();
    setSelectedPlace(null);
    setSelectedType("");
    modal.close();
  };
  const handleSetAddress = (place: PlaceInterface) => {
    setValue(`latitude`, place.lat && +place.lat);
    setValue(`longitude`, place.lng && +place.lng);
    setValue(`street`, place.street);
    setValue(`postalCode`, place.postalCode);
    setValue(`buildingNumber`, place.buildingNumber);
    pinpointModal.close();
    findAddressModal.close();
    setSelectedPlace(place);
  };

  useEffect(() => {
    if (editMode && defaultValues) {
      setValue("unitType", defaultValues.unitType || "");
      setValue("postalCode", defaultValues.postalCode || "");
      setValue("street", defaultValues.street || "");
      setValue("buildingNumber", defaultValues.buildingNumber || "");
      setValue("buildingName", defaultValues.buildingName || "");
      setValue("floorNumber", defaultValues.floorNumber || "0");
      setValue("unitNumber", defaultValues.unitNumber || "0");
      setValue("addressLabel", defaultValues.addressLabel || "");
      setValue("latitude", defaultValues.latitude || "");
      setValue("longitude", defaultValues.longitude || "");
      setValue("notes", defaultValues.notes || "");
      setSelectedType(defaultValues.unitType || "");
      setSelectedPlace({
        postalCode: defaultValues.postalCode,
        buildingNumber: defaultValues.buildingNumber,
        lat: defaultValues.latitude,
        lng: defaultValues.longitude,
      });
    }
  }, [editMode, defaultValues, setValue]);

  const onFinishHandler = async (data: any) => {
    const formData = {
      postalCode: String(data.postalCode || ""),
      buildingNumber: String(data.buildingNumber || ""),
      street: data.street,
      buildingName: String(data.buildingName || ""),
      floorNumber: data.floorNumber || "0",
      unitNumber: data.unitNumber || "0",
      unitType: data.unitType,
      addressLabel: data.addressLabel,
      latitude: data.latitude,
      longitude: data.longitude,
      notes: String(data.notes || ""),
    };
    {
      try {
        if (editMode) {
          const editAddress = await patchAddress(userId, formData, defaultValues?.id);
          onAddNewAddress(null, editAddress); 
        } else {
          const newAddress = await postCreateAddress(userId, formData);
          onAddNewAddress(newAddress, null); 
        }
        

        modal.close();
        toast.success("Success");
        reset();
      } catch (error) {
        console.error(error);
        toast.error("Error");
      }
    }
  };
  const fieldArr = useFieldArray({ control: control, name: "floorNumberNA" });
  const unitFieldArr = useFieldArray({
    control,
    name: "unitNumberNA",
  });

  const isFloorChecked = watch("floorNumberNA", []).includes(
    "floorNumberNotApplicable"
  );
  const isUnitChecked = watch("unitNumberNA", []).includes(
    "unitNumberNotApplicable"
  );

  const watchField = watch();

  return (
    <Modal
      width="40rem"
      heading={`${
        editMode ? "Edit Customer Adddress" : "Add Customer Adddress"
      }`}
      visible={modal.visible}
      close={handleCloseModal}
      preventClose
    >
      <form
        id="user-form"
        onSubmit={handleSubmit(onFinishHandler)}
        className="flex flex-col gap-4"
      >
        <AddressLocation
          className="w-full"
          onClick={findAddressModal.show}
          name={
            selectedPlace
              ? formatBuildingAddress(
                  watchField.buildingNumber,
                  watchField.street
                )
              : "Search address location"
          }
          address={
            selectedPlace
              ? formatCountry(
                  watchField.country || "Singapore",
                  watchField.postalCode
                )
              : "Select the address exact location using maps"
          }
        />
        <FormsGrid>
          <TextField
            disabled
            control={control}
            required
            label={"Latitude"}
            isNumeric
            fieldName={`latitude`}
          />
          <TextField
            disabled
            control={control}
            required
            label={"Longitude"}
            isNumeric
            fieldName={`longitude`}
          />
        </FormsGrid>
        <FormsGrid>
          <TextField
            label={"Postal Code"}
            control={control}
            fieldName={`postalCode`}
          />
          <TextField
            required
            label={"Block Number"}
            control={control}
            fieldName={`buildingNumber`}
          />
        </FormsGrid>
        <TextField
          required
          label={"Street"}
          control={control}
          fieldName={`street`}
        />

        <TextField
          required
          label={"Building Name"}
          control={control}
          fieldName={`buildingName`}
        />

        <FormsGrid col={2}>
          <div>
            <TextField
              disabled={isFloorChecked}
              required
              label={"Floor Number"}
              control={control}
              fieldName={`floorNumber`}
            />
            <Checkbox
              className="mt-2"
              fieldArr={fieldArr}
              cbVal={"floorNumberNotApplicable"}
              fieldName="floorNumberNA"
              control={control}
              label={"Not Applicable"}
            />
          </div>
          <div>
            <TextField
              disabled={isUnitChecked}
              required
              label={"Unit Number"}
              control={control}
              fieldName={`unitNumber`}
            />
            <Checkbox
              className="mt-2"
              fieldArr={unitFieldArr}
              cbVal={"unitNumberNotApplicable"}
              fieldName="unitNumberNA"
              control={control}
              label={"Not Applicable"}
            />
          </div>
        </FormsGrid>
        <FormsGrid col={2} className="mt-2">
          <div>
            <label
              htmlFor="address-type"
              className="text-dark font-medium block mb-2"
            >
              Address Type
            </label>
            <div className="relative w-full">
              <input
                value={selectedType || ""}
                readOnly
                className="px-4 py-2 rounded-th-1 border-2 transition-colors w-full inline-block cursor-pointer border-[#EAECF0]"
                onClick={() => unitTypeModal.show()}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
              >
                <path
                  d="M6.66671 11.333L10 7.99972"
                  stroke="#C2C2C2"
                  strokeWidth="1.33332"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10 8L6.66671 4.66671"
                  stroke="#C2C2C2"
                  strokeWidth="1.33332"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>

            <UnitTypeModal
              control={control}
              modal={unitTypeModal}
              onApply={(value) => setSelectedType(value)}
            />
          </div>

          <TextField
            label={"Address Label"}
            control={control}
            fieldName={"addressLabel"}
          />
        </FormsGrid>

        <TextAreaField
          className="mt-5"
          label="Notes for technician (optional)"
          fieldName="notes"
          control={control}
          placeholder="House color, landmarks, special instructions, etc..."
        />

        <Button type="submit" className="w-full mt-9">
          {editMode ? "Save Changes" : "Save"}
        </Button>
      </form>
      <FindAddressPopup
        visible={findAddressModal.visible}
        openPinPoint={pinpointModal.show}
        handleSetAddress={handleSetAddress}
        close={findAddressModal.close}
      />
      <PinpointPopup
        visible={pinpointModal.visible}
        close={pinpointModal.close}
        openFindAddress={findAddressModal.show}
        handleSetAddress={handleSetAddress}
      />
    </Modal>
  );
};

export default AddressModal;

const UnitTypeModal = ({
  control,
  modal,
  onApply,
}: {
  control: any;
  modal: useModalReturnType;
  onApply: (value: string) => void;
}) => {
  const [selectedValue, setSelectedValue] = useState<string>("");

  const residentialOptions =
    UNIT_TYPES.find(
      (category) => category.category === "Residential"
    )?.types.map((type) => ({
      value: type,
      label: type,
    })) || [];

  const commercialOptions =
    UNIT_TYPES.find(
      (category) => category.category === "Commercial"
    )?.types.map((type) => ({
      value: type,
      label: type,
    })) || [];

  const handleApply = () => {
    onApply(selectedValue);
    modal.close();
  };

  return (
    <Modal
      width="32rem"
      heading="Address Type"
      visible={modal.visible}
      close={modal.close}
    >
      <Controller
        control={control}
        name="unitType"
        render={({ field: { value } }: any) => {
          setSelectedValue(value);
          return (
            <>
              <h4 className="text-[#404040] font-medium mb-4 text-th-sm">
                Residential
              </h4>
              <RadioField
                options={residentialOptions}
                control={control}
                label="Address Type"
                fieldName="unitType"
                required={true}
                onChange={(value: any) => setSelectedValue(value)}
              />
              <hr className="border-t-2 border-[#EAECF0] my-4" />

              <h4 className="text-[#404040] font-medium mb-4 text-th-sm ">
                Commercial
              </h4>
              <RadioField
                options={commercialOptions}
                control={control}
                label="Address Type"
                fieldName="unitType"
                required={true}
                onChange={(value: any) => setSelectedValue(value)}
              />
            </>
          );
        }}
      />
      <Button className="w-full mt-6" type="button" onClick={handleApply}>
        Apply
      </Button>
    </Modal>
  );
};
