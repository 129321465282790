import React from 'react'
import { HiMapPin } from 'react-icons/hi2';

interface AddressLocationProps {
    className?: string
    name: string
    address: string
    onClick?: () => void
    action?: string;
}

const AddressLocation: React.FC<AddressLocationProps> = ({ className, name, address, onClick, action }) => {
    const Tag = onClick ? 'button' : 'div'
    return (
        <Tag onClick={onClick} type={Tag == 'button' ? 'button' : undefined} className={`flex px-4 py-3 border-2 rounded-lg justify-between items-center border-[#ECF0F8] text-start ${className}`}>
            <div className="flex items-center gap-4">
                <div className="text-xl">
                    <HiMapPin color='#CB3A31' />
                </div>
                <div className="flex flex-col gap-2.5">
                    <p className=" text-th-xs font-medium line-clamp-1">{name}</p>
                    <p className="text-[0.625rem] text-light line-clamp-2">{address}</p>
                </div>
            </div>
            {onClick &&
                <p className="text-primary text-[0.625rem]" >{ action ? action : 'Change'}</p>
            }
        </Tag>
    )
}

export default AddressLocation