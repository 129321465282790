import { useEffect, useRef, useState } from "react";
import { CloseIcon } from "../Svg";

export interface ModalProps {
  visible: boolean;
  close: () => void;
  children?: JSX.Element | JSX.Element[];
  heading?: JSX.Element | string;
  width?: string;
  className?: string;
  renderChildOnHidden?: boolean;
  hideClose?: boolean;
  [key: string]: any;
  subheading?: string;
  preventClose?: boolean;
}
export default function Modal({
  visible,
  close,
  children,
  heading,
  width,
  className,
  renderChildOnHidden = false,
  hideClose = false,
  preventClose = false,
  subheading,
  ...rest
}: ModalProps) {
  const ref = useRef<HTMLDivElement | null>(null);
  const timeOutId = useRef<any | null>(null);
  let classes = "modal" + (className ? " " + className : "");
  const [showConfirmation, setShowConfirmation] = useState(false);
  useEffect(() => {
    if (ref && ref.current) {
      if (visible) {
        ref.current.classList.add("is-visible", "is-shown");
      } else {
        ref.current.classList.remove("is-shown");
        timeOutId.current = setTimeout(() => {
          ref.current?.classList.remove("is-visible");
        }, 300);
      }
    }
  }, [visible, ref, timeOutId]);

  useEffect(() => {
    return () => {
      clearTimeout(timeOutId.current);
    };
  }, [timeOutId]);
  const handleClose = () => {
    if (preventClose) {
      setShowConfirmation(true);
    } else {
      close();
    }
  };
  const handleConfirmClose = () => {
    setShowConfirmation(false);
    close();
  };
  const handleCancelClose = () => {
    setShowConfirmation(false);
  };
  return (
    <>
      {showConfirmation ? (
        <section className={classes}>
          <div className="content" style={{ width: "22rem" }}>
            <div className="w-full flex flex-col items-center gap-8 ">
              <div className="flex flex-col items-center gap-6 text-center  tablet-below:px-4">
                <p className=" text-th-lg text-[#404040] font-medium leading-7 whitespace-pre-wrap">
                  Are you sure want to leave this page?
                </p>
                <p className="text-th-base text-light mx-10 whitespace-pre-wrap">
                  If you leave, you can lose all your inputted data
                </p>
              </div>
              <div className=" w-full flex gap-4 flex-col">
                <button
                  type="button"
                  onClick={handleConfirmClose}
                  className="btn w-full border-none  bg-primary hover:bg-primary/50  flex justify-center py-2.5"
                >
                  <p className="text-th-xs">Yes, leave</p>
                </button>
                <button
                  type="button"
                  onClick={handleCancelClose}
                  className="btn border-primary bg-transparent hover:opacity-70 transition-all text-primary  w-full flex justify-center py-2.5"
                >
                  <p className="text-th-xs" color="#CB3A31">
                    Cancel
                  </p>
                </button>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section ref={ref} className={classes} {...rest}>
          <div className="content" style={{ width: width || "auto" }}>
            <div className="header">
              <div>
                <h6 className="heading">{heading}</h6>
                <p>{subheading ? subheading : ""}</p>
              </div>
              {!hideClose && (
                <button className="close-btn" onClick={() => handleClose()}>
                  <CloseIcon />
                  <span className="sr-only">Close Modal</span>
                </button>
              )}
            </div>
            {(visible || renderChildOnHidden) && (
              <div className="body">{children}</div>
            )}
          </div>
        </section>
      )}
    </>
  );
}
