import { PDFViewer } from "@react-pdf/renderer";
import { useModal } from "@refinedev/core";
import { claimInvoiceHistory, deleteInvoiceHistory } from "api/invoice";
import { Button } from "components/buttons";
import { InvoiceOrderDocument } from "components/pdfs/InvoiceOrderDocument";
import Pill from "components/Pill";
import { Modal } from "components/popups";
import { ManagePaymentIcon, ChevronDownIcon } from "components/Svg";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "sonner";
import { formatFullDate } from "utils/date";
import { scrollToTargetAdjusted } from "utils/scheduler";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules'
import { createPortal } from "react-dom";
import { savePdfFile } from "utils/file";
import { isBefore } from "date-fns";

type Props = {
    invoice: MiscObj
    order?: MiscObj
    refetchOrder: () => void
    openRecord: () => void
}

const InvoiceCard = (props: Props) => {
    const { invoice, order, refetchOrder, openRecord } = props
    const [isShown, setIsShown] = useState(false)
    const [viewedInvoiceHistory, setViewedInvoiceHistory] = useState<MiscObj>()
    const proofModal = useModal()
    const itemIds = invoice.items.map((item: any) => item.id)
    const invoiceItems = order ? order.items.filter((item: any) => itemIds.includes(item.id)) : []

    const handleRemovePayment = async (invoiceId: string, invoiceHistoryId: string) => {
        try {
            if (await deleteInvoiceHistory(invoiceId, invoiceHistoryId)) {
                toast.success('Successfully removed payment')
            } else {
                toast.error('Failed to remove payment.')
            }
            refetchOrder()
        } catch (err: any) {
            toast.error(err.message)
        }
    }

    const handleClaimPayment = async (invoiceId: string, invoiceHistoryId: string) => {
        try {
            if (await claimInvoiceHistory(invoiceId, invoiceHistoryId)) {
                toast.success('Successfully claimed payment')
            } else {
                toast.error('Failed to claim payment.')
            }
            refetchOrder()
        } catch (err: any) {
            toast.error(err.message)
        }
    }

    return <div className="bg-white relative  border rounded-lg p-4" id={`invoice-${invoice.id}`}>
        <div className="flex items-start gap-3 border-b pb-4">
            <div>
                <ManagePaymentIcon />
            </div>
            <div className="grow flex flex-col gap-3">
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                        <h3 className="text-th-lg text-light">Manage payments #{invoice.id}</h3>
                        {invoice.customerDoesntWantToPay &&
                            <Pill backgroundColor="#BB032A33" textColor="#BB032A">
                                Customer doesn't want to pay
                            </Pill>
                        }
                        {invoice.customerWantsToPayLater &&
                            <Pill backgroundColor="#FC880033" textColor="#FC8800">
                                Customer wants to pay later
                            </Pill>
                        }
                    </div>


                    <div className="flex items-center gap-2">
                        <Button shape="outline" disabled={Boolean(order?.cancelledAt)} onClick={() => { setIsShown(state => !state); setTimeout(() => scrollToTargetAdjusted(`invoiceEle${invoice.id}`), 100) }}>
                            <span>See Invoice</span>
                            <ChevronDownIcon className={invoice.isShown ? 'rotate-180' : ''} width={24} height={24} />
                        </Button>
                        {invoice.paymentGtwRes != null && invoice.paymentGtwRes.url &&
                            <Button disabled={Boolean(order?.cancelledAt)} onClick={() => { navigator.clipboard.writeText(invoice.paymentGtwRes.url); toast.success('Payment link successfully copied !'); }}>
                                Copy Payment Link
                            </Button>
                        }

                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <div className="flex items-center justify-between">
                        <span className="font-medium">Amount due: 
                            {!order?.cancelledAt ? (
                            <span className="font-normal">$ {invoice.amountDue.toFixed(2)}</span>
                        ): (
                        <span className="font-normal">$ 0.00</span>
                        )} - <button type='button' disabled={Boolean(order?.cancelledAt)} onClick={openRecord}  className="text-primary">Record a payment</button> <span className="font-normal">manually</span></span>
                        <span><span className="font-medium">Status:</span> {invoice.amountDue == 0 ? 'Paid fully' : invoice.amountDue != invoice.amount ? invoice.amountDue < 0 ? 'Overpaid' : 'Partially Paid' : 'Not Paid'}</span>
                    </div>
                    <span>
                        <span className="font-medium">Fully Paid Date: </span>{formatFullDate(invoice.paidAt)}
                    </span>
                </div>
            </div>
        </div>
        <div className="border-b py-4 px-[2.6rem] flex flex-col">
            <div className="flex flex-col gap-1.5">
                {invoice && invoice.invoiceHistories && invoice.invoiceHistories.sort((a: any, b: any) => isBefore(a.createdAt, b.createdAt) ? -1 : 1).map((invHis: any) => {
                    return <div className="flex flex-col text-th-sm text-light items-start">
                        <span>
                            <span className="font-medium text-black">{invHis.isManual ? 'Manual' : invHis.paymentMethod == 'Credits' ? 'Credits' : 'Hitpay'} {invHis.type == 1 ? 'Payment' : invHis.type == 2 ? 'Refund' : 'Compensation'} : </span>
                            {formatFullDate(invHis.paidAt)} - A {invHis.type == 1 ? 'payment' : invHis.type == 2 ? 'refund' : 'compensation'} for
                            <span className="font-medium text-black"> $ {invHis.amount.toFixed(2)} </span>
                            was made using {invHis.paymentMethod} by
                            <span className="font-medium text-black"> {invHis.createdBy}</span>
                        </span>
                        {invHis.notes &&
                            <span>Notes : {invHis.notes}</span>
                        }
                        <div className="flex items-center gap-4">
                            {invHis.cancelledBy == null && invHis.type != 2 && invHis.isManual && (invHis.paymentMethod != 'onsite' || !invHis.claimedAt) && invHis.paymentMethod != 'Credits' &&
                                <Button shape="raw" type="button" confirm onClick={() => handleRemovePayment(invoice.id, invHis.id)} className="text-primary font-medium">Remove {invHis.type == 1 ? 'Payment' : 'Compensation'}</Button>
                            }
                            {invHis.paymentMethod == 'onsite' && !invHis.claimedAt && invHis.cancelledBy == null &&
                                <Button shape="raw" type="button" confirm onClick={() => handleClaimPayment(invoice.id, invHis.id)} className="text-primary font-medium">Claim Payment</Button>
                            }
                            {invHis.proofs && invHis.proofs.length > 0 &&
                                <Button shape="raw" type="button" onClick={() => { setViewedInvoiceHistory(invHis); proofModal.show() }} className="text-primary font-medium">View Receipts</Button>
                            }
                        </div>
                        {invHis.cancelledBy != null &&
                            <span>{invHis.cancelledBy.name} removed this {invHis.type == 1 ? 'payment' : invHis.type == 2 ? 'refund' : 'compensation'} on {formatFullDate(invHis.updatedAt)}</span>
                        }
                        {invHis.paymentMethod == 'onsite' && invHis.claimedAt &&
                            <span>{invHis.claimedBy.name} claimed this payment on {formatFullDate(invHis.claimedAt)}</span>
                        }
                    </div>
                })}
            </div>
        </div>
        {isShown && (invoice && order) &&
            <div className="flex flex-col items-end mt-4">
                <iframe typeof="pdf" className="w-full aspect-[1/1.1] invoice-iframe" src={`${process.env.REACT_APP_API_BASE_URL}/admin/documents/service-invoices/${invoice.id}`} />
            </div>
        }

        {createPortal(
            <Modal visible={proofModal.visible} close={proofModal.close} heading={`Payment Receipts with Total $ ${viewedInvoiceHistory?.amount.toFixed(2)}`} width="80rem">
                <Swiper modules={[Navigation]} navigation={{ enabled: true }}>
                    {viewedInvoiceHistory?.proofs.map((proof: any) => {
                        const url = proof.url.replace('https:/a', 'https://a')
                        return <SwiperSlide>
                            <div className="flex justify-center">
                                <Link key={proof.id} target="_blank" to={url} className="mx-auto">
                                    <img src={url} alt="Payment Proofs" className="h-[80vh] aspect-square object-contain" />
                                </Link>
                            </div>
                        </SwiperSlide>
                    })}
                </Swiper>

            </Modal>, document.body
        )}

    </div>
}

export default InvoiceCard