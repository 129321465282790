import ListMissingReports from "components/pages/service-orders/ListMissingReports"
import { PendingPaymentIcon } from "components/Svg"
import { ResourceType } from "resources-config"

export const MISSING_REPORTS_CONFIG: ResourceType = {
    name: 'missing-reports',
    meta: {
        icon: <PendingPaymentIcon />,
        label: 'Missing Reports',
        apiEndpoints: {
            getList: '/service-schedules/missing-reports'
        },
        parent: 'service-orders',
    },
    actions: {
        list: {
            path: 'missing-reports', page: <ListMissingReports />,
            allowedRoles: ['super admin']
        },
    }
}

