import Badge from "components/Badge"
import { PROPERTY_TYPES } from "components/consts/property"
import { CopyIcon } from "components/Svg"
import { capitalize, flatten } from "lodash"
import { useMemo } from "react"
import { Link } from "react-router-dom"
import { MultiValue } from "react-select"
import { toast } from "sonner"
import { formatFullDate } from "utils/date"
import { generateCodeStrings } from "utils/promo"

type Props = {
    className?: string
    minimumSpend?: number,
    amount: number,
    title: string,
    description: string,
    startDate: Date,
    endDate: Date,
    platforms: string[]
    type: number,
    code: string
    promoType: number
    generatorPosition?: string
    generatorLength?: number
    maxAmount?: number
    paymentMethod?: string[]
    quota?: number
    allowPriorityBooking?: (string | number)
    includedServices?: MiscObj[]
    excludedServices?: MiscObj[]
    includedUsers?: MiscObj[]
    excludedUsers?: MiscObj[]
    includeOrExcludeUsers?: string | number
    includeOrExcludeServices?: string | number
    includeOrExcludePropertyTypes?: string | number
    servicesList?: MiscObj[]
    genders?: string[]
    propertyTypes?: string[]
    userCriteria?: (string | number)[]
    usageLimit?: number
    selectedUsers?: MultiValue<any>
    mode: 'create' | 'view' | 'edit'
}

export default function PromoCard(props: Props) {
    const {
        className, minimumSpend, title, amount,
        type, code, promoType, generatorPosition,
        generatorLength = 0, startDate, endDate, paymentMethod,
        description, maxAmount, platforms, servicesList = [],
        includedServices, excludedServices, includeOrExcludeServices,
        includedUsers, excludedUsers, includeOrExcludeUsers,
        propertyTypes, includeOrExcludePropertyTypes,
        allowPriorityBooking, quota, genders, userCriteria, usageLimit,
        selectedUsers = [], mode
    } = props

    const codes = useMemo(() => {
        let suffix = 0;
        let prefix = 0;
        if (generatorPosition == 'suffix')
            suffix = generatorLength
        else if (generatorPosition == 'prefix')
            prefix = generatorLength
        else suffix = generatorLength
        return generateCodeStrings(code || "", 1, suffix, prefix)
    }, [generatorLength, generatorPosition, code])

    const appliedFor = useMemo(() => {
        if (includeOrExcludeServices == 0) return []
        if (mode != 'view') {
            const servicesCriteria = {
                excludedServices: includeOrExcludeServices == 'exclude' ? excludedServices && excludedServices.map((exc: any) => ({ id: parseInt(exc.split('-')[0]), isContract: exc.split('-')[1] == 'contract', categoryId: parseInt(exc.split('-')[2]) })) || [] : [],
                includedServices: includeOrExcludeServices == 'include' ? includedServices && includedServices.map((exc: any) => ({ id: parseInt(exc.split('-')[0]), isContract: exc.split('-')[1] == 'contract', categoryId: parseInt(exc.split('-')[2]) })) || [] : []
            };
            if (includeOrExcludeServices == 'include') {
                const includedServicesId = servicesCriteria.includedServices.map(service => `${service.id}-${service.isContract}-${service.categoryId}`)
                return servicesList.filter(service => includedServicesId.includes(`${service.service.id}-${service.frequency > 1}-${service.category.id}`)).map(service => `${service.service.name} ${service.frequency > 1 ? ' (Contract)' : ''} - ${service.category.name}|${service.service.id}`)
            }
            else if (includeOrExcludeServices == 'exclude') {
                const excludedServicesId = servicesCriteria.excludedServices.map(service => `${service.id}-${service.isContract}-${service.categoryId}`)
                return servicesList.filter(service => !excludedServicesId.includes(`${service.service.id}-${service.frequency > 1}-${service.category.id}`)).map(service => `${service.service.name} ${service.frequency > 1 ? ' (Contract)' : ''} - ${service.category.name}|${service.service.id}`)
            }
        } else {
            if (includeOrExcludeServices == 'include' && includedServices) {
                return includedServices.map(service => `${service.service.name} ${service.isContract ? ' (Contract)' : ''} - ${service.serviceCategory.name}|${service.service.id}`)
            }
            else if (includeOrExcludeServices == 'exclude' && excludedServices) {
                const excludedServicesId = excludedServices.map((service) => `${service.service.id}-${service.isContract}-${service.serviceCategory.id}`)
                return servicesList.filter(service => !excludedServicesId.includes(`${service.service.id}-${service.frequency > 1}-${service.category.id}`)).map(service => `${service.service.name} ${service.frequency > 1 ? ' (Contract)' : ''} - ${service.category.name}|${service.service.id}`)
            }
            return []
        }



        return []
    }, [includedServices, excludedServices, includeOrExcludeServices])

    const handleCopyPromoCode = () => {
        if (!code) return;

        navigator.clipboard.writeText(code.toString());
        toast.success("Promo code successfully copied");
    }

    return (

        <>
            <div className="shadow-md rounded-lg overflow-hidden max-w-[450px] mx-auto">
                <div className="p-5 bg-white">
                    <h5 className="font-medium">Promo Preview</h5>
                    <section
                        className={`${className} overflow-x-hidden relative  shadow-primary-3-surface rounded-b-lg`}
                    >
                        <div className="bg-primary-3-surface h-2.5 w-full rounded-t-lg border-b-2 border-dashed border-white " />
                        <div className="w-[3.2rem] h-[2rem] bg-white border shadow-sm border-primary-3-surface  z-10 absolute rounded-full -left-[2rem] top-[40%] -translate-y-1/2" />
                        <div className="w-[3.2rem] h-[2rem] bg-white border shadow-sm border-primary-3-surface z-10 absolute rounded-full -right-[2rem] top-[40%] -translate-y-1/2" />
                        <div className="px-8 flex flex-col gap-6 justify-between rounded-b-lg relative  border border-t-0  border-primary-3-surface overflow-x-hidden">
                            <div className="flex items-center justify-between ">
                                <div>
                                    <h6 className="text-theme-sm font-medium pt-5 pr-8">{title}</h6>
                                    {minimumSpend == null ? (
                                        <span className="text-theme-xs text-body">
                                            No minimum purchase
                                        </span>
                                    ) : (
                                        <span className="text-theme-xs text-body ">
                                            Get{" "}
                                            <span className="font-medium">
                                                {type === 1 ? "$ " : ""}
                                                {amount}
                                                {type === 2 ? "%" : ""} Off {" "}
                                            </span>
                                            with {" "}
                                            <span className="font-medium">
                                                {isNaN(+minimumSpend) || !minimumSpend ? 'No' : `$ ${(+minimumSpend).toFixed(2)}`} Minimum Spend
                                            </span>
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="flex items-center justify-between l pb-5">
                                <div className="flex gap-[6px]">
                                    <span className="text-theme-xs text-body font-medium">
                                        Promo Code: {(promoType == 2 && mode != 'view') ? (codes[0] || '') : code}
                                    </span>
                                    {promoType != 2 && code &&
                                        <button type="button" onClick={handleCopyPromoCode}>
                                            <CopyIcon color="#1954A1" width={14} height={14} />
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className="bg-primary-3-surface p-5 pt-12 max-h-[50rem] overflow-auto">
                    {description &&
                        <p className="whitespace-pre-wrap mt-8 mb-4">{description}</p>
                    }
                    <div className={`flex flex-col gap-4 mb-8 ${description ? '' : 'mt-8'}`}>
                    {(startDate || endDate) && (
                         <div className="grid grid-cols-2 items-start gap-4">
                         <span className="text-light">Promo Period</span>
                         <span>
                           {startDate === endDate
                             ? formatFullDate(startDate, { showDay: false, showTime: false })
                             : `${formatFullDate(startDate, { showDay: false, showTime: false })}${
                                 endDate ? ` - ${formatFullDate(endDate, { showDay: false, showTime: false })}` : ''
                               }`}
                         </span>
                       </div>
                    )}

                        {minimumSpend != null &&
                            <div className="grid grid-cols-2 items-start gap-4">
                                <span className="text-light">Min Transaction</span>
                                <span>{minimumSpend ? `$ ${(+minimumSpend).toFixed(2)}` : '$ 0'}</span>
                            </div>
                        }
                        {amount != null &&
                            <div className="grid grid-cols-2 items-start gap-4">
                                <span className="text-light">Discount Amount</span>
                                <span>{(amount && type) ? `${type == 1 ? '$ ' : ''}${isNaN(+amount) ? 'Invalid Amount' : type == 1 ? (+amount).toFixed(2) : amount} ${type == 2 ? '%' : ''}` : '$ 0'} {maxAmount && type == 2 ? `up to $ ${(+maxAmount).toFixed(2)}` : ''}</span>
                            </div>
                        }
                        {paymentMethod && paymentMethod.length > 0 &&
                            <div className="grid grid-cols-2 items-start gap-4">
                                <span className="text-light">Payment Method</span>
                                <span>{paymentMethod.map(method => method == 'online' ? 'Online Payment' : 'Pay On Site').join(' and ')}</span>
                            </div>
                        }

                        {quota != null &&
                            <div className="grid grid-cols-2 items-start gap-4">
                                <span className="text-light">Number of uses (Quota)</span>
                                <span>{quota}</span>
                            </div>
                        }

                        {usageLimit != null &&
                            <div className="grid grid-cols-2 items-start gap-4">
                                <span className="text-light">Usage per user</span>
                                <span>{usageLimit}</span>
                            </div>
                        }

                        {platforms && platforms.length > 0 &&
                            <div className="grid grid-cols-2 items-start gap-4">
                                <span className="text-light">Platform</span>
                                <span>{platforms.map(method => method == 'mobile' ? 'Mobile App' : 'Website').join(' and ')}</span>
                            </div>
                        }

                        {(allowPriorityBooking != undefined || allowPriorityBooking != null) &&
                            <div className="grid grid-cols-2 items-start gap-4">
                                <span className="text-light">Priority Booking Eligibility</span>
                                {allowPriorityBooking === "true" &&
                                    <span>User can only use promo code if they use Priority Booking</span>
                                }
                                {/* @ts-ignore */}
                                {allowPriorityBooking === "false" &&
                                    <span>User can't use promo code if they use Priority Booking</span>
                                }
                                {allowPriorityBooking === 0 &&
                                    <span>User can use promo code regardless of Priority Booking</span>
                                }
                            </div>
                        }
                        {appliedFor && appliedFor.length > 0 &&
                            <div className="grid grid-cols-1 items-start gap-2">
                                <span className="text-light">Applied For</span>
                                <div className="flex flex-wrap items-center gap-2">
                                    {appliedFor.map(applied =>
                                        <Link target="_blank" to={`/services/edit/${applied.split('|')[1]}`}>
                                            <Badge className="py-0.5 px-2 text-start text-th-xs line-clamp-1" color="primary">{applied.split('|')[0]}</Badge>
                                        </Link>
                                    )}
                                </div>
                            </div>
                        }

                        {includeOrExcludeUsers != 0 &&
                            <>
                                {includeOrExcludeUsers == 'include' && includedUsers && includedUsers.length > 0 &&
                                    <div className="grid grid-cols-1 items-start gap-2">
                                        <span className="text-light">Include Users</span>
                                        <div className="flex flex-wrap items-center gap-2">
                                            {includedUsers.map(userOrUserId => {
                                                let user;
                                                if (mode != 'view')
                                                    user = `${selectedUsers.find(user => user.value == userOrUserId)?.label.split(' - ')[0]}|${userOrUserId}`
                                                else user = `${userOrUserId.name}|${userOrUserId.id}`
                                                return user ? <Link target="_blank" to={`/users/${user.split('|')[1]}`}>
                                                    <Badge className="py-0.5 px-2 text-start text-th-xs line-clamp-1" color="primary">{user.split('|')[0]}</Badge>
                                                </Link> : <></>
                                            })}
                                        </div>
                                    </div>
                                }

                                {includeOrExcludeUsers == 'exclude' && excludedUsers && excludedUsers.length > 0 &&
                                    <div className="grid grid-cols-1 items-start gap-2">
                                        <span className="text-light">Exclude Users</span>
                                        <div className="flex flex-wrap items-center gap-2">
                                            {excludedUsers.map(userOrUserId => {
                                                let user;
                                                if (mode != 'view')
                                                    user = `${selectedUsers.find(user => user.value == userOrUserId)?.label.split(' - ')[0]}|${userOrUserId}`
                                                else user = `${userOrUserId.name}|${userOrUserId.id}`
                                                return user ? <Link target="_blank" to={`/users/${user.split('|')[1]}`}>
                                                    <Badge className="py-0.5 px-2 text-start text-th-xs line-clamp-1" color="primary">{user.split('|')[0]}</Badge>
                                                </Link> : <></>
                                            })}
                                        </div>
                                    </div>
                                }
                            </>
                        }
                        {genders && genders.length > 0 &&
                            <div className="grid grid-cols-2 items-start gap-4">
                                <span className="text-light">Gender</span>
                                <span>{genders.map(gender => capitalize(gender)).join(' and ')}</span>
                            </div>
                        }

                        {includeOrExcludePropertyTypes != 0 && (includeOrExcludePropertyTypes == 'exclude' || (includeOrExcludePropertyTypes == 'include' && propertyTypes && propertyTypes.length > 0)) &&
                            <div className="grid grid-cols-1 items-start gap-2">
                                <span className="text-light">Property Types</span>
                                <div className="flex flex-wrap items-center gap-2">
                                    {includeOrExcludePropertyTypes == 'include' &&
                                        propertyTypes?.map(type => <Badge className="py-0.5 px-2 text-start text-th-xs line-clamp-1" color="primary">{type}</Badge>)
                                    }
                                    {includeOrExcludePropertyTypes == 'exclude' &&
                                        flatten(PROPERTY_TYPES?.map(type => type.types)).filter(type => !propertyTypes?.includes(type)).map(
                                            type => <Badge className="py-0.5 px-2 text-start text-th-xs line-clamp-1" color="primary">{type}</Badge>
                                        )
                                    }
                                </div>
                            </div>
                        }

                        {userCriteria && userCriteria.length > 0 &&
                            <div className="grid grid-cols-2 items-start gap-4">
                                <span className="text-light">User Criteria</span>
                                <span>{userCriteria.filter(criteria => criteria != 0).map(criteria => criteria == 'forHaventBookedUser' ? "New Users (haven't booked)" : criteria == 'forHaventBookedUserInMonths' ? 'Inactive users (6+ months)' : undefined).join(', ')}</span>
                            </div>
                        }
                    </div>
                    <div className="">
                        <h5 className="font-medium pb-2">Other Terms and Conditions</h5>
                        <ul className="list-disc ml-4">
                            <li>
                  Eligibility: Promo codes are available to users who meet the eligibility criteria specified in Airple’s Terms of Use.
                  </li>
                  <li>Validity Period: Promo codes are valid only within the dates specified at the time of issuance. Expired codes will not be accepted.</li>
                  <li>
                  Redemption Process: To redeem a promo code, customers must enter it during the checkout process in the designated field. The applicable discount will be applied to the total amount.
                  </li>
                  <li>Non-Transferability: Promo codes are non-transferable and cannot be exchanged for cash, credit, or other goods and services.</li>
                  <li>
                  Restrictions: Certain services or products may be excluded from promo code promotions. Specific terms will be provided with each promo code.
                  </li>
                  <li>Combining Offers: Unless stated otherwise, promo codes cannot be combined with other promotions, discounts, or offers.</li>
                  <li>Modification Rights: Airple reserves the right to modify or cancel any promo code or its terms at any time without prior notice.</li>
                  <li>Misuse: Any misuse of promo codes, including but not limited to fraudulent activities, may result in order cancellation or account suspension.</li>
                  <li>Additional Terms: Specific promo codes may come with additional terms and conditions, which will be communicated at the time of issuance.</li>
                        </ul>
                    </div>
                </div>
            </div >
        </>

    )
}