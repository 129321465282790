import { useEffect, useMemo, useState } from "react"
import { BaseFormProps } from "./types"
import { useController } from "react-hook-form"
import Select from 'react-select'
import { getFieldErrMsg } from "utils/handle-api-error"
import { FilterOptionOption } from "react-select/dist/declarations/src/filters"


interface MultiSelectFieldProps extends BaseFormProps {
    options: { value: any, label: string }[],
    defaultCfg?: {
        value: SelectMultiVal
        setValue: FormHookType['setValue']
    }
    filterOption?: (option: FilterOptionOption<any>, inputValue: string) => boolean
    filterOptions?: boolean
    isRequired?: boolean | string;
}

export default function MultiSelectField({
    fieldName, control, options, errorCfg, defaultCfg,
    label, desc, className, filterOptions = true, filterOption, isRequired, ...rest
}: MultiSelectFieldProps) {

    const reactSelectId = useMemo(() => (Date.now().toString()), [])
    const [isMounted, setIsMounted] = useState(false);
    const { field, fieldState } = useController({ name: fieldName, control,
        rules: {
            required: isRequired && !rest.disabled ? "Please fill out this field" :  false,
        }
     });
    let classes = 'form-group' + (className ? ' ' + className : '')
    classes += rest.required ? ' required' : ''

    useEffect(() => setIsMounted(true), []);

    useEffect(() => {
        if (defaultCfg && defaultCfg.setValue && defaultCfg.value !== undefined) {
            defaultCfg.setValue(fieldName, defaultCfg.value ? defaultCfg.value.map(val => val.value) : [])
        }
    }, [defaultCfg?.value, defaultCfg?.setValue])

    if (!isMounted) {
        return null
    }
    return (
        <span className={classes}>
            {label ? <label className='form-label'>{label}</label> : ''}
            <Select
                id={reactSelectId}
                ref={field.ref} options={options}
                defaultValue={defaultCfg && defaultCfg.value ? defaultCfg.value : []}
                value={filterOptions ? options.filter(opt => field.value?.includes(opt.value)) : undefined}
                onChange={opt => field.onChange(opt.map(opt => opt.value))}
                isDisabled={rest.disabled}
                isOptionDisabled={(option: any) => option.isDisabled}
                filterOption={filterOption}
                menuPortalTarget={document.body}
                isClearable
                isMulti
                styles={{
                    control: (base: any, state: any) => ({
                        ...base,
                        borderWidth: '2px', borderStyle: 'solid',
                        borderColor: fieldState.error
                        ? '#BB032A' 
                        : state.isFocused
                        ? '#EAECF0 !important' 
                        : '#EAECF0 !important', 
                      
                        borderRadius: '8px', boxShadow: "none",
                        outline: 'none', padding: '0em 0.2em'
                    }),
                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                    multiValue: (base: any, state: any) => ({
                        ...base,
                        background: '#1954A1',
                        color: 'white',
                        borderRadius: '0.5rem',
                        padding: 5
                    }),
                    multiValueLabel: (base: any, state: any) => ({
                        ...base,
                        color: 'white',
                        fontWeight: '500'
                    })
                }}
            />
            <span className="flex flex-col">
                {!desc || fieldState.error ? <></> : <span className="desc">{desc}</span>}
                {
                    !fieldState.error || (errorCfg && errorCfg.hideErrMsg) ? <></> :
                        <span className="err-msg">
                            {getFieldErrMsg(
                                fieldState.error, fieldName, (errorCfg && errorCfg.errLabel ? errorCfg.errLabel : '')
                            )}
                        </span>
                }
            </span>
        </span>
    )
}