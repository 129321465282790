import { ResourceType } from "resources-config"

export const PROMO_CODE_USAGE_CONFIG: ResourceType = {
    name: 'promo-code-usages', label: 'Promo', meta: {
        visible: false,
        apiEndpoints: {
            getList: 'service-promos/:promoId/promo-codes/:promoCodeId/usages',
        }
    },
    actions: {
        list: {
            allowedRoles: ['super admin'],
        },
    }
}