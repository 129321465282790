import { Button } from "components/buttons"
import Modal from "../popups/Modal"

interface FilterModalProps{
    visible: boolean
    close: () => void
    resourceName: string,
    doFilter: () => void,
    children: any,
    [key: string]: any
    subheading?: string
}

export default function FilterModal({resourceName, doFilter, children, subheading , ...rest}: FilterModalProps){
    return (
        <Modal {...rest} heading={`Filter ${resourceName}`} width="28rem" subheading={subheading}>
            {children}
            <div className="justify-end mt-4 flex-ct-y text-th-xs">
                <Button type='button' className="w-full" onClick={() => {doFilter(); rest.close()}}>Set Filter</Button>
            </div>
        </Modal>
    )
}