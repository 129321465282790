import { postCreatePromo } from "api/promo"
import { getUsers } from "api/users"
import { Button } from "components/buttons"
import { CreateEditCard, PromoCard } from "components/cards"
import { PROPERTY_TYPES } from "components/consts/property"
import { AsyncMultiSelectField, FormsGrid, MultiSelectField, SelectField, TextAreaField, TextField } from "components/forms"
import Switch from "components/forms/Switch"
import { flatten, isNaN, uniqBy } from "lodash"
import { baseApi } from "providers/customDataProvider"
import { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { MultiValue } from "react-select"
import { toast } from "sonner"
import useSWR from "swr"


const CreateServicePromo = () => {
    const { control, watch, handleSubmit,setError, clearErrors,formState: { errors } } = useForm()
    const [selectedUsers, setSelectedUsers] = useState<MultiValue<any>[]>([])
    const navigate = useNavigate()
    const { data: servicesList = [] } = useSWR('services-details', async () => {
        const res = await baseApi.get('/admin/service-promos/find-frequencies')
        return res.data
    })

    const servicesOptions: { value: any, label: string }[] = useMemo(() => {
        return servicesList.filter((service: any) => service.category).map((service: any) => ({
            value: `${service.service.id}-${service.frequency > 1 ? 'contract' : 'non'}-${service.category.id}`,
            label: `${service.service.name} ${service.frequency > 1 ? ' (Contract)' : ''} - ${service.category.name}`
        }))
    }, [servicesList])

    const getUsersOptions = async (inputValue: string) => {
        const users = await getUsers(inputValue)
        return users.map((user: any) => ({ value: +user.id, label: `${user.name} - ${user.phoneNumber}` }))
    }

    const onSubmit = handleSubmit(async (data) => {
        try {
            const isValid = validateForm(data);
            if (!isValid) return;
            const {
                userCriteria, checkoutCriteria, paymentMethod,
                includedServices, excludedServices, includeOrExcludeServices,
                includedUsers, excludedUsers, includeOrExcludeUsers,
                propertyTypes, includeOrExcludePropertyTypes,
                ...formData
            } = data
            const servicesCriteria = {
                excludedServices: includeOrExcludeServices == 'exclude' ? data.excludedServices && data.excludedServices.map((exc: any) => ({ id: parseInt(exc.split('-')[0]), isContract: exc.split('-')[1] == 'contract', categoryId: parseInt(exc.split('-')[2]) })) || [] : [],
                includedServices: includeOrExcludeServices == 'include' ? data.includedServices && data.includedServices.map((exc: any) => ({ id: parseInt(exc.split('-')[0]), isContract: exc.split('-')[1] == 'contract', categoryId: parseInt(exc.split('-')[2]) })) || [] : []
            }
            const usersCriteria = {
                excludedUsers: includeOrExcludeUsers == 'exclude' ? data.excludedUsers || [] : [],
                includedUsers: includeOrExcludeUsers == 'include' ? data.includedUsers || [] : []
            }
            const availablePropertyTypes = flatten(PROPERTY_TYPES.map(categories => categories.types.map(type => ({ value: type, label: `${categories.category} - ${type}` }))))
            const propertyTypesRes = includeOrExcludePropertyTypes == 'include' ? propertyTypes : includeOrExcludePropertyTypes == 'exclude' ? availablePropertyTypes.filter(type => !(propertyTypes || []).includes(type)).map(type => type.value) : []
            const formatMaxAmount = !data.maxAmount || isNaN(+data.maxAmount) ? null : data.maxAmount
            const body = {
                ...formData,
                ...servicesCriteria,
                ...usersCriteria,
                maxAmount: data.type == 1 ? null : formatMaxAmount,
                promoType: data.promoType,
                isVisible: data.promoType == 2 ? false : data.isVisible,
                quota: data.quota ? +data.quota : null,
                genders: data.genders?.filter((gender: string | number) => gender != 0) || [],
                forHaventBookedUser: (userCriteria || []).includes('forHaventBookedUser'),
                forHaventBookedUserInMonths: (userCriteria || []).includes('forHaventBookedUser'),
                generatorLength: data.generatorLength ? +data.generatorLength : null,
                generatorPosition: data.generatorPosition == 0 ? null : data.generatorPosition,
                paymentMethod: data.paymentMethod.length == 0 ? null : data.paymentMethod.length > 1 ? null : data.paymentMethod[0],
                propertyTypes: propertyTypesRes,
                allowPriorityBooking: data.allowPriorityBooking == 0 ? null : data.allowPriorityBooking,
                usageLimit: data.promoType == 2 ? 1 : +data.usageLimit
            }
            const response = await postCreatePromo(body)
            toast.success('Successfully created promo.')
            navigate(`/promos/${response.id}`)
        } catch (err: any) {
            console.log(err)
            toast.error(err.message)
        }
    })
    const validateForm = (data: any) => {
        const usageLimit = Number(data.usageLimit); 
        const quota = Number(data.quota)
        if (usageLimit === 0 || quota === 0) {
            if (usageLimit === 0) handleZeroError("usageLimit");
            if (quota === 0) handleZeroError("quota");       
            return false;
        }
           
        if (usageLimit > quota ) {
            setError("usageLimit", {
                type: "manual",
                message: "Usage per user cannot exceed total quota"
            });
            return false;
        }
    
        return true;
    };

  
    
    const watchForm: any = watch()
    const startDate = watchForm.startDate;
    const today = new Date().toISOString().split('T')[0];

    useEffect(() => {
        const usageLimit = Number(watchForm.usageLimit);
        const quota = Number(watchForm.quota);
        if (usageLimit === 0) {
            setError("usageLimit", {
                type: "manual",
                message: "The input value must not be 0",
            });
        } else if (usageLimit > quota) {
            setError("usageLimit", {
                type: "manual",
                message: "Usage per user cannot exceed total quota",
            });
        } else {
            clearErrors("usageLimit");
        }
    
    }, [watchForm.usageLimit, watchForm.quota, setError, clearErrors]);
    
    useEffect(() => {
        const quota = Number(watchForm.quota);
        if (quota === 0) {
            handleZeroError("quota");
        } else {
            clearErrors("quota");
        }
    
    }, [watchForm.quota, setError, clearErrors]);
    

    const handleZeroError = (fieldName: string) => {
        setError(fieldName, {
            type: "manual",
            message: "The input value must not be 0",
        });
    };
    
    const {
        type,
        generatorPosition,
        promoType = 1,
        includeOrExcludeServices,
        includeOrExcludeUsers,
        includeOrExcludePropertyTypes
    } = watchForm

    // console.log(watchForm)


    return <CreateEditCard cardHeading="Create new promo code" actions={<ActionItems promoType={promoType} control={control} />}>
        <form onSubmit={onSubmit} id="create-promo-form" className="relative grid grid-cols-6 items-start gap-6">
            <div className="grow items-center grid grid-cols-3 gap-y-6 col-span-4">
                <label className="font-medium">Promo Type <span className="text-danger">*</span></label>
                <SelectField
                    desc={`(e.g., “Normal Promo,” “Promo Generator”, “Birthday Promo”).`}
                    control={control}
                    placeholder="Please select one"
                    required
                    fieldName="promoType"
                    className="col-span-2"
                    options={[
                        { value: 1, label: 'Normal Promo' },
                        { value: 2, label: 'Generator Promo' },
                        { value: 3, label: 'Birthday Promo' },
                    ]}
                />

                <label className="font-medium" htmlFor="title">Promo Title <span className="text-danger">*</span></label>
                <TextField
                    desc={`(e.g., "Summer Sale").`}
                    control={control}
                    id={'title'}
                    isRequired
                    placeholder="Enter a promo title"
                    className="col-span-2"
                    fieldName="title"
                />

                <label className="font-medium" htmlFor="description">Promo Description <span className="text-danger">*</span></label>
                <TextAreaField
                    desc={`A multi-line text area for a detailed description of the promo`}
                    control={control}
                    id={'description'}
                    rows={7}
                    placeholder="Enter a description"
                    className="col-span-2"
                    isRequired="Please fill out this field"
                    fieldName="description"
                />


                {promoType != 2 &&
                    <>
                        <label htmlFor="code" className="font-medium">Promo Code <span className="text-danger">*</span></label>
                        <TextField
                            desc={`Text input for a custom promo code name (e.g., “AIRPLE2024”).`}
                            control={control}
                            id={'code'}
                            isRequired
                            placeholder="Enter promo code"
                            className="col-span-2"
                            fieldName="code"
                            transformUppercase
                            removeSpaces={true}
                        />
                    </>
                }

                <label className="font-medium self-start">Percentage or Amount <span className="text-danger">*</span></label>
                <div className="flex flex-col items-stretch w-full gap-2 col-span-2">
                    <div className="gap-2 w-full flex items-center">
                        <FormsGrid col={2} className="grow">
                            <SelectField
                                control={control}
                                fieldName="type"
                                placeholder="Please select one"
                                required
                                options={[{ value: 1, label: 'Fixed Amount Discount' }, { value: 2, label: 'Percentage Discount' }]}
                            />
                            <TextField
                                control={control}
                                fieldName="amount"
                                placeholder="Enter amount"
                                isRequired
                                min={0}
                                isNumeric
                            />
                        </FormsGrid>
                        <div className="text-center w-8">{type == 1 ? 'SGD' : '%'}</div>
                    </div>
                    {type == 2 &&
                        <div className="gap-2 flex items-center w-full">
                            <TextField
                                desc="A numeric input field to specify the max discount amount (e.g., $ 10)."
                                control={control}
                                id={'maxAmount'}
                                placeholder="Capped at"
                                fieldName="maxAmount"
                                className="grow"
                                isNumeric
                                min={0}
                                isRequired
                            />
                            <div className="text-center w-8 mb-4">SGD</div>
                        </div>
                    }

                </div>


                <label className="font-medium">Minimum spend <span className="text-danger">*</span></label>
                <div className="gap-2 flex items-center w-full col-span-2">
                    <TextField
                        desc={`A numeric input with currency formatting to set the minimum spend requirement.`}
                        control={control}
                        id={'minimumSpend'}
                        placeholder="Enter minimum spend"
                        className="grow"
                        fieldName="minimumSpend"
                        isNumeric
                        min={0}
                        isRequired
                    />
                    <div className="text-center w-8 mb-4">SGD</div>
                </div>

                {promoType != 2 &&
                    <>
                        <label htmlFor="paymentMethod" className="font-medium">Payment Method<span className="text-danger">*</span></label>
                        <MultiSelectField
                            desc={`Options for “Online Payment,” “Pay On Site,”."`}
                            control={control}
                            fieldName="paymentMethod"
                            className="col-span-2"
                            options={[
                                { value: 'online', label: "Online Payment" },
                                { value: 'onsite', label: "Pay On site" },
                            ]}
                            isRequired
                        />
                    </>
                }

                {promoType != 3 &&
                    <>
                        <label htmlFor="quota" className="font-medium">Number of uses (Quota) <span className="text-danger">*</span></label>
                        <TextField
                            desc={`Field to specify the quota for the total number of redemptions.`}
                            control={control}
                            id={'quota'}
                            placeholder="Enter number of usage (quota)"
                            className="col-span-2"
                            fieldName="quota"
                            min={0}
                            max={promoType == 2 ? 1000 : undefined}
                            isNumeric
                            isRequired
                        />
                    </>
                }


                {promoType == 2 &&
                    <>
                        <label htmlFor="paymentMethod" className="font-medium">Payment Method <span className="text-danger">*</span></label>
                        <MultiSelectField
                            desc={`Options for “Online Payment,” “Pay On Site,”."`}
                            control={control}
                            fieldName="paymentMethod"
                            className="col-span-2"
                            options={[
                                { value: 'online', label: "Online Payment" },
                                { value: 'onsite', label: "Pay On site" },
                            ]}
                            isRequired
                        />
                    </>
                }

                {promoType == 2 &&
                    <>
                        <label className="font-medium self-start">Promo Code Generator <span className="text-danger">*</span></label>
                        <div className="flex flex-col items-stretch w-full gap-2 col-span-2">
                            <div className="gap-2 w-full flex items-center">
                                <FormsGrid col={2} className="grow">
                                    <SelectField
                                        control={control}
                                        fieldName="generatorPosition"
                                        desc="Options for “Prefix,” “Suffix,”."
                                        placeholder="Please select one"
                                        required
                                        options={[
                                            { value: 'prefix', label: 'Prefix' },
                                            { value: 'suffix', label: 'Suffix' },
                                            { value: 0, label: 'Random Generator' },
                                        ]}
                                    />
                                    <TextField
                                        control={control}
                                        fieldName="generatorLength"
                                        placeholder="Enter length"
                                        min={0}
                                        max={10}
                                        isRequired
                                        desc='"Minimum 5" for numeric fields'
                                        isNumeric
                                    />
                                </FormsGrid>
                            </div>
                            <TextField
                                control={control}
                                desc="Text input for a custom promo code name (e.g., “AIRPLE2024”)."
                                id={'code'}
                                placeholder="Enter promo code"
                                className="col-span-2"
                                transformUppercase
                                removeSpaces={true}
                                disabled={!generatorPosition}
                                fieldName="code"
                            />
                        </div>
                    </>
                }

                {promoType != 2 ?
                    <>
                        <label htmlFor="usageLimit" className="font-medium">Usage per user <span className="text-danger">*</span></label>
                        <TextField
                            desc={`Field to specify the quota for the total number of redemptions per user.`}
                            control={control}
                            id={'usageLimit'}
                            isRequired
                            min={0}
                            placeholder="Enter number of usage per user"
                            className="col-span-2"
                            fieldName="usageLimit"
                            isNumeric
                        />
                    </> : <></>
                }


                <label htmlFor="platforms" className="font-medium">Platforms <span className="text-danger">*</span></label>
                <MultiSelectField
                    desc="Options for “Mobile App,” “Website,”."
                    control={control}
                    fieldName="platforms"
                    className="col-span-2"
                    isRequired
                    options={[{ value: 'mobile', label: 'Mobile App' }, { value: 'web', label: 'Website' }]}
                />

                {promoType != 3 &&
                    <>
                        <label htmlFor="startDate" className="font-medium">Start Date <span className="text-danger">*</span></label>
                        <TextField isRequired desc="Date the voucher becomes active for use." control={control} id="startDate" fieldName="startDate" type='date' className="col-span-2" minDate={today}/>

                        <label htmlFor="endDate" className="font-medium">End Date <span className="text-danger">*</span></label>
                        <TextField isRequired desc="Date the voucher expires and can no longer be used." control={control} id="endDate" fieldName="endDate" type='date' className="col-span-2" disabled={!startDate} startDate={startDate}
                        validateEndDate={true} />
    

                    </>
                }

                <label htmlFor="allowPriorityBooking" className="font-medium">Priority Booking Required <span className="text-danger">*</span></label>
                <SelectField
                    desc="Option for Voucher can be redeemed in specific condition."
                    control={control}
                    required
                    fieldName="allowPriorityBooking"
                    options={[
                        { value: 0, label: 'User can use promo code regardless of Priority Booking' },
                        { value: 'true', label: 'User can only use promo code if they use Priority Booking' },
                        { value: 'false', label: "User can't use promo code if they use Priority Booking" }
                    ]}
                    className="col-span-2"
                />

                <label htmlFor="includeOrExcludeServices" className="font-medium">Include or exclude services <span className="text-danger">*</span></label>
                <div className="col-span-2">
                    <div className="flex items-center gap-4">
                        <SelectField
                            control={control}
                            className="min-w-[150px]"
                            required
                            fieldName="includeOrExcludeServices"
                            options={[
                                { value: 'include', label: 'Include' },
                                { value: 'exclude', label: 'Exclude' },
                                { value: 0, label: 'N/A' },
                            ]}
                        />
                        {includeOrExcludeServices == 'include' ?
                            <MultiSelectField
                                control={control}
                                fieldName="includedServices"
                                options={servicesOptions}
                                className="grow"
                                disabled={!includeOrExcludeServices}
                                required={includeOrExcludeServices}
                                isRequired
                            /> :
                            <MultiSelectField
                                control={control}
                                fieldName="excludedServices"
                                options={servicesOptions}
                                className="grow"
                                disabled={!includeOrExcludeServices}
                                required={includeOrExcludeServices}
                                isRequired

                            />
                        }
                    </div>
                    {!errors.includeOrExcludeServices && (
                 <span className="text-[0.86em] mt-1 inline-block text-light">Add services which are included or excluded.</span>
            )}
                  
                </div>

                <label htmlFor="userCriteria" className="font-medium">User Criteria <span className="text-danger">*</span></label>
                <MultiSelectField
                    desc={`Select user criteria such as "New Users" or "Inactive users (6+ months)"`}
                    control={control}
                    fieldName="userCriteria"
                    className="col-span-2"
                    isRequired
                    options={[
                        { value: 'forHaventBookedUser', label: "New Users (haven't booked)" },
                        { value: 'forHaventBookedUserInMonths', label: "Inactive users (6+ months)" },
                        { value: 0, label: 'N/A' },
                    ]}
                />

                <label htmlFor="includeOrExcludeUsers" className="font-medium">Include or exclude users <span className="text-danger">*</span></label>
                <div className="col-span-2">
                    <div className="flex items-center gap-4">
                        <SelectField
                            control={control}
                            className="min-w-[150px]"
                            required
                            fieldName="includeOrExcludeUsers"
                            options={[
                                { value: 'include', label: 'Include' },
                                { value: 'exclude', label: 'Exclude' },
                                { value: 0, label: 'N/A' },
                            ]}
                        />
                        {includeOrExcludeUsers == 'include' ?
                            <AsyncMultiSelectField
                                control={control}
                                fieldName="includedUsers"
                                loadOptions={getUsersOptions}
                                onChange={(opt) => {
                                    setSelectedUsers((prev) => {
                                        const combinedOptions = [...prev, ...opt];
                                        return uniqBy(combinedOptions, JSON.stringify);
                                    });
                                }}
                                className="grow"
                                disabled={!includeOrExcludeUsers}
                                required={includeOrExcludeUsers}
                                isRequired
                            /> :
                            <AsyncMultiSelectField
                                control={control}
                                fieldName="excludedUsers"
                                loadOptions={getUsersOptions}
                                onChange={(opt) => {
                                    setSelectedUsers((prev) => {
                                        const combinedOptions = [...prev, ...opt];
                                        return uniqBy(combinedOptions, JSON.stringify);
                                    });
                                }}
                                className="grow"
                                disabled={!includeOrExcludeUsers}
                                required={includeOrExcludeUsers}
                                isRequired
                            />
                        }
                    </div>
                    {!errors.includeOrExcludeUsers && (
                <span className="text-[0.86em] mt-1 inline-block text-light">
                    Add users who are included or excluded.
                </span>
            )}
                </div>


                <label htmlFor="genders" className="font-medium">Genders <span className="text-danger">*</span></label>
                <MultiSelectField
                    desc="Options for “Male,” “Female,”."
                    control={control}
                    fieldName="genders" options={[{ value: 'male', label: 'Male' }, { value: 'female', label: 'Female' }, { value: 0, label: 'N/A' }]}
                    className="col-span-2"
                    isRequired
                />

                <label htmlFor="propertyTypes" className="font-medium">Include or exclude Property Types <span className="text-danger">*</span></label>
                <div className="col-span-2">
                    <div className="flex items-center gap-4">
                        <SelectField
                            control={control}
                            className=""
                            fieldName="includeOrExcludePropertyTypes"
                            required
                            options={[
                                { value: 'include', label: 'Include' },
                                { value: 'exclude', label: 'Exclude' },
                                { value: 0, label: 'N/A' },
                            ]}
                        />
                        <MultiSelectField
                            control={control}
                            fieldName="propertyTypes"
                            required={includeOrExcludePropertyTypes}
                            isRequired
                            disabled={!includeOrExcludePropertyTypes}
                            className="grow"
                            options={flatten(PROPERTY_TYPES.map(categories => categories.types.map(type => ({ value: type, label: `${categories.category} - ${type}` }))))}
                        />
                    </div>
                    {!errors.includeOrExcludePropertyTypes && (
                 <span className="text-[0.86em] mt-1 inline-block text-light">Add property types which are included or excluded.</span>
            )}
                  
                </div>
            </div>
            <div className="grow sticky col-span-2 top-44">
                <PromoCard
                    {...watchForm}
                    genders={watchForm.genders?.filter((gender: string | number) => gender != 0)}
                    mode={'create'}
                    className="bg-white translate-y-[55%] -mt-[9%]"
                    servicesList={servicesList}
                    selectedUsers={selectedUsers}
                />
            </div>

        </form>
    </CreateEditCard>
}

export default CreateServicePromo

type ActionItemsProps = {
    control: FormHookType['control']
    promoType: number
}

const ActionItems = (props: ActionItemsProps) => {
    const { control, promoType } = props
    return <div className="flex items-center gap-8">
        {promoType != 2 &&
            <Switch label="Show Promo Code" name={'isVisible'} control={control} />
        }
        <Button confirm form='create-promo-form'>Save and Publish</Button>
    </div>
}