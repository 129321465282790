import { useTable } from "@refinedev/core";
import Badge from "components/Badge";
import EmptySection from "components/EmptySection";
import { TextField } from "components/forms";
import { SearchIcon } from "components/Svg";
import {
  Table,
  TAction,
  TActionItem,
  TBody,
  Td,
  Th,
  THead,
} from "components/Table";
import TabletWidgets, {
  TabletWidgetLeft,
  TabletWidgetRight,
} from "components/TableWidgets";
import { NavLink } from "react-router-dom";
import { SERVICE_ORDER_CONFIG } from "resources-config/service-order/service-order-config";
import { formatFullDate } from "utils/date";
import useTableQuery from "utils/useTableQuery";

type Props = {
  rating?: MiscObj;
  userId?: any;
};

const ShowUserRatingList = (props: Props) => {
  const { rating, userId } = props;
  const {
    tableQueryResult,
    pageSize,
    current,
    setCurrent,
    sorters,
    setSorters,
    filters,
    setFilters,
  } = useTable({
    queryOptions: { retry: false },
    pagination: { current: 1, pageSize: 10 },
    resource: `${userId}/customer-ratings`,
    filters: {
      initial: [
        {
          field: "userId",
          operator: "eq",
          value: userId,
        },
      ],
    },
  });
  const { control: controlSearch, doFilter } = useTableQuery(
    filters,
    setFilters
  );

  return (
    <>
      <TabletWidgets>
        <TabletWidgetLeft>
          <TextField
            Icon={<SearchIcon />}
            control={controlSearch}
            fieldName="search"
            placeholder="Search by order ID or technician name"
            className="w-full"
            handleEnter={doFilter}
          />
        </TabletWidgetLeft>
        <TabletWidgetRight>
          <div className="flex gap-2 items-center">
            <Badge color="#9DC7F1" className="!text-[#0B5CAD]">
              {tableQueryResult.data && tableQueryResult.data.data.length || 0}
            </Badge>
            <span className="text-th-sm">Review found</span>
          </div>
        </TabletWidgetRight>
      </TabletWidgets>
      {tableQueryResult.data && tableQueryResult.data.data.length > 0 ? (
        <Table>
          <THead>
            <Th sorting={{ column: 'createdAt', sorters: sorters, setSorters: setSorters }}>Review Date</Th>
            <Th sorting={{ column: 'id', sorters: sorters, setSorters: setSorters }}>Order & Job ID</Th>
            <Th>Technician</Th>
            <Th>Review</Th>
            <Th>Feedback</Th>
            <Th>Action</Th>
          </THead>
          {tableQueryResult.data && tableQueryResult.data.data.length > 0 ? (
            tableQueryResult.data.data.map((data: any) => (
              <TBody key={`rating-${data?.id}`}>
                <Td>{formatFullDate(data?.createdAt, { showTime: false })}</Td>
                <Td>
                  {data?.schedule
                    ? `${
                        data?.schedule?.order?.id
                          ? `OID${data?.schedule?.order?.id} - `
                          : ""
                      }${
                        data?.schedule?.id ? `  JID${data?.schedule?.id}` : ""
                      }`
                    : "-"}
                </Td>

                <Td>
                  {data?.schedule?.completingTechnicians &&
                  data.schedule.completingTechnicians.length > 0
                    ? data.schedule.completingTechnicians
                        .map(
                          (technician: any) =>
                            technician.technician?.name || "-"
                        )
                        .join(", ")
                    : "-"}
                </Td>

                <Td>
                  <Badge
                    color={
                      data.rating >= 4.5
                        ? "#24663B"
                        : data.rating >= 3.5
                        ? "#8F4700"
                        : "#BB032A"
                    }
                  >
                    {data?.rating?.toFixed(2)}
                  </Badge>
                </Td>
                <Td>
                  Technician comments : {data.otherComments}
                  {data?.isRude ? (
                    <span className="font-medium">
                      <br /> * Customer is rude
                    </span>
                  ) : (
                    <></>
                  )}
                  {data?.cannotSpeakEnglish ? (
                    <span className="font-medium">
                      <br /> * Customer cannot speak english
                    </span>
                  ) : (
                    <></>
                  )}
                  {data?.isLookingAttech ? (
                    <span className="font-medium">
                      <br /> * Customer is looking at technician
                    </span>
                  ) : (
                    <></>
                  )}
                  {data?.isRushing ? (
                    <span className="font-medium">
                      <br /> * Customer is rushing
                    </span>
                  ) : (
                    <></>
                  )}
                </Td>

                {data?.schedule ? (
                  <Td className="text-primary text-sm font-medium text-end">
                    <NavLink
                      className={"text-sm"}
                      to={`/service-orders/${data?.schedule?.order?.id}`}
                    >
                      View
                    </NavLink>
                  </Td>
                ) : (
                  <Td className="text-primary text-sm font-medium text-center">
                    View
                  </Td>
                )}
              </TBody>
            ))
          ) : (
            <></>
          )}
        </Table>
      ) : (
        <EmptySection
          title="No Review Found"
          body="Customer has never given a review to the technician"
        />
      )}
    </>
  );
};

export default ShowUserRatingList;
