import { useModalReturnType } from "@refinedev/core";
import { postWithdrawCredits } from "api/users";
import { Button } from "components/buttons";
import { SelectField, TextAreaField, TextField } from "components/forms";
import UploadField from "components/forms/UploadField";
import { Modal } from "components/popups";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { formatToFormData } from "utils/data-formatter";

type Props = {
  modal: useModalReturnType;
  userId: any;
  creditAmount: any;
};

const WithDrawCreditsModal = (props: Props) => {
  const { modal, userId, creditAmount } = props;
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data: any) => {
    try {
      const body = {
        ...data,
        amount: creditAmount,
        withdrawMethod: data.paymentMethod,
        notes: data.notes,
      };
      console.log("body", body)
      const formData = formatToFormData(body, ["proofs"]);
      await postWithdrawCredits(userId, formData);

      // console.log("Success withdraw credits:", formData)
      toast.success("Success")
      modal.close();
    } catch (error: any) {
      toast.error(error.message)
      console.error("Error:", error.message || error);
    }
  };

  return (
    <Modal
      visible={modal.visible}
      close={modal.close}
      heading="Withdraw Credit to Customer"
      width="30rem"
    >
      <h5 className="text-light text-th-sm">
        Withdraw the available credit from the Airple to the Customer
      </h5>
      <div className="flex justify-between py-8">
        <h3 className="text-th-sm font-medium text-[#404040]">Credit amount</h3>
        <span className="text-primary text-th-lg">
          $ {creditAmount.toFixed(2)}
        </span>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
        <div>
          <label htmlFor="dateTime" className="font-medium">
            Date Time <span className="text-danger">*</span>
          </label>
          <TextField
            isRequired
            control={control}
            id="dateTime"
            fieldName="dateTime"
            type="date"
            className="col-span-2"
          />
        </div>

        <div>
          <label htmlFor="paymentMethod" className="font-medium">
            Method <span className="text-danger">*</span>
          </label>
          <SelectField
            required
            placeholder="Select Payment Method"
            control={control}
            fieldName="paymentMethod"
            options={PAYMENT_METHODS_OPTIONS}
            shape="outline"
          />
        </div>
        <UploadField
          control={control}
          fieldName="proofs"
          multiple
          label={"Upload Proof Image"}
        />
        <TextAreaField
          className="mt-5"
          label="Memo / Notes"
          fieldName="notes"
          control={control}
          placeholder="Type transaction notes"
        />
        <Button className="w-full" type="submit">
          Record Withdrawals
        </Button>
      </form>
    </Modal>
  );
};


export default WithDrawCreditsModal;

const PAYMENT_METHODS_OPTIONS = [
  {
    label: "Cash",
    value: "Cash",
  },
  {
    label: "Bank Transfer",
    value: "Bank Transfer",
  },
];
