import { useModalReturnType } from "@refinedev/core";
import { Modal } from "components/popups";

type Props = {
  modal: useModalReturnType;
  handleConfirm: any;
  title?: string;
  body?: string;
};

const DangerModal = (props: Props) => {
  const { modal, handleConfirm, title, body } = props;
  return (
    <Modal
      visible={modal.visible}
      close={modal.close}
      heading="Confirmation"
      width="30rem"
    >
      <div className="hide-scrollbar">
        <div className="w-full flex flex-col items-center gap-8 ">
          <div className="flex flex-col items-center gap-6 text-center  tablet-below:px-4">
            <p className=" text-th-lg text-[#404040] font-medium leading-7 whitespace-pre-wrap">
              {title ? title : ""}
            </p>
            <p className="text-th-base text-light mx-10 whitespace-pre-wrap">
            {body ? body : ""}
            </p>
          </div>
          <div className="flex  w-full gap-4 justify-end">
            <button
              type="button"
              className="btn border-danger bg-transparent hover:opacity-70 transition-all text-danger  w-fit flex justify-center py-2.5"
            >
              <p className="text-th-xs" color="#CB3A31" onClick={modal.close}>
                Cancel
              </p>
            </button>
            <button
              type="submit"
              className="btn  border-none  bg-danger hover:bg-danger/50 w-fit flex justify-center py-2.5"
            >
              <p className="text-th-xs" onClick={handleConfirm}>
                Yes
              </p>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DangerModal;
