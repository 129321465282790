import { useForm } from "@refinedev/react-hook-form"

import PageTitle from "components/PageTitle";
import { CreateEditCard } from "components/cards";
import { Button } from "components/buttons";
import { useEffect, useMemo, useState } from "react";
import { handleMutation } from "utils/handle-mutation";
import { SERVICE_ORDER_CONFIG } from "resources-config/service-order/service-order-config";
import { AsyncMultiSelectField, AsyncSelectField, CounterField, CountryPhoneInput, FormsGrid, FormsSection, SelectField, TextField } from "components/forms";
import { baseApi } from "providers/customDataProvider";
import { useFieldArray } from "react-hook-form";
import { CustomerDetailIcon, PlusScheduleIcon, ServiceListIcon, TechniciansIcon, TrashIcon } from "components/Svg";
import { getErrorNotif, getSuccessNotif } from "utils/data-formatter";
import { useModal } from "@refinedev/core";
import { IoAddCircle } from "react-icons/io5";
import AddressLocation from "components/location-picker/AddressLocation";
import { formatAddressAny, formatBuildingAddress, formatCountry } from "utils/address";
import { PlaceInterface } from "utils/map";
import FindAddressPopup from "components/location-picker/FindAddressPopup";
import PinpointPopup from "components/location-picker/PinpointPopup";
import { AutoLink, FieldInfo } from "components/texts";
import useSWR from "swr";
import { getActiveServices } from "api/service";
import { toast } from "sonner";
import { ChooseItemEffects } from "components/effects";
import { patchEditUser, postCreateUser } from "api/users";
import { universalPhoneRegex } from "utils/regex";
import { flatten } from "lodash";
import { calculateDiscount, calculateGST } from "utils/discounts";
import Pill from "components/Pill";
import { useSearchParams } from "react-router-dom";

export default function CreateServiceOrder() {
    const [btnDisabled, disableBtn] = useState(false)
    const { data: services = [] } = useSWR('active-services', getActiveServices)
    const [selectedPlace, setSelectedPlace] = useState<PlaceInterface>()
    const [addressList, setAddressList] = useState<any[]>([])
    const promoModal = useModal()
    const findAddressModal = useModal()
    const pinpointModal = useModal()
    const [searchParams] = useSearchParams()
    const initialUser = searchParams.get('user')
    const {
        refineCore: { onFinish, mutationResult }, saveButtonProps: { disabled }, control, clearErrors, setError,
        watch, setValue, handleSubmit, resetField
    } = useForm<MiscObj>({
        refineCoreProps: {
            resource: SERVICE_ORDER_CONFIG.name,
            action: 'create',
            successNotification(data) {
                let actionUrl = undefined
                if (data && data.data) {
                    actionUrl = `/service-orders/${data.data.id}`
                }
                return getSuccessNotif('create', 'service-order', actionUrl)
            },
            errorNotification(data) {
                return getErrorNotif('create', 'service-order', data)
            },
        },
    });

    useEffect(() => {
        if (initialUser)
            setValue('user', +initialUser)
    }, [])

    const itemFieldArr = useFieldArray({ control: control, name: 'items' })
    const items = watch('items')
    const userWatch = watch('user')
    const itemSelect = watch('itemSelect')
    const onFinishHandler: any = async (data: any) => {
        try {
            if (data.user == -1) {
                let user = await postCreateUser({ ...data, phoneNumber: `+${data.phoneNumber}` })
                user = await patchEditUser(user.id, {
                    ...user,
                    addresses: [{
                        ...data,
                        isDefault: true
                    }]
                })
                onFinish({
                    ...data,
                    price: parseFloat(data.price),
                    user: parseInt(user.id),
                    userAddress: parseInt(user.addresses[0].id),
                    upsellTechnicians: [...(data.leaderTechnicians || []).map((lt: any) => ({ id: lt, role: 1 })), ...(data.assistantTechnicians || []).map((at: any) => ({ id: at }))],
                })
            }
            else onFinish({
                ...data,
                price: parseFloat(data.price),
                upsellTechnicians: [...(data.leaderTechnicians || []).map((lt: any) => ({ id: lt, role: 1 })), ...(data.assistantTechnicians || []).map((at: any) => ({ id: at }))],

            })
        } catch (err: any) {
            console.error(err)
            if (err.message && err.message[0]) {
                toast.error(err.message)
                return
            }
            toast.error('Error occured. Please contact support.')
        }
    }

    const grandTotal = useMemo(() => {
        if (!items) return 0
        return items.map((item: any) => item.price ? parseFloat(item.price) : 0).reduce((acc: any, current: any) => acc + current, 0)
    }, [itemFieldArr])

    const handleSetAddress = (place: PlaceInterface) => {
        setValue('latitude', place.lat)
        setValue('longitude', place.lng)
        pinpointModal.close()
        findAddressModal.close()
        setSelectedPlace(place)
        setValue('street', place.street)
        setValue('postalCode', place.postalCode)
        setValue('buildingNumber', place.buildingNumber)
    }

    const handleAddItem = () => {
        const service = services.find((service: any) => service.id == itemSelect)
        if (!service) {
            toast.error('Item not found')
            setValue('itemSelect', null)
            return
        }
        itemFieldArr.append({ frequency: null, quantity: 1, price: '', name: service.name, service: service.id })
    }

    const handleLoadLeadSource = async (inputValue: string) => {
        const res = await baseApi.get(`/admin/lead-sources?name=${inputValue}&isActive=true`)
        return res.data.map((lead: any) => ({ value: +lead.id, label: lead.name }))
    }

    useEffect(() => {
        if (!userWatch || userWatch == -1) return
        // if (!userWatch || userWatch != -1) {
        //     resetField('phoneNumber')
        //     resetField('name')
        //     resetField('email')
        //     return
        // }
        baseApi.get(`/admin/users/${userWatch}`).then(response => {
            setValue('name', response.data.name)
            setValue('phoneNumber', response.data.phoneNumber.replace('+', ''))
            setValue('email', response.data.email)
            setAddressList(response.data.addresses ? response.data.addresses : [])
        })
    }, [userWatch])

    useEffect(() => {
        handleMutation(mutationResult, setError, clearErrors, disableBtn)
    }, [mutationResult, setError, clearErrors, disableBtn])

    return (<form onSubmit={handleSubmit(onFinishHandler)} id='service-order-form'>
        <PageTitle title="Create Order" />
        <CreateEditCard
            cardHeading="New Order"
            actions={
                <div className="flex items-start gap-2">
                    <SelectField required placeholder="Select Payment Method" control={control} fieldName="paymentMethod" options={PAYMENT_METHODS_OPTIONS} shape="outline">
                        Select Payment Method
                    </SelectField>
                    <Button type="button" form={'service-order-form'} confirm disabled={itemFieldArr.fields.length == 0} isLoading={disabled}>Save and Continue</Button>
                </div>
            }
        >
            <div className="flex flex-col gap-4 mt-4">
                <FormsSection Icon={<CustomerDetailIcon />} title="Customer Details" className="space-y-4">
                    <FormsGrid col={initialUser ? 3 : 4}>
                        {!initialUser &&
                            <UserField setValue={setValue} control={control} />
                        }
                        {/* <TextField disabled={userWatch != -1} control={control} label={'Contact Number'} required fieldName="phoneNumber" /> */}
                        <CountryPhoneInput control={control} fieldName="phoneNumber" label={'Contact Number'} required disabled={userWatch != -1} />
                        <TextField disabled={userWatch != -1} control={control} label={'Customer Name'} required fieldName="name" />
                        <TextField disabled={userWatch != -1} control={control} regex="email" label={'Customer Email'} fieldName="email" />
                    </FormsGrid>
                    {userWatch == -1 ?
                        <>
                            <AddressLocation
                                className="w-full"
                                onClick={findAddressModal.show}
                                name={selectedPlace ? formatBuildingAddress(selectedPlace.buildingNumber, selectedPlace.street) : 'Not Selected'}
                                address={selectedPlace ? formatCountry(selectedPlace.country, selectedPlace.postalCode) : 'Not Selected'}
                            />
                            <FormsGrid FormsGrid col={4}>
                                <TextField control={control} label={'Postal Code'} required fieldName="postalCode" />
                                <TextField control={control} label={'Block Number'} required fieldName="buildingNumber" />
                                <TextField control={control} label={'Street Name'} required fieldName="street" />
                                <TextField control={control} label={'Building Name'} fieldName="buildingName" />
                                <TextField control={control} label={'Floor Number'} fieldName="floorNumber" />
                                <TextField control={control} label={'Unit Number'} fieldName="unitNumber" />
                                <SelectField
                                    options={flatten(UNIT_TYPES.map(categories => categories.types.map(type => ({ value: type, label: `${categories.category} - ${type}` }))))}
                                    control={control} label={'Address Type'} required fieldName="unitType" />
                                <TextField control={control} label={'Address Label'} required fieldName="addressLabel" />
                            </FormsGrid>
                        </> :
                        <>
                            {(userWatch) &&
                                <div className="space-y-1.5">
                                    <SelectField
                                        fieldName="userAddress"
                                        label={'Address'}
                                        required
                                        placeholder={'Select customer address'}
                                        options={addressList.map(address => ({ value: parseInt(address.id), label: `${formatAddressAny(address)}` }))}
                                        control={control}
                                    />
                                    <div className="flex justify-end">
                                        <AutoLink href={`/users/edit/${userWatch}`} className="text-primary flex justify-end items-center gap-2">
                                            <IoAddCircle />
                                            <span>
                                                Add new Address
                                            </span>
                                        </AutoLink>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </FormsSection>
                {/* <FormsSection Icon={<TechniciansIcon color="#1945A1" width={28} height={28} />} title="Upsell Technicians">
                    <TechField control={control} setValue={setValue} watch={watch} />
                </FormsSection> */}
                <FormsSection Icon={<ServiceListIcon />} title="Service List" leading={
                    <div className="flex items-center gap-2">
                        <label>Lead Source <span className="text-danger">*</span></label>
                        <AsyncSelectField required className="min-w-36" placeholder="Select lead source" loadOptions={handleLoadLeadSource} control={control} fieldName="leadSource" />
                    </div>
                }>
                    <div className="bg-primary-2-surface py-4 grid grid-cols-6 gap-3 font-medium px-4">
                        <span>Items</span>
                        <div className="col-span-4 grid grid-cols-4 w-full text-center">
                            <span>Unit Category</span>
                            <span>Frequency</span>
                            <span>Quantity</span>
                            <span>Price Per Unit</span>
                        </div>
                        <span className="self-end text-center">Amount</span>

                    </div>
                    <div className="space-y-4">
                        {
                            itemFieldArr.fields ? itemFieldArr.fields.map((item: MiscObj, idx: number) => (
                                <ItemFields
                                    setValue={setValue}
                                    resetField={resetField}
                                    services={services}
                                    key={item.id}
                                    item={item}
                                    index={idx}
                                    control={control}
                                    fieldArr={itemFieldArr}
                                    watch={watch}
                                />
                            )) : <></>
                        }
                        <div className="border-b pb-4 flex items-end gap-4">
                            <SelectField
                                control={control}
                                options={services.map((service: any) => ({ value: +service.id, label: service.name }))}
                                fieldName={`itemSelect`}
                                className="w-full"
                                defaultCfg={{ value: null, setValue: setValue }}
                                label='New Item'
                                placeholder='Select an item to add'
                            />
                            <Button onClick={handleAddItem} disabled={!itemSelect} shape="outline" className="text-theme-xxs whitespace-nowrap">
                                <PlusScheduleIcon color="#1954A1" /> Add Item
                            </Button>
                        </div>
                        <div className="flex flex-col items-end gap-4">
                            <FieldInfo horizontal labelWeight="normal" label="Subtotal" value={`$ ${(grandTotal - calculateGST(grandTotal)).toFixed(2)}`} />
                            <FieldInfo horizontal labelWeight="normal" label="GST" value={`$ ${calculateGST(grandTotal).toFixed(2)}`} />
                            <FieldInfo horizontal label="Grand Total" value={`$ ${grandTotal ? +grandTotal.toFixed(2) : ''}`} />
                        </div>
                    </div>
                </FormsSection>
            </div>
        </CreateEditCard >
        <FindAddressPopup visible={findAddressModal.visible} openPinPoint={pinpointModal.show} handleSetAddress={handleSetAddress} close={findAddressModal.close} />
        <PinpointPopup visible={pinpointModal.visible} close={pinpointModal.close} openFindAddress={findAddressModal.show} handleSetAddress={handleSetAddress} />
    </form>)
}

function TechField({ control, setValue, watch }: { control: FormHookType['control'], setValue: FormHookType['setValue'], watch: FormHookType['watch'] }) {
    const getTechnicians = async (search: string) => {
        const { data } = await baseApi.get(`/admin/technicians?search=${search}`)
        return data.list.map((tech: MiscObj) => ({
            value: +tech.id, label: `${tech.name}`
        }))
    }

    const technicians = watch()

    const selectedTechnicians = useMemo(() => {
        const leaderTechnicians = technicians.leaderTechnicians || []
        const assistantTechnicians = technicians.assistantTechnicians || []
        return [...leaderTechnicians, ...assistantTechnicians]
    }, [technicians])

    const promiseOptions = (inputValue: string) => new Promise<any[]>((resolve) => {
        resolve(getTechnicians(inputValue))
    })

    return <FormsGrid col={2}>
        <AsyncMultiSelectField
            control={control}
            fieldName="leaderTechnicians" loadOptions={promiseOptions}
            label={'Leader Upsell Technicians'}
            filterOption={({ value }) => !selectedTechnicians.includes(value)}
        />
        <AsyncMultiSelectField
            control={control}
            fieldName="assistantTechnicians" loadOptions={promiseOptions}
            label={'Assistant Upsell Technicians'}
            filterOption={({ value }) => !selectedTechnicians.includes(value)}
        />
    </FormsGrid>
}

function UserField({ control, setValue }: { control: FormHookType['control'], setValue: FormHookType['setValue'] }) {
    const getUser = async (search: string) => {
        const { data } = await baseApi.get(`/admin/users?search=${search}&activeStatus=active`)
        return data.list.map((user: MiscObj) => ({
            value: +user.id, label: `${user.name} - ${user.phoneNumber}`
        }))
    }

    const promiseOptions = (inputValue: string) => new Promise<any[]>((resolve) => {
        resolve(getUser(inputValue))
    })

    const handleOnCreate = (inputValue: string) => {
        // setValue('user', -1)
        if (universalPhoneRegex.test(inputValue)) {
            setValue('name', null)
            setValue('phoneNumber', inputValue.replace('+', ''))

        }
        else {
            setValue('name', inputValue)
            setValue('phoneNumber', '65')
        }
        setValue('email', null)
        setValue('user', -1)
    }

    return (
        <AsyncSelectField control={control}
            fieldName={`user`} required
            onCreateOption={handleOnCreate}
            label='Search Customer' placeholder="Search by name or phone number" loadOptions={promiseOptions}
        />
    )
}

type ItemFieldProps = {
    index: number,
    control: FormHookType['control'],
    fieldArr: FormHookType['fieldArr'],
    watch: FormHookType['watch'],
    services: any[]
    resetField: FormHookType['resetField']
    setValue: FormHookType['setValue']
    item: MiscObj
}

function ItemFields({ index, control, fieldArr, watch, services, resetField, setValue, item }: ItemFieldProps) {
    const name = `items.${index}.`
    const serviceId = watch(`${name}service`)
    const categoryId = watch(`${name}category`)
    const frequencyId = watch(`${name}frequency`)
    const quantity = watch(`${name}quantity`)
    const pricePerUnit = watch(`${name}pricePerUnit`)
    const finalPrice = watch(`${name}price`)
    const [availableCategories, setAvailableCategories] = useState<any[]>([])
    const [availableFrequencies, setAvailableFrequencies] = useState<any[]>([])
    const [initialPricePerUnit, setInitialPricePerUnit] = useState<number>(0)
    const additionalLabels = availableFrequencies.map((freq, index) => ({
        value: +freq.id,
        label: freq.label
      }));
    return (
        <ChooseItemEffects
            categoryId={categoryId}
            serviceId={serviceId}
            setAvailableCategories={setAvailableCategories}
            setAvailableFrequencies={setAvailableFrequencies}
            setValue={setValue}
            name={`${name}`}
            availableFrequencies={availableFrequencies}
            quantity={quantity}
            frequencyId={frequencyId}
            pricePerUnit={pricePerUnit}
            setInitialPricePerUnit={setInitialPricePerUnit}
        >
            <div className="space-y-4 border-b pt-4 pb-8">
                <div className="grid grid-cols-6 place-items-start gap-3">
                    <div className="">
                        {item.name}
                    </div>
                    <div className="col-span-4">
                        <div className="flex flex-col gap-4">
                            <FormsGrid col={4}>
                                <SelectField
                                    disabled={!availableCategories || availableCategories.length == 0 || !serviceId}
                                    defaultCfg={{ value: null, setValue: setValue }}
                                    control={control}
                                    options={availableCategories.map(category => ({ value: +category.id, label: category.name }))}
                                    fieldName={`items.${index}.category`} className="w-full" required
                                    placeholder="Select category"
                                />
                                <SelectField
                                    disabled={!availableFrequencies || availableFrequencies.length == 0}
                                    control={control}
                                    options={availableFrequencies.map(freq => ({ value: +freq.id, label: `${freq.frequency} times` }))}
                                    fieldName={`items.${index}.frequency`}
                                    className="w-full" required
                                    defaultCfg={{ value: null, setValue: setValue }}
                                    placeholder="Select frequency"
                                    additionalLabels={additionalLabels} 
                                />
                                <CounterField
                                    disabled={!availableFrequencies || availableFrequencies.length == 0}
                                    control={control}
                                    fieldName={`items.${index}.quantity`}
                                    required
                                    placeholder="Select quantity"
                                />
                                <div className="flex flex-col">
                                    <TextField
                                        required
                                        type="number"
                                        disabled={!frequencyId}
                                        fieldName={`items.${index}.pricePerUnit`}
                                        control={control}
                                        isNumeric
                                        className="w-full"
                                        placeholder="Price per unit"
                                    />
                                    <div className="flex items-center justify-between mt-2">
                                        <span className="text-[0.625rem] text-light">Initial price/unit/freq : $ {initialPricePerUnit.toFixed(2)}</span>
                                        {calculateDiscount(pricePerUnit, initialPricePerUnit) > 0 &&
                                            <Pill backgroundColor="#FF6666" textColor="white" fontSize={10}>{calculateDiscount(pricePerUnit, initialPricePerUnit)} %</Pill>
                                        }
                                    </div>
                                </div>
                            </FormsGrid>
                            <TextField
                                control={control}
                                fieldName={`items.${index}.notes`}
                                placeholder='Enter a note'
                            />
                        </div>
                    </div>
                    <div className="col-span-1 flex items-center gap-2">
                        {/* <TextField
                            required
                            disabled={!frequencyId}
                            fieldName={`items.${index}.price`}
                            control={control}
                            isNumeric
                            className="w-full"
                            type='hidden'
                            placeholder="Total Price"
                            readOnly
                        /> */}
                        <FieldInfo value={`$ ${finalPrice}`} fieldClassName="w-full bg-gray-100" />
                        <button type="button" onClick={() => fieldArr.remove(index)}>
                            <TrashIcon />
                        </button>
                    </div>
                </div>
            </div>
        </ChooseItemEffects>

    )
}

const UNIT_TYPES = [
    {
        category: 'Residential',
        types: ['HDB', 'Condominium', 'Landed']
    },
    {
        category: 'Commercial',
        types: ['Office', 'Shop', 'Factory', 'Warehouse', 'Business Park']
    },
]

const PAYMENT_METHODS_OPTIONS = [
    {
        label: 'Online Payment - 15 Mins Expiry',
        value: 1
    },
    {
        label: 'Online Payment - No Expiry',
        value: 2
    },
    {
        label: 'Pay On Site',
        value: 3
    },
]