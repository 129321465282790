import { useTable } from "@refinedev/core";
import { BaseCard } from "components/cards";
import { TextField } from "components/forms";
import PageTitle from "components/PageTitle";
import Pagination from "components/Pagination";
import Pill from "components/Pill";
import ScheduleStatusPill from "components/ScheduleStatusPill";
import { SearchIcon } from "components/Svg";
import { Table, THead, Th, TBody, Td } from "components/Table";
import TabletWidgets, { TabletWidgetLeft, TabletWidgetRight } from "components/TableWidgets";
import { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { MISSING_REPORTS_CONFIG } from "resources-config/service-order/missing-reports-config";
import { formatFullDate } from "utils/date";
import useTableQuery from "utils/useTableQuery";


export default function ListMissingReports() {
    const { tableQueryResult, pageSize, current, setCurrent, setSorters, sorters, filters, setFilters } = useTable({
        queryOptions: { retry: false, },
        pagination: { current: 1, pageSize: 10 },
        resource: MISSING_REPORTS_CONFIG.name,
    })


    const { control, doFilter } = useTableQuery(filters, setFilters)

    const changePage = useCallback((page: number | string) => {
        setCurrent(+page)
    }, [setCurrent])

   const totalItems =  tableQueryResult.data?.total || 0
    const totalPages = useMemo(() => (
        !totalItems || !pageSize ? 0 : Math.ceil(totalItems/pageSize)
    ), [totalItems, pageSize])
    console.log("data" , tableQueryResult?.data?.data)
    return <>
        <PageTitle />
        <BaseCard>
            <TabletWidgets>
                <TabletWidgetLeft>
                    <TextField
                       Icon={<SearchIcon />}
                        control={control} fieldName='search' placeholder="Search by Job ID, customer, or technician"
                        className='w-full' handleEnter={doFilter}
                    />
                </TabletWidgetLeft>
                <TabletWidgetRight>
                    <Pill textColor='#0B5CAD' backgroundColor='#CBE2F9'>{tableQueryResult.data ? tableQueryResult.data.total : 0}</Pill>missing service reports
                </TabletWidgetRight>
            </TabletWidgets>
            <Table>
                <THead>
                    <Th sorting={{ column: 'id', sorters: sorters, setSorters: setSorters }}>Job ID</Th>
                    <Th>Customer Name</Th>
                    <Th>Services</Th>
                    <Th>Assigned Technicians</Th>
                    <Th sorting={{ column: 'startDate', sorters: sorters, setSorters: setSorters }}>Job Date</Th>
                    <Th>Job Status</Th>
                    <Th>Order Detail</Th>
                </THead>
                {tableQueryResult && tableQueryResult.data ? (tableQueryResult.data.data).map((schedule, index) => {
                    console.log(schedule)
                    return <TBody key={index}>
                        <Td>JID{schedule.id}</Td>
                        <Td>{schedule.user.name}</Td>
                        <Td>
                            <ul className="list-disc ml-3">
                                {schedule.tasks.map((task: any) => <li>{task.item.service.name}</li>)}
                            </ul>
                        </Td>
                        <Td>
                            {schedule.completingTechnicians?.length > 0 ?
                                schedule.completingTechnicians.map((ct: any) => `${ct.technician.name}${ct.role ? ' (Leader)' : ''}`).join(',') :
                                schedule.technicianTeam.assignedDates[0]?.assignedTechnicians.map((at: any) => `${at.technician.name}${at.role ? '(Leader)' : ''}`).join(',')
                            }
                        </Td>
                        <Td className="min-w-[150px]">{formatFullDate(schedule.startDate, { showTime: false, showDay: false })}</Td>
                        <Td>
                            <ScheduleStatusPill schedule={schedule} />
                        </Td>
                        <Td>
                            <Link to={`/service-orders/${schedule.order.id}`} className="font-medium text-primary ml-auto">View</Link>
                        </Td>
                    </TBody>
                }) : <></>}
            </Table>
            <div className='justify-between mt-4 flex-ct-y'>
            <span className='text-[#344054]'>Page {current} of {totalPages}</span>
                <Pagination
                    pageSize={pageSize} currentPage={current} totalItems={tableQueryResult.data?.total || 0}
                    clickHandler={changePage} range={5}
                />
            </div>
        </BaseCard >
    </>
}