import { capitalize, create } from "lodash";
import { formatFullDate } from "utils/date";

type Props = {
    dbLog?: MiscObj
}

const DisplayDbLog = (props: Props) => {
    const { dbLog } = props

    if (!dbLog) return <span className="text-light">No changes made.</span>

    let action;
    let userType = capitalize(dbLog.userType);
    let name = dbLog.changedBy?.name ? dbLog.changedBy.name : dbLog.changedBy?.id;
    let createdAt = formatFullDate(dbLog.createdAt);


    switch (dbLog.operationType) {
        case 'UPDATE':
            action = 'Last Changed'
            break
        case 'INSERT':
            action = 'Created'
            break
        case 'SOFT_DELETE':
            action = 'Removed'
            break
        case 'DELETE':
            action = 'Removed'
            break
        default:
            break
    }

    return <div className="flex items-center gap-1 text-light">
        <span className="font-medium">{action} by <b>{userType} {name}</b> at </span>
        <b>{createdAt}</b>
    </div>
}

export default DisplayDbLog