import { useForm } from "@refinedev/react-hook-form"

import PageTitle from "components/PageTitle";
import { useEffect, useState } from "react";
import { handleMutation } from "utils/handle-mutation";
import { getErrorNotif, getSuccessNotif } from "utils/data-formatter";
import { patchEditUser, postCreateUser } from "api/users";
import { QUOTATION_CONFIG } from "resources-config/quotation/quotation-config";
import FormFieldQuotation from "./FormFieldQuotation";
import { useFieldArray } from "react-hook-form";
import { isArray } from "lodash";
import { baseApi } from "providers/customDataProvider";
import { toast } from "sonner";
import { isAfter, isBefore } from "date-fns";
import { useSearchParams } from "react-router-dom";

export default function CreateQuotation() {
    const [btnDisabled, disableBtn] = useState(false)
 const [searchParams] = useSearchParams()
    const initialUser = searchParams.get('user')
    const {
        refineCore: { onFinish, mutationResult }, control, clearErrors, setError,
        watch, setValue, handleSubmit, resetField, formState: { errors }
    } = useForm<MiscObj>({
        refineCoreProps: {
            resource: QUOTATION_CONFIG.name,
            action: 'create',
            successNotification(data) {
                let actionUrl = undefined
                if (data && data.data) {
                    actionUrl = `/quotations/${data.data.id}`
                }
                return getSuccessNotif('create', 'quotations', actionUrl)
            },
            errorNotification(data) {
                return getErrorNotif('create', 'quotations', data)
            },
        },
    });
    useEffect(() => {
        if (initialUser)
            setValue('user', +initialUser)
    }, [])
    const userWatch = watch('user')
    const itemFieldArr = useFieldArray({ control: control, name: 'quotationItemCarts' })
    const discountFieldArr = useFieldArray({ control: control, name: 'discounts' })
    useEffect(() => {
        if (!userWatch || userWatch == -1) return
        // if (!userWatch || userWatch != -1) {
        //     resetField('phoneNumber')
        //     resetField('name')
        //     resetField('email')
        //     return
        // }
        baseApi.get(`/admin/users/${userWatch}`).then(response => {
            setValue('name', response.data.name)
            setValue('phoneNumber', response.data.phoneNumber.replace('+', ''))
            setValue('email', response.data.email)
          
        })
    }, [userWatch])

    const onFinishHandler: any = handleSubmit(async (data: any) => {
        try {
            const { quotationDate, validUntil } = data
            if (isAfter(quotationDate, validUntil)) {
                setError('quotationDate', { message: 'Quotation date must be before Valid Until date' })
                setError('validUntil', { message: 'Valid Until date must be after Quotation date' })
                return
            }
            if (isBefore(validUntil, new Date())) {
                setError('validUntil', { message: 'Valid Until date must be after today' })
                return
            }
            if (!data.quotationItemCarts || !isArray(data.quotationItemCarts)) return

            const discountObj = data.discounts[0]
            const discount = (discountObj && discountObj.total) ? +discountObj.total : 0
            const discountDescription = (discountObj && discountObj.description) ? discountObj.description : ''

            if (data.userId == -1) {
                let user = await postCreateUser({ ...data, phoneNumber: `+${data.phoneNumber}` })
                user = await patchEditUser(user.id, {
                    ...user,
                    addresses: [{
                        ...data,
                        isDefault: true
                    }]
                })
                const formattedItems = await formatQuotationItems(data.quotationItemCarts)
                onFinish({
                    ...data,
                    discount,
                    discountDescription,
                    userId: parseInt(user.id),
                    addressId: parseInt(user.addresses[0].id),
                    quotationItemCarts: formattedItems
                })
            }
            else {
                const formattedItems = await formatQuotationItems(data.quotationItemCarts)
                onFinish({
                    ...data,
                    discount,
                    discountDescription,
                    quotationItemCarts: formattedItems
                })
            }
        } catch (err: any) {
            if (err.message && err.message[0]) {
                toast.error(err.message)
                return
            }
            toast.error('Error occured. Please contact support.')
        }

    })

    useEffect(() => {
        handleMutation(mutationResult, setError, clearErrors, disableBtn)
    }, [mutationResult, setError, clearErrors, disableBtn])

    const formatQuotationItems = async (quotationItemCarts: MiscObj) => {
        const formattedItems: any[] = []
        await Promise.all(quotationItemCarts.map(async (item: any) => {
            if (item.quotationItemId == -1) {
                const createdItemRes = await baseApi.post(`/admin/quotations/items`, {
                    ...item, pricePerUnit: +item.pricePerUnit, quantity: +item.quantity
                })
                const createdItem = createdItemRes.data
                formattedItems.push({
                    quotationItemId: +createdItem.id,
                    description: createdItem.description,
                    pricePerUnit: +createdItem.pricePerUnit,
                    quantity: +item.quantity
                })
            } else {
                formattedItems.push({
                    quotationItemId: +item.quotationItemId,
                    description: item.description,
                    pricePerUnit: +item.pricePerUnit,
                    quantity: +item.quantity
                })
            }
        }))
        return formattedItems
    }

    return (<>
        <PageTitle title="Create Quotation" />
        <FormFieldQuotation
            control={control}
            watch={watch}
            resetField={resetField}
            setValue={setValue}
            formId="create-quotation"
            onFinishHandler={onFinishHandler}
            isFetching={btnDisabled}
            cardHeading="Create Quotation"
            discountFieldArr={discountFieldArr}
            itemFieldArr={itemFieldArr}
        />
    </>)
}

