import { useModal, useTable } from "@refinedev/core"
import Badge from "components/Badge"
import { Button } from "components/buttons"
import { FilterModal } from "components/custom-modals"
import EmptySection from "components/EmptySection"
import { Checkbox, SelectField, TextField } from "components/forms"
import Pagination from "components/Pagination"
import { FilterIcon, SearchIcon } from "components/Svg"
import { Table, THead, Th, TBody, Td, TAction, TActionItem } from "components/Table"
import TabletWidgets, { TabletWidgetLeft, TabletWidgetRight } from "components/TableWidgets"
import { useCallback } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { NavLink } from "react-router-dom"
import { SERVICE_ORDER_CONFIG } from "resources-config/service-order/service-order-config"
import { getOrderStatus } from "utils/data-formatter"
import { formatFullDate } from "utils/date"
import useTableQuery from "utils/useTableQuery"


const ShowUserOrderList = ({ userId }: { userId?: any }) => {
    const filterModal = useModal()
    const { tableQueryResult, pageSize, current, setCurrent, sorters, setSorters, filters, setFilters } = useTable({
        queryOptions: { retry: false, },
        pagination: { current: 1, pageSize: 10 },
        resource: SERVICE_ORDER_CONFIG.name,
        filters: {
            initial: [{
                field: 'userId',
                operator: 'eq',
                value: userId,
            }]
        },
    })

    const changePage = useCallback((page: number | string) => {
        setCurrent(+page)
    }, [setCurrent])

    const { control: controlSearch, doFilter, setValue } = useTableQuery(filters, setFilters)
    const fieldArr = useFieldArray({ control: controlSearch, name: 'status' })
    
    const paymentFieldArr = useFieldArray({
        control: controlSearch,
        name: 'payment', 
    });
    const handleClearStatus = () => {
        setValue('status', []);
        doFilter(); 
    };
    const handleClearPayment = () => {
        setValue('payment', []);
        doFilter(); 
    };
    
    

    return <>
        <TabletWidgets>
            <TabletWidgetLeft>
                <TextField
                  Icon={<SearchIcon />}
                    control={controlSearch} fieldName='search' placeholder="Search by Order ID"
                    className='w-full' handleEnter={doFilter}
                />
            </TabletWidgetLeft>
            <TabletWidgetRight>
                <Button shape='outline' onClick={filterModal.show}>
                    <FilterIcon />Filter
                </Button>
                <Button shape="outline" href={`/quotations/create?user=${userId}`}> Create Quotation</Button>
                <Button href={`/service-orders/create?user=${userId}`}>+ New order</Button>
            </TabletWidgetRight>
        </TabletWidgets>
        {tableQueryResult.data && tableQueryResult.data.data.length > 0 ? (
    <Table>
    <THead>
        <Th sorting={{ column: 'id', sorters: sorters, setSorters: setSorters }}>Order ID</Th>
        <Th sorting={{ column: 'createdAt', sorters: sorters, setSorters: setSorters }}>Purchase Date</Th>
        <Th>Job Type</Th>
        <Th>Promo Code</Th>  
        <Th>Status</Th>
        <Th>Amount Due</Th>
        <Th>Grand Total</Th>
        <Th>Action</Th>
    </THead>
    {!tableQueryResult.data ? [[]] : tableQueryResult.data.data.map((order: any) => {
        const firstInvoice = order.invoices.sort((a: any, b: any) => parseInt(a) - parseInt(b))[0]
        const status = getOrderStatus(order, firstInvoice)
        return <TBody>
            <Td>{order.id}</Td>
            <Td>{formatFullDate(order.createdAt, { showTime: false })}</Td>
            <Td>
            {order.items[0]?.service?.jobType?.name ?? '-'}
                {/* {order.items[0] && order.items[0].service.jobType &&
                    <Badge color={order.items[0].service.jobType.colorHex}>{order.items[0].service.jobType.name}</Badge>
                } */}
            </Td>
            <Td>{order.promoCodeUsage != null && order.promoCodeUsage?.promoCode?.code ? order.promoCodeUsage?.promoCode?.code : "-"}</Td>
     
            <Td>
                {status &&
                    <Badge fullOpacityBackground textColor={status.color} color={status.backgroundColor} className='inline-block mr-4 text-th-xs'>
                        {status.text}
                    </Badge>
                }
            </Td>
            <Td>{order.invoices && order.invoices[0].amountDue ? ` $ ${order.invoices[0].amountDue}` : "-"}</Td>
            <Td>$ {order.grandTotal}</Td>      
         <Td className="flex items-center">
         <NavLink className={'text-sm font-medium text-primary'}  to={`/service-orders/${order.id}`}>View</NavLink>
         </Td>
            {/* <TAction id={`service-order-${order.id}`}>
                <TActionItem access={{ resource: SERVICE_ORDER_CONFIG.name, action: 'show' }}>
                    <NavLink className={'text-sm'} to={`/service-orders/${order.id}`}>View</NavLink>
                </TActionItem>
            </TAction> */}
        </TBody>
    })}
</Table>
        ) : <EmptySection/>
        
    }
    
        <div className='justify-end mt-4 flex-ct-y'>
            <Pagination
                pageSize={pageSize} currentPage={current} totalItems={tableQueryResult.data?.total || 0}
                clickHandler={changePage} range={5}
            />
        </div>
        <FilterModal visible={filterModal.visible} close={filterModal.close} resourceName="Order" subheading="Only shows data with this criteria" doFilter={doFilter}>
            <div className='mb-4 font-medium flex justify-between'>
                <h5 className="text-[#303030]">Order Status</h5>
                <button type="button" onClick={handleClearStatus} className="text-primary">Clear</button>
            </div>
            <div className='flex flex-col gap-3 mb-6 '>
                <Checkbox fieldArr={fieldArr}
                    cbVal={'ongoing'} fieldName='status' control={controlSearch}
                    label={'Ongoing'}
                />
                <Checkbox fieldArr={fieldArr}
                    cbVal={'cancelled'} fieldName='status' control={controlSearch}
                    label={'Cancelled'}
                />
                <Checkbox fieldArr={fieldArr}
                    cbVal={'completed'} fieldName='status' control={controlSearch}
                    label={'Completed'}
                />
                <Checkbox fieldArr={fieldArr}
                    cbVal={'expired'} fieldName='status' control={controlSearch}
                    label={'Expired'}
                />
                <Checkbox fieldArr={fieldArr}
                    cbVal={'refunded'} fieldName='status' control={controlSearch}
                    label={'Refunded'}
                />
            </div>
           
            <div className='mb-4 font-medium flex justify-between'>
                <h5 className="text-[#303030]">Payment Status</h5>
                <button type="button" onClick={handleClearPayment} className="text-primary">Clear</button>
            </div>
            <div className="flex gap-3 flex-col">
            <Checkbox fieldArr={paymentFieldArr}
                    cbVal={'paid'} fieldName='payment' control={controlSearch}
                    label={'Paid'}
                />
            <Checkbox fieldArr={paymentFieldArr}
                    cbVal={'unpaid'} fieldName='payment' control={controlSearch}
                    label={'Unpaid'}
                />
            </div>
        </FilterModal>
    </>
}

export default ShowUserOrderList