import { BaseKey, useOne } from "@refinedev/core"
import { useParams } from "react-router-dom"
import { PROMO_CONFIG } from "resources-config/promo-config"
import ShowNormalPromo from "./promo-types/ShowNormalPromo"
import ShowGeneratorPromo from "./promo-types/ShowGeneratorPromo"
import { BaseCard, PromoCard } from "components/cards"
import useSWR from "swr"
import { baseApi } from "providers/customDataProvider"
import { useMemo, useState } from "react"
import { CopyIcon } from "components/Svg"
import { toast } from "sonner"
import { Button } from "components/buttons"
import { postToggleActivatePromo, postToggleVisibilityPromo } from "api/promo"
import ShowBirthdayPromo from "./promo-types/ShowBirthdayPromo"

const ShowServicePromo = () => {
    let PromoUI;
    const params = useParams()
    const [isFetching, setIsFetching] = useState(false)
    const { data, refetch: refetchPromo } = useOne({
        resource: PROMO_CONFIG.name,
        id: params.id
    })
    const { data: servicesList = [] } = useSWR('services-details', async () => {
        const res = await baseApi.get('/admin/service-promos/find-frequencies')
        return res.data
    })
    const promo: any = data?.data
    const { userCriteria = [] } = useMemo(() => {
        if (!promo) return {}
        const userCriteria = []

        if (promo.forHaventBookedUser) userCriteria.push('forHaventBookedUser')
        if (promo.forHaventBookedUserInMonths) userCriteria.push('forHaventBookedUserInMonths')

        return { userCriteria }
    }, [promo])

    switch (promo?.promoType) {
        case 1:
            PromoUI = ShowNormalPromo
            break
        case 2:
            PromoUI = ShowGeneratorPromo
            break
        case 3:
            PromoUI = ShowBirthdayPromo
            break
        default:
            PromoUI = null
            break
    }

    const handleCopyPromoCode = () => {
        const code = promo?.codes[0]?.code
        if (!code) return;

        navigator.clipboard.writeText(code.toString());
        toast.success("Promo code successfully copied");
    }

    const handleDeactivate = async (id: BaseKey | undefined) => {
        if (!id) return
        try {
            setIsFetching(true)
            if (await postToggleActivatePromo(id.toString())) {
                toast.success('Success updating promo.')
                refetchPromo()
            }
        } catch (err: any) {
            toast.error('Failed updating promo. ' + err.message)
            console.log(err)
        } finally {
            setIsFetching(false)
        }
    }

    const handleToggleVisibility = async (id: BaseKey | undefined) => {
        if (!id) return
        try {
            setIsFetching(true)
            if (await postToggleVisibilityPromo(id.toString())) {
                toast.success('Success updating promo.')
                refetchPromo()
            }
        } catch (err: any) {
            toast.error('Failed updating promo. ' + err.message)
            console.log(err)
        } finally {
            setIsFetching(false)
        }
    }

    return <>
        {PromoUI && promo &&
            <BaseCard className="">
                <div className="flex items-center justify-between pb-6 mb-4 border-b">
                    <div className="flex items-center gap-3 text-th-lg">
                        <h1 className="font-bold">Promo Code</h1>
                        <span>{promo.codes[0].code}</span>
                        <button onClick={handleCopyPromoCode}>
                            <CopyIcon width={24} height={24} />
                        </button>
                    </div>
                    <div className="flex items-center gap-8">
                        {promo.promoType != 2 &&
                            <label className="inline-flex items-center cursor-pointer gap-2">
                                <input disabled={isFetching} type="checkbox" className="sr-only peer" checked={promo.isVisible} onChange={(e) => { e.preventDefault(); handleToggleVisibility(promo.id); }} />
                                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full dark:bg-gray-300 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></div>
                                <span className="text-light">{'Show Promo Code'}</span>
                            </label>
                        }
                        <Button confirm disabled={isFetching} shape={"outline"} onClick={() => handleDeactivate(promo.id)}>Deactivate{promo.isActive ? '' : 'd'}</Button>
                    </div>
                </div>
                <div className="relative grid grid-cols-6 items-start gap-6">
                    <PromoUI className="grow gap-y-6 col-span-4" promo={promo} />
                    <div className="grow sticky col-span-2 top-44">
                        <PromoCard
                            {...promo}
                            mode={'view'}
                            className="bg-white translate-y-[55%] -mt-[9%]"
                            servicesList={servicesList}
                            selectedUsers={[]}
                            code={promo.codes[0].code}
                            quota={promo.codes[0].quota}
                            allowPriorityBooking={promo.allowPriorityBooking === undefined ? 0 : promo.allowPriorityBooking}
                            includeOrExcludeUsers={promo.includedUsers.length > 0 ? 'include' : promo.excludedUsers.length > 0 ? 'exclude' : 0}
                            includeOrExcludeServices={promo.includedServices.length > 0 ? 'include' : promo.excludedServices.length > 0 ? 'exclude' : 0}
                            includeOrExcludePropertyTypes={'include'}
                            userCriteria={userCriteria}
                            usageLimit={promo.codes[0].usageLimit}
                        />
                    </div>
                </div>

            </BaseCard>
        }
    </>
}

export default ShowServicePromo