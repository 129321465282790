import { useCallback } from 'react'
import { useTable, CanAccess, BaseKey } from "@refinedev/core"
import { BaseCard } from "components/cards"
import PageTitle from "components/PageTitle"
import Pagination from "components/Pagination"
import { TAction, TActionItem, TBody, THead, Table, Td, Th } from "components/Table"
import { TECHNICIANS_CONFIG } from "resources-config/technicians-config"
import { Button } from 'components/buttons'
import Badge from 'components/Badge'
import { baseApi } from 'providers/customDataProvider'
import TabletWidgets, { TabletWidgetLeft, TabletWidgetRight } from 'components/TableWidgets'
import { NavLink } from 'react-router-dom'
import { TextField } from 'components/forms'
import useTableQuery from 'utils/useTableQuery'
import { padStart } from 'lodash'


export default function ListTechnician() {
    const {
        tableQueryResult, pageSize, current, setCurrent, sorters, setSorters,
        filters, setFilters
    } = useTable({
        queryOptions: { retry: false, },
        // pagination: { current: 1, pageSize: 20 },
        resource: TECHNICIANS_CONFIG.name,
    })
    const changePage = useCallback((page: number | string) => {
        setCurrent(+page)
    }, [setCurrent])

    const toggleActiveTechnician = useCallback(async (id: BaseKey, isActive: boolean) => {
        try {
            await baseApi.post('admin/technicians/toggle-active/' + id, { isActive: isActive })
            tableQueryResult.refetch()
        } catch (error) {
            console.log(error)
        }
    }, [tableQueryResult.refetch])

    const { control, doFilter } = useTableQuery(filters, setFilters)

    return (<>
        <PageTitle />
        <BaseCard>
            <TabletWidgets>
                <TabletWidgetLeft>
                    <TextField
                        control={control} fieldName='search' placeholder="Search by ID, name, email, or phone number"
                        className='w-full' handleEnter={doFilter}
                    />
                </TabletWidgetLeft>
                <TabletWidgetRight>
                    <CanAccess resource={TECHNICIANS_CONFIG.name} action='create'>
                        <Button href='/technicians/create'>
                            Add Technician
                        </Button>
                    </CanAccess>
                </TabletWidgetRight>
            </TabletWidgets>
            <Table>
                <THead>
                    <Th sorting={{ column: 'id', setSorters: setSorters, sorters: sorters }}>ID</Th>
                    <Th sorting={{ column: 'name', setSorters: setSorters, sorters: sorters }}>Name</Th>
                    <Th sorting={{ column: 'email', setSorters: setSorters, sorters: sorters }}>Email</Th>
                    <Th sorting={{ column: 'phone', setSorters: setSorters, sorters: sorters }}>Phone Number</Th>
                    <Th sorting={{ column: 'vrn', setSorters: setSorters, sorters: sorters }}>VRN</Th>
                    {/* <Th sorting={{ column: 'team', setSorters: setSorters, sorters: sorters }}>Team</Th> */}
                    <Th>Status</Th><Th>Actions</Th>
                </THead>
                {!tableQueryResult.data ? [[]] : tableQueryResult.data.data.map((technician, index) => (
                    <TBody key={index}>
                        <Td>{padStart(technician.id?.toString(), 4, '0')}</Td>
                        <Td>{technician.name}</Td>
                        <Td>{technician.email}</Td>
                        <Td>{technician.phoneNumber}</Td>
                        <Td>{technician.vrn ?? '--'}</Td>
                        {/* <Td>{technician.technicianTeam ? technician.technicianTeam.name : '--'}</Td> */}
                        <Td>
                            <Badge color={(technician.isActive ? 'success' : 'danger')} className='text-th-xs'>
                                {(technician.isActive ? 'Active' : 'Inactive')}
                            </Badge>
                        </Td>
                        <TAction id={`edit-technician-${technician.id}`}>
                            <TActionItem access={{ resource: TECHNICIANS_CONFIG.name, action: 'edit' }}>
                                <button type='button' className='text-sm' onClick={() => { toggleActiveTechnician(technician.id || 0, !technician.isActive) }}>
                                    {technician.isActive ? 'Deactivate' : 'Activate'}
                                </button>
                            </TActionItem>
                            <TActionItem>
                                <NavLink type='button' className='text-sm' to={`/technicians/${technician.id}`}>
                                    Edit
                                </NavLink>
                            </TActionItem>
                        </TAction>
                    </TBody>
                ))}
            </Table>
            {/* <div className='justify-end mt-4 flex-ct-y'>
                <Pagination
                    pageSize={pageSize} currentPage={current} totalItems={tableQueryResult.data?.total || 0}
                    clickHandler={changePage} range={5}
                />
            </div> */}

        </BaseCard>
    </>)
}

