import { baseApi } from "providers/customDataProvider";

export const postCreateAddress = async (userId: string, body: MiscObj) => {
  try {
    const res = await baseApi.post(
      `/admin/user/${userId}/user-addresses`,
      body
    );
    const address = res.data;
    return address;
  } catch (error) {
    console.error("Error creating address:", error);
    throw error;
  }
};

export const removeAddress = async (userId: string, addressId: string | null) => {
  try {
    const res = await baseApi.delete(
      `/admin/user/${userId}/user-addresses/${addressId}`
    );
    const address = res.data;
    return address;
  } catch (error) {
    console.error("Error remove address:", error);
    throw error;
  }
};

export const patchAddress = async (userId: string, body: MiscObj, addressId: string ) => {
  const res = await baseApi.patch(`/admin/user/${userId}/user-addresses/${addressId}`, body)
  const user = res.data
  return user
}