import { useController } from "react-hook-form"

type Props = {
    control: FormHookType['control']
    label: string
    name: string
    disabled?: boolean
}

const Switch = (props: Props) => {
    const { control, label, name, disabled = false } = props
    const { field: { onChange, value } } = useController({
        control: control,
        name: name
    })
    return <div>
        <label className="inline-flex items-center cursor-pointer gap-2">
            <input disabled={disabled} type="checkbox" className="sr-only peer" checked={value} onChange={onChange} />
            <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full dark:bg-gray-300 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></div>
            <span className="text-light">{label}</span>
        </label>
    </div>
}

export default Switch