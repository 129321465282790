import ListAdminFollowUp from "components/pages/admin-follow-up/ListAdminFollowUp"
import { AdminFollowUpIcon } from "components/Svg"
import { ResourceType } from "resources-config"

export const ADMIN_FOLLOW_UP_CONFIG: ResourceType = {
    name: 'admin-follow-up',
    meta: {
        icon: <AdminFollowUpIcon />,
        label: 'Admin Follow Up',
        apiEndpoints: {
            getList: '/service-reports/needs-attention'
        },
        showBottomDivider: true
    },
    actions: {
        list: {
            path: 'admin-follow-up', page: <ListAdminFollowUp />,
            allowedRoles: ['super admin']
        },
    }
}

