import { useTable } from "@refinedev/core"
import { TextField } from "components/forms"
import Pagination from "components/Pagination"
import { Table, THead, Th, TBody, Td } from "components/Table"
import TabletWidgets, { TabletWidgetLeft } from "components/TableWidgets"
import { capitalize } from "lodash"
import { ComponentProps, useCallback } from "react"
import { formatFullDate } from "utils/date"
import useTableQuery from "utils/useTableQuery"
type Props = ComponentProps<'section'> & {
    promo: MiscObj
}

const ShowGeneratorPromo = (props: Props) => {
    const { promo, className, ...rest } = props
    const code = promo.codes[0]

    const { tableQueryResult, setFilters, pageSize, current, setCurrent, setPageSize, filters, setSorters, sorters } = useTable({
        queryOptions: { retry: false, },
        pagination: { current: 1, pageSize: 20 },
        resource: `service-promos/${promo.id}/promo-codes`
    })

    const changePage = useCallback((page: number | string) => {
        setCurrent(+page)
    }, [setCurrent])

    const { control, doFilter } = useTableQuery(filters, setFilters)

    return <section className={`${className}`} {...rest}>
        <TabletWidgets>
            <TabletWidgetLeft>
                <TextField
                    control={control} fieldName='search' placeholder="Search by ID or user's name"
                    className='w-full' handleEnter={doFilter}
                />
            </TabletWidgetLeft>
        </TabletWidgets>
        {tableQueryResult.data &&
            <Table>
                <THead>
                    <Th>Promo Code</Th>
                    <Th>Order ID</Th>
                    <Th>Order Amount</Th>
                    <Th>Discount Given</Th>
                    <Th>Platform</Th>
                    <Th sorting={{ column: 'createdAt', sorters: sorters, setSorters: setSorters }}>Redeem At</Th>
                </THead>
                {tableQueryResult.data.data.map((promoCode: any, index: number) => {
                    const codeUsage = promoCode.codeUsages.find((usage: any) => usage.isActive)
                    return (
                        <TBody key={index}>
                            <Td>{promoCode.code}</Td>
                            <Td>OID {codeUsage?.order.id}</Td>
                            <Td>$ {codeUsage?.order.grandTotal?.toFixed(2)}</Td>
                            <Td>$ {codeUsage?.order.discountAmount?.toFixed(2)}</Td>
                            <Td>{capitalize(codeUsage?.order.platform)}</Td>
                            <Td>{formatFullDate(codeUsage?.createdAt, { showDay: false })}</Td>
                        </TBody>
                    )
                })}
            </Table>
        }

        <div className='justify-end mt-4 flex-ct-y'>
            <Pagination
                pageSize={pageSize} currentPage={current} totalItems={tableQueryResult.data?.total || 0}
                clickHandler={changePage} range={5}
            />
        </div>
    </section>
}

export default ShowGeneratorPromo