import { ComponentProps } from "react"

type Props = ComponentProps<'div'> & {
    horizontal?: boolean
    labelWeight?: 'normal' | 'medium'
    label?: string
}
const LabeledInfo = (props: Props) => {
    const { horizontal = false, labelWeight = 'medium', label, children, className, ...rest } = props
    return <div className={`flex ${horizontal ? 'flex-row' : 'flex-col'} text-[#404040] gap-1.5 ${rest.onClick ? 'cursor-pointer' : ''}`} {...rest}>
        <span className={`${labelWeight == 'medium' ? 'font-medium' : ''}`}>{label}</span>
        <div className={className}>
            {children}
        </div>
    </div>
}

export default LabeledInfo