import { useLogout, useModal } from "@refinedev/core"
import { BaseCard, KanbanCard } from "components/cards"
import { DashboardHeader } from "components/headers"
import PageTitle from "components/PageTitle"
import RequestModal, { RequestObject } from "components/custom-modals/RequestModal"
import { NoticeIcon } from "components/Svg"
import { useEffect, useState } from "react"
import { getLocalStorage } from "utils/storageHelper"
import { useTaskBoard } from "context/TaskBoardContext"


export default function Dashboard() {
    const { mutate: logout } = useLogout()
    const requestModal = useModal()
    const [viewedObject, setViewedObject] = useState<RequestObject>()
    const { refreshAll, lowPriorityRequests, mediumPriorityRequests, highPriorityRequests } = useTaskBoard()

    const handleOpenRequest = (reqObject: RequestObject) => {
        setViewedObject(reqObject)
        requestModal.show()
    }

    useEffect(() => {
        const user = getLocalStorage('user')
        if (!user) logout()
        else refreshAll()
    }, [])

    return <>
        <PageTitle title='Dashboard' />
        <DashboardHeader />
        <BaseCard className="mt-4">
            <div className="mt-4 grid grid-cols-3 gap-4 bg-transparent">
                <section className="p-2 bg-[#FFF4F2] rounded-lg">
                    <div className="flex-ct-y gap-2 font-medium">
                        <div className="flex-ct-y gap-1.5 rounded-full py-1.5 px-4 border bg-[#FF6666] text-white">
                            <NoticeIcon className="fill-white" />
                            High Priority
                        </div>
                        <span className="text-danger">{highPriorityRequests.length}</span>
                    </div>
                    <div className='space-y-2 mt-2'>
                        {highPriorityRequests.map((request: any) =>
                            <KanbanCard
                                {...request}
                                key={request.id}
                                accentColor={"#BB032A"}
                                className="hover:border-[#BB032A] hover:bg-danger-light/30"
                                onClick={() => handleOpenRequest({
                                    scheduleId: request.scheduleId,
                                    submitId: request.submitId,
                                    orderId: request.orderId,
                                    topic: request.topic,
                                    displayDate: request.date,
                                    showAction: request.showAction,
                                    reason: request.reason,
                                    requestBy: request.requestBy
                                })}
                            />
                        )}
                    </div>
                </section>
                <section className="p-2 bg-[#FC880033] rounded-lg">
                    <div className="flex-ct-y gap-2 font-medium">
                        <div className="flex-ct-y gap-1.5 rounded-full py-1.5 px-4 bg-[#FC8800] text-white">
                            <NoticeIcon className="fill-white" />
                            Medium Priority
                        </div>
                        <span className="text-[#FC8800]">{mediumPriorityRequests.length}</span>
                    </div>
                    <div className='space-y-2 mt-2'>
                        {mediumPriorityRequests.map((request: any) =>
                            <KanbanCard
                                {...request}
                            />
                        )}
                    </div>
                </section>
                <section className="p-2 bg-warning-light/30 rounded-lg">
                    <div className="flex-ct-y gap-2 font-medium">
                        <div className="flex-ct-y gap-1.5 bg-[#EECEB0] rounded-full py-1.5 px-4 text-black">
                            <NoticeIcon className="fill-white" />
                            Low Priority
                        </div>
                        <span className="text-[#BF6919]">{lowPriorityRequests.length}</span>
                    </div>
                    <div className='space-y-2 mt-2'>
                        {lowPriorityRequests.map(request =>
                            <KanbanCard
                                accentColor={"#247881"}
                                className="hover:border-[#247881] hover:bg-[#247881]/10"
                                {...request}
                            />
                        )}
                    </div>
                </section>
            </div>
            <RequestModal visible={requestModal.visible} close={requestModal.close} viewedObject={viewedObject} />
        </BaseCard>
    </>
}