import { ComponentProps } from "react"
import Pill from "./Pill"
import { FlagIcon, TimeRunIcon, PinCheckIcon, TruckIcon, ExclamationIcon } from "./Svg"



type Props = ComponentProps<'div'> & {
    schedule: MiscObj
}

const ScheduleStatusPill = (props: Props) => {
    const { schedule } = props

    if (!schedule) {
        return <></>
    }
    if (schedule.completionDate) {
        return (
            <Pill className="justify-center" backgroundColor="#00A19A" textColor="#FFFFFF">
                <div className="flex items-center gap-2 whitespace-nowrap">
                    <FlagIcon color="white" width={16} height={16} />
                    Job Completed
                </div>
            </Pill>
        )
    }
    else if (schedule.teamStartDate) {
        return (
            <Pill className="justify-center" backgroundColor="#36A9E1" textColor="#FFFFFF">
                <div className="flex items-center gap-2 whitespace-nowrap">
                    <TimeRunIcon color="white" width={16} height={16} />
                    Job Started
                </div>
            </Pill>
        )
    }
    else if (schedule.teamArrivalDate) {
        return (
            <Pill className="justify-center" backgroundColor="#3267E3" textColor="#FFFFFF">
                <div className="flex items-center gap-2 whitespace-nowrap">
                    <PinCheckIcon color="white" width={16} height={16} />
                    Tech Arrived
                </div>
            </Pill>
        )
    }

    else if (schedule.teamOnTheWayDate) {
        return (
            <Pill className="justify-center" backgroundColor="#CD7B2E" textColor="#FFFFFF">
                <div className="flex items-center gap-2 whitespace-nowrap">
                    <TruckIcon color="white" width={16} height={16} />
                    On The Way
                </div>
            </Pill>
        )
    }
    else {
        return <></>
    }
}

export default ScheduleStatusPill