import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTable, CanAccess, BaseKey, useModal, useModalReturnType } from "@refinedev/core"
import { USERS_CONFIG } from 'resources-config/users-config'
import { TextField } from 'components/forms'
import { TAction, TActionItem, TBody, THead, Table, Td, Th } from "components/Table"
import Pagination from 'components/Pagination'
import PageTitle from "components/PageTitle"
import { baseApi } from 'providers/customDataProvider'
import { BaseCard } from 'components/cards'
import { Button } from 'components/buttons'
import { NavLink } from 'react-router-dom'
import Badge from 'components/Badge'
import TabletWidgets, { TabletWidgetLeft, TabletWidgetRight } from 'components/TableWidgets'
import useTableQuery from 'utils/useTableQuery'
import Pill from 'components/Pill'
import { formatFullDate } from 'utils/date'
import { toast } from 'sonner'
import { formatAddressAny } from 'utils/address'
import { MenuDotsIcon, SearchIcon } from 'components/Svg'
import { Modal } from 'components/popups'
import DangerModal from 'components/custom-modals/DangerModal'

export default function ListUser() {
    const filterModal = useModal()
    const  isActiveModal = useModal()
    const { tableQueryResult, setFilters, pageSize, current, setCurrent, setPageSize, filters, setSorters, sorters } = useTable({
        queryOptions: { retry: false, },
        pagination: { current: 1, pageSize: 10 },
        resource: USERS_CONFIG.name,
    })
    const totalItems =  tableQueryResult.data?.total || 0
       const totalPages = useMemo(() => (
            !totalItems || !pageSize ? 0 : Math.ceil(totalItems/pageSize)
        ), [totalItems, pageSize])
        
    const changePage = useCallback((page: number | string) => {
        setCurrent(+page)
    }, [setCurrent])

    const [userIdToConfirm, setUserIdToConfirm] = useState<BaseKey | null>(null);
    const [isActiveToConfirm, setIsActiveToConfirm] = useState<boolean>(false);
    
    const toggleActiveAPI = async (id: BaseKey, isActive: boolean) => {
        try {
          await baseApi.post(`admin/users/toggle-active/${id}`, { isActive });
          tableQueryResult.refetch();
        } catch (error: any) {
          toast.error(error.message);
          console.error(error);
        }
      };

      const toggleActiveUser = useCallback(
        (id: BaseKey, isActive: boolean) => {
          if (!isActive) {
            setUserIdToConfirm(id);
            setIsActiveToConfirm(isActive);
            isActiveModal.show();
            return;
          }
          toggleActiveAPI(id, isActive);
        },
        [isActiveModal, tableQueryResult]
      );

      const handleConfirm = () => {
        if (userIdToConfirm !== null) {
          toggleActiveAPI(userIdToConfirm, isActiveToConfirm);
          isActiveModal.close();
          setUserIdToConfirm(null);
          setIsActiveToConfirm(false);
        }
      };
                  
      
    const { control, doFilter } = useTableQuery(filters, setFilters)

    return (<>
        <PageTitle title='Customers' />
        <BaseCard>
            <TabletWidgets>
                <TabletWidgetLeft>
                </TabletWidgetLeft>
                <TabletWidgetRight>
                    {/* <Button shape='outline' onClick={filterModal.show}>
                        <FilterIcon/>Filter
                    </Button> */}
                </TabletWidgetRight>
            </TabletWidgets>
            <TabletWidgets>
                <TabletWidgetLeft>
                    <TextField
                    Icon={<SearchIcon />}
                        control={control} fieldName='search' placeholder="Search by ID, name, email, or phone"
                        className='w-full' handleEnter={doFilter}
                    />
                </TabletWidgetLeft>
                <TabletWidgetRight>
        <div className="flex items-center gap-4">
            <Pill textColor="#0B5CAD" backgroundColor="#CBE2F9">
                {tableQueryResult.data ? tableQueryResult.data.total : 0}
            </Pill>
            <span>customers found</span>
            <CanAccess resource={USERS_CONFIG.name} action="create">
                <Button href="/users/create">Add New Customer</Button>
            </CanAccess>
        </div>
    </TabletWidgetRight>
            </TabletWidgets>
            <Table>
                <THead>
                    <Th sorting={{ column: 'id', sorters: sorters, setSorters: setSorters }}>Customer ID</Th>
                    <Th sorting={{ column: 'name', sorters: sorters, setSorters: setSorters }}>Name</Th>
                    <Th>Address</Th>
                    <Th>Phone Number</Th>                   
                    <Th>Created At</Th>
                    <Th>Credits</Th>
                    <Th>Total Revenue</Th>
                    <Th>Status</Th>
                    <Th className='=00 !text-center'>Action</Th>
                </THead>
                {!tableQueryResult.data ? <td>s</td> : tableQueryResult.data.data.map((user, index) => (
                    <TBody key={index}>
                        <Td>{user.id}</Td>
                        <Td>{user.name}</Td>                      
                        <Td className="max-w-[14.375rem] overflow-hidden whitespace-nowrap text-ellipsis">
                        {user.defaultAddress ? formatAddressAny(user.defaultAddress) : '--'}
                        </Td>

                        <Td>{user.phoneNumber}</Td>
                        <Td>{formatFullDate(user.createdAt, { showDay: false, showTime: false })}</Td>
                        <Td>$ {user.balance.toFixed(2)}</Td>
                        <Td>${user.revenue.toFixed(2)}</Td>
                        <Td>
                            <Badge color={(user.isActive ? '#24663B' : '#757575')} className='text-th-xs'>
                                {(user.isActive ? 'Active' : 'Deactivated')}
                            </Badge>
                        </Td>                       
                        <Td>
                        <div className="flex gap-4 items-center">

                  <TAction className='border-none' toggleText={<MenuDotsIcon />} id={`edit-user-${user.id}`}>
                        
                            {/* <TActionItem access={{ resource: USERS_CONFIG.name, action: 'show' }}>
                                <NavLink type='button' className='text-sm' to={`/users/${user.id}`}>
                                    View
                                </NavLink>
                            </TActionItem>
                            <TActionItem access={{ resource: USERS_CONFIG.name, action: 'edit' }}>
                                <NavLink type='button' className='text-sm' to={`/users/edit/${user.id}`}>
                                    Edit
                                </NavLink>
                            </TActionItem>*/}
                            <TActionItem access={{ resource: USERS_CONFIG.name, action: 'edit' }}>
                                <button type='button' className='text-sm' onClick={() => { toggleActiveUser(user.id || 0, !user.isActive) }}>
                                    {user.isActive ? 'Deactivate' : 'Activate'}
                                </button>
                            </TActionItem> 
                        </TAction>
                    
                        <NavLink type="button" className="text-sm font-medium text-primary" to={`/users/${user.id}`}>
                          View
                        </NavLink>
                        </div>
                        </Td>
                    </TBody>
                ))}
       
            </Table>
            <div className='justify-between mt-4 flex-ct-y'>
                <span className='text-[#344054]'>Page {current} of {totalPages}</span>
                <Pagination
                    pageSize={pageSize} currentPage={current} totalItems={totalItems}
                    clickHandler={changePage} range={5}
                />
            </div>
        </BaseCard>
       <DangerModal modal={isActiveModal} handleConfirm={handleConfirm} title={`Are you sure want to \n deactivate this customer profile?`}
       body={`Deactivated accounts will lose access \n to the customer app and web.`}
       />
        <FilterModal modal={filterModal} setPageSize={setPageSize} filters={filters} setFilters={setFilters} />
    </>)
}

const FilterModal = (
    { modal, setPageSize, setFilters, filters }: {
        modal: useModalReturnType, setPageSize: any, setFilters: any, filters: any
    }
) => {
    return (<></>
        // <Modal heading={'Filter'} close={modal.close} visible={modal.visible}>
        //     <SelectField label='Page Size' value={filters} onChange={(e: any) => {setPageSize(e.target.value)}} options={[
        //         {value: 20}, {value: 50}
        //     ]}/>  
        // </Modal>
    )
}

const formatDefaultAddress = (user: { [key: string]: any }) => {
    const defaultAddress = user.addresses.find((address: any) => (
        address.isDefault === true
    ))
    return (
        defaultAddress ? (
            defaultAddress.buildingNumber + ' ' + defaultAddress.street + ' ' + defaultAddress.postalCode
        ) : ''
    )
}
const getAuthMethods = (user: { [key: string]: any }) => {
    let authMethods: string[] = []
    if (user.googleAuth) { authMethods.push('Google') }
    if (user.appleAuth) { authMethods.push('Apple') }
    if (Boolean(user.phoneNumber)) { authMethods.push('Phone number') }
    if (Boolean(user.password)) { authMethods.push('Password') }
    return authMethods.join(', ')
}