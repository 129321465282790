import { BaseKey, useModal, useOne } from "@refinedev/core";
import { getServiceOrders } from "api/service-orders";
import { Button } from "components/buttons";
import { BaseCard } from "components/cards";
import { Tab, TabPanel } from "components/Tab";
import {
  Table,
  TAction,
  TActionItem,
  TBody,
  Td,
  Th,
  THead,
} from "components/Table";
import { DisplayDbLog, LabeledInfo } from "components/texts";
import { TextTooltip } from "components/tooltips";
import { baseApi } from "providers/customDataProvider";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { USERS_CONFIG } from "resources-config/users-config";
import { toast } from "sonner";
import { formatAddressAny } from "utils/address";
import { formatFullDate } from "utils/date";
import ShowUserOrderList from "./ShowUserOrderList";
import { FormsGrid, TextAreaField } from "components/forms";
import { useForm } from "react-hook-form";
import { getUserRatings, patchEditUser } from "api/users";
import ShowUserRatingList from "./ShowUserRatingList";
import {
  ArrowDownIcon,
  CustomerRatingIcon,
  EditNotesTwoIcon,
  MenuDotsIcon,
  MoneyCircleFillIcon,
  MoneyFillIcon,
  PenIcon,
} from "components/Svg";
import Badge from "components/Badge";
import EditProfileModal from "components/custom-modals/EditProfileModal";
import ShowUserAddressList from "./ShowUserAddressList";
import ShowUserCreditsList from "./ShowUserCreditsList";
import DangerModal from "components/custom-modals/DangerModal";
import { Tooltip } from "react-tooltip";
import WithDrawCreditsModal from "components/custom-modals/WithDrawCreditsModal";

const ShowUser = () => {
  const params = useParams();
  const { control, handleSubmit, setValue } = useForm();
  const { data, refetch: refetchUser } = useOne({
    resource: USERS_CONFIG.name,
    id: params.id,
  });
  const user = data && data.data;
  const [orders, setOrders] = useState<any[]>([]);
  const [rating, setRating] = useState<MiscObj>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [editNotes, setEditNotes] = useState<boolean>(false);
  const isActiveModal = useModal();
  const editProfileModal = useModal();
  const withdraw = useModal();
  // const toggleActiveUser = useCallback(async () => {
  //     if (!user) return
  //     try {
  //         await baseApi.post('admin/users/toggle-active/' + user.id, { isActive: !user.isActive })
  //         refetchUser()
  //         toast.success('Success')
  //     } catch (error) {
  //         console.log(error)
  //     }
  // }, [refetchUser, user])

  const [userIdToConfirm, setUserIdToConfirm] = useState<BaseKey | null>(null);
  const [isActiveToConfirm, setIsActiveToConfirm] = useState<boolean>(false);

  const toggleActiveAPI = async () => {
    if (!user) return;
    try {
      await baseApi.post("admin/users/toggle-active/" + user.id, {
        isActive: !user.isActive,
      });
      refetchUser();
      toast.success("Success");
    } catch (error: any) {
      toast.error(error.message);
      console.error(error);
    }
  };

  const toggleActiveUser = useCallback(() => {
    if (!user) return;
    if (user.isActive) {
      setUserIdToConfirm(user.id ? user.id : null);
      setIsActiveToConfirm(user.isActive);
      isActiveModal.show();
      return;
    }
    toggleActiveAPI();
  }, [isActiveModal]);

  const handleConfirm = () => {
    if (userIdToConfirm !== null) {
      toggleActiveAPI();
      isActiveModal.close();
      setUserIdToConfirm(null);
      setIsActiveToConfirm(false);
    }
  };

  const submitNotes = handleSubmit(async (data) => {
    if (!user || !user.id) return;
    if (data.internalNote === user.internalNote) {
      toast.info("No changes detected");
      setEditNotes(false);
      return;
    }
    setIsFetching(true);
    try {
      await patchEditUser(user.id.toString(), data);
      toast.success("Internal notes saved");
      setEditNotes(false);
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      setIsFetching(false);
      refetchUser();
    }
  });

  useEffect(() => {
    if (!user || !user.id) return;
    setValue("internalNote", user.internalNote);
    getServiceOrders("", 1, 100)
      .then((orders) => { })
      .catch((err) => console.log(err));
    getUserRatings(user.id.toString())
      .then((rating) => {
        setRating(rating);
      })
      .catch((err) => console.log(err));
  }, [user]);

  const handleSetDefault = async (userId: any, addressId: number) => {
    try {
      await baseApi.post(
        `/admin/users/${userId}/addresses/${addressId}/default`
      );
      toast.success("Default address successfully updated");
    } catch (err: any) {
      console.log(err);
      toast.error(err.message);
    } finally {
      refetchUser();
    }
  };

  return (
    <BaseCard>
      {user && (
        <>
          <header className=" border-b ">
            <div className="flex items-center justify-between">
              <div className="flex gap-6 items-center">
                <h1 className="text-th-2xl font-bold">
                  {user.name} - {user.id}
                </h1>
                <div>
                  <Badge
                    color={user.isActive ? "#24663B" : "#757575"}
                    className="text-th-xs "
                  >
                    {user.isActive ? "Active" : "Deactivated"}
                  </Badge>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <TAction
                  className="border-none"
                  toggleText={<MenuDotsIcon />}
                  id={`edit-user-${user.id}`}
                >
                  <TActionItem
                    access={{ resource: USERS_CONFIG.name, action: "edit" }}
                  >
                    <button
                      type="button"
                      className="text-sm"
                      onClick={() => {
                        toggleActiveUser();
                      }}
                    >
                      {user.isActive
                        ? "Deactivate this profile"
                        : "Activate this profile"}
                    </button>
                  </TActionItem>
                </TAction>
              </div>
            </div>
            <DangerModal
              modal={isActiveModal}
              handleConfirm={handleConfirm}
              title={`Are you sure want to \n deactivate this customer profile?`}
              body={`Deactivated accounts will lose access \n to the customer app and web.`}
            />
            <div className="flex justify-between py-6">
              <div className="flex gap-6">
                <div className="flex items-center gap-2 bg-[#F7CD431A] rounded-lg px-2">
                  <CustomerRatingIcon />
                  <span className="font-medium">
                    {rating && parseFloat(rating.avgRatings || 0).toFixed(2)}{" "}
                  </span>
                  <h3 className="text-th-base font-normal text-light">
                    Customer ratings
                  </h3>
                </div>
                <div className="flex items-center gap-2 bg-[#F7CD431A] rounded-lg px-2">
                  <MoneyFillIcon />
                  <span className="font-medium">{user.revenue.toFixed(2)}</span>
                  <h3 className="text-th-base font-normal text-light">
                    Revenue generated
                  </h3>
                </div>
              </div>
              <div className={`relative group ${user.balance === 0 ? "pointer-events-none" : ""}`}>
                <div className="flex items-center cursor-pointer gap-2 bg-[#00A19A1A] rounded-lg p-2 group-hover:shadow-lg">
                  <MoneyCircleFillIcon />
                  <span className="font-medium">
                    $ {user.balance.toFixed(2) || 0}
                  </span>
                  <h3 className="text-th-base font-normal text-light">
                    Credits
                  </h3>
                  <ArrowDownIcon />
                </div>
                <div className="absolute mt-4 w-56 right-0 left-auto bg-white rounded-lg hover:rounded-lg shadow-lg opacity-0 group-hover:opacity-100 group-hover:translate-y-0 transform -translate-y-2 transition-all duration-200 z-10">
                  <ul className="">
                    <li
                      className="text-body hover:bg-[#ECF2FA] hover:text-primary transition-colors w-full inline-block p-2 cursor-pointer"
                      onClick={() => withdraw.show()}
                    >
                      Withdraw the available credit
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </header>

          <section className=" relative mt-4 ">
            <div className="sticky top-20  grid grid-cols-2 gap-8 ">
              <div>
                <div className="flex justify-between">
                  <span className="font-medium text-[#404040]">
                    Customer Profile
                  </span>
                  <button
                    type="button"
                    className="flex gap-1 items-center"
                    onClick={() => editProfileModal.show()}
                  >
                    <span className="font-medium text-primary text-th-xs">
                      Edit Profile
                    </span>
                    <PenIcon />
                  </button>
                </div>
                <div className=" rounded-lg border mt-2 border-[#E0E0E0] p-6">
                  <FormsGrid col={2}>
                    <LabeledInfo label="Contact Number" className="text-light">
                      {user.phoneNumber}
                    </LabeledInfo>
                    <LabeledInfo label="Email" className="text-light">
                      {user.email}
                    </LabeledInfo>
                  </FormsGrid>
                  <FormsGrid col={1} className="my-6">
                    <LabeledInfo
                      label="Primary Address"
                      className="text-light "
                    >
                      {user.addresses.length > 0
                        ? formatAddressAny(
                          user.addresses.find(
                            (address: any) => address.isDefault
                          ) || user.addresses[0]
                        )
                        : "N/A"}
                    </LabeledInfo>
                  </FormsGrid>
                  <FormsGrid col={2}>
                    <LabeledInfo label="Gender" className="text-light">
                      {!user.gender ? "N/A" : user.gender}
                    </LabeledInfo>
                    <LabeledInfo label="Birthdate" className="text-light">
                      {formatFullDate(user.birthDate, {
                        showDay: false,
                        showTime: false,
                      })}
                    </LabeledInfo>
                    <LabeledInfo label="Join Date" className="text-light">
                      {formatFullDate(user.createdAt, {
                        showDay: false,
                        showTime: false,
                      })}
                    </LabeledInfo>
                    <LabeledInfo label="Last Login" className="text-light">
                      {formatFullDate(user.lastLoginAt)}
                    </LabeledInfo>
                  </FormsGrid>
                  <FormsGrid col={1} className="my-6">
                    <LabeledInfo
                      label="Created by"
                      className="text-light capitalize"
                    >
                      {user?.createDbLog ?
                        '-' : <>
                          {user?.createDbLog?.userType}{" "}
                          {user?.createDbLog?.changedBy?.name}
                        </>
                      }
                    </LabeledInfo>
                  </FormsGrid>
                  {/*                         
                        <DisplayDbLog dbLog={user.createDbLog} /> */}
                  {/* <DisplayDbLog dbLog={user.updateDbLog} /> */}
                  {user.deactivateReason && (
                    <LabeledInfo
                      label="Deactivate Reason"
                      className="text-light break-words"
                    >
                      {user.deactivateReason}
                    </LabeledInfo>
                  )}
                </div>
              </div>

              <form>
                <div className="mb-2 flex items-center justify-between w-full">
                  <span className="font-medium text-[#404040]">
                    Internal Notes
                  </span>
                  {editNotes ? (
                    <div className="flex justify-end  gap-4">
                      <span
                        className="font-medium text-danger text-th-xs cursor-pointer"
                        onClick={() => setEditNotes(false)}
                      >
                        Cancel
                      </span>
                      <button
                        onClick={submitNotes}
                        className="font-medium text-primary text-th-xs cursor-pointer "
                        type="button"
                      >
                        Save Edit
                      </button>
                    </div>
                  ) : (
                    <button
                      type="button"
                      onClick={() => setEditNotes(true)}
                      className="flex gap-1 items-center cursor-pointer"
                    >
                      <span className="font-medium text-primary text-th-xs ">
                        Edit Notes
                      </span>
                      <EditNotesTwoIcon />
                    </button>
                  )}
                </div>
                <TextAreaField
                  rows={16}
                  disabled={!editNotes}
                  control={control}
                  fieldName="internalNote"
                />
                <div className="text-end mt-2">
                  <span className="pt-1 text-th-xs text-end text-light">
                    Last Updated By:{" "}
                    {user.internalNoteLastEditedBy &&
                      user.internalNoteLastEditedBy.name}{" "}
                    ({formatFullDate(user.internalNoteLastEditedAt)})
                  </span>
                </div>
                {/* <Button type="submit" className="w-full mt-2.5" isLoading={isFetching}>Save Notes</Button> */}
              </form>
            </div>
          </section>
          <section className="mt-12">
            <Tab>
              <TabPanel id="orders" title="Order List">
                <ShowUserOrderList userId={user.id && user.id.toString()} />
              </TabPanel>
              <TabPanel id="address" title="Address">
                <ShowUserAddressList
                  address={user.addresses}
                  userId={user.id && user.id.toString()}
                />
              </TabPanel>
              <TabPanel id="credits" title="Credit History">
                <ShowUserCreditsList userId={user.id && user.id.toString()} />
              </TabPanel>
              <TabPanel id="rating" title="Customer Ratings">
                <ShowUserRatingList
                  rating={rating}
                  userId={user.id && user.id.toString()}
                />
              </TabPanel>
            </Tab>
          </section>
          <WithDrawCreditsModal
            modal={withdraw}
            creditAmount={user.balance}
            userId={user.id && user.id.toString()}
          />

          <EditProfileModal
            id={user.id}
            modal={editProfileModal}
            userId={user.id}
          />
        </>
      )}
    </BaseCard>
  );
};

export default ShowUser;
