export const generateCodeStrings = (
    code: string,
    count: number,
    suffixLength: number = 0,
    prefixLength: number = 0
): string[] => {
    const generateRandomString = (length: number): string => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return result;
    };


    const codes = new Set<string>();
    while (codes.size < count) {
        const prefix = generateRandomString(prefixLength);
        const suffix = generateRandomString(suffixLength);
        const generatedCode = `${suffix}${code}${prefix}`;
        codes.add(generatedCode); // Ensures uniqueness
    }

    return Array.from(codes);
};
