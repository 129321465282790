import { CounterPlusIcon, MenuDotsIcon } from "components/Svg";
import {
  Table,
  TAction,
  TActionItem,
  TBody,
  Td,
  Th,
  THead,
} from "components/Table";
import { TextTooltip } from "components/tooltips";
import { formatAddressAny } from "utils/address";
import { USERS_CONFIG } from "resources-config/users-config";
import { useCallback, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { BaseKey, useModal } from "@refinedev/core";
import TabletWidgets, {
  TabletWidgetLeft,
  TabletWidgetRight,
} from "components/TableWidgets";
import Badge from "components/Badge";
import { Button } from "components/buttons";
import AddressModal from "components/custom-modals/AddressModal";
import DangerModal from "components/custom-modals/DangerModal";
import { removeAddress } from "api/address";
import { toast } from "sonner";
import EmptySection from "components/EmptySection";
type Props = {
  address: MiscObj[];
  userId?: any;
};

const ShowUserAddressList = (props: Props) => {
  const { address: initialAddress, userId } = props;
  const [address, setAddress] = useState(initialAddress);
  const removeAddressModal = useModal();
  const addNewAddressModal = useModal();
  const [defaultValues, setDefaultValues] = useState<MiscObj | null>(null);
  const [editMode, setEditMode] = useState(false);

  const [addrId, setAddrId] = useState<string | null>(null);

  const toggleActiveUser = useCallback(
    (id: string) => {
      setAddrId(id);
      removeAddressModal.show();
    },
    [removeAddressModal]
  );

  const handleConfirm = async () => {
    try {
      await removeAddress(userId, addrId);
      setAddress((prev) => prev.filter((addr: any) => addr.id !== addrId));

      removeAddressModal.close();
      toast.success("Success");
    } catch (error) {
      console.error(error);
      toast.error("Error");
    }
  };
  const handleAddNewAddress = (newAddress?: any, editAddress?: any) => {
    if (editAddress && editMode) {
      setAddress((prev) =>
        prev.map((addr: any) =>
          addr.id === editAddress.id ? editAddress : addr
        )
      );
    } else if (newAddress) {
      setAddress((prev) => [...prev, newAddress]);
    }
  };

  const handleAddNewAddressBtn = () => {
    setEditMode(false);
    setDefaultValues(null);
    addNewAddressModal.show();
  };

  const handleEdit = (data: any) => {
    setDefaultValues({
      id: data.id,
      postalCode: data.postalCode,
      street: data.street,
      buildingNumber: data.buildingNumber,
      buildingName: data.buildingName,
      floorNumber: data.floorNumber,
      unitNumber: data.unitNumber,
      unitType: data.unitType,
      addressLabel: data.addressLabel,
      latitude: data.latitude,
      longitude: data.longitude,
      notes: data.notes,
    });
    setEditMode(true);
    addNewAddressModal.show();
  };

  return (
    <>
      <TabletWidgets>
        <TabletWidgetLeft>
          <div className="flex gap-2 items-center">
            <Badge color="#9DC7F1" className="!text-[#0B5CAD]">
              {address.length || 0}
            </Badge>
            <span className="text-th-sm">Address found</span>
          </div>
        </TabletWidgetLeft>
        <TabletWidgetRight>
          <Button
            className=""
            shape="outline"
            onClick={() => handleAddNewAddressBtn()}
          >
            <CounterPlusIcon color="#1954A1" />
            Add new address
          </Button>
        </TabletWidgetRight>
      </TabletWidgets>
      {address.length > 0 ? (
        <Table>
          <THead>
            <Th>Postal Code</Th>
            <Th>Full Address</Th>
            <Th>Label</Th>
            <Th>Address Type</Th>
            <Th>Address Notes</Th>
            <Th>Action</Th>
          </THead>
          {address
            ? address.map((data: any) => (
                <TBody key={data.id}>
                  <Td>{data.postalCode}</Td>
                  <Td>{formatAddressAny(data, { showPostalCode: false })}</Td>
                  <Td>{data.addressLabel || "-"}</Td>
                  <Td>{data.unitType}</Td>
                  <Td>
                    {data.notes ? (
                      <TextTooltip
                        position="top"
                        tooltipClassName="w-[300px] break-words "
                        text={data.notes}
                        className="line-clamp-1 underline"
                      >
                        {data.notes}
                      </TextTooltip>
                    ) : (
                      "-"
                    )}
                  </Td>
                  <Td className="flex gap-4 items-center">
                    <TAction
                      className="border-none"
                      toggleText={<MenuDotsIcon />}
                      id={`edit-user-address-${data.id}`}
                    >
                      <TActionItem>
                        <button
                          type="button"
                          className="text-sm"
                          onClick={() => toggleActiveUser(data.id)}
                        >
                          Remove Address
                        </button>
                      </TActionItem>
                    </TAction>

                    <button
                      type="button"
                      className="text-sm font-medium text-primary"
                      onClick={() => handleEdit(data)}
                    >
                      Edit
                    </button>
                  </Td>
                </TBody>
              ))
            : "empty"}
        </Table>
      ) : (
        <EmptySection
          title="No Address Found"
          body="Customer still does not have 
a registered address"
        />
      )}

      <DangerModal
        modal={removeAddressModal}
        handleConfirm={handleConfirm}
        title="Are you sure want to remove this customer address?"
        body="This action can&#39;t be undone and the data will be removed completely."
      />
      <AddressModal
        defaultValues={defaultValues}
        userId={userId}
        modal={addNewAddressModal}
        onAddNewAddress={handleAddNewAddress}
        editMode={editMode}
      />
    </>
  );
};

export default ShowUserAddressList;
