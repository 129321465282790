import { useModalReturnType } from "@refinedev/core";
import { Button } from "components/buttons";
import { AsyncMultiSelectField } from "components/forms";
import { Modal } from "components/popups";
import { baseApi } from "providers/customDataProvider";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";

type Props = {
  modal: useModalReturnType;
  targetItem: MiscObj;
  refetchOrder: () => void;
  isTechExist?: boolean;
};

const UpsellTechModal = (props: Props) => {
  const { control, setValue, getValues, handleSubmit, watch } = useForm();
  const { modal, targetItem, refetchOrder, isTechExist } = props;
  const [defaultValueLeaders, setDefaultValueLeaders] = useState<
    SelectMultiVal | undefined | null
  >(undefined);
  const [defaultValueAssistants, setDefaultValueAssistants] = useState<
    SelectMultiVal | undefined | null
  >(undefined);
  const [technicianNames, setTechnicianNames] = useState<any[]>();
  const [leaderTechNames, setLeaderTechNames] = useState<string[]>([]);
  const [assistantTechNames, setAssistantTechNames] = useState<string[]>([]);

  const technicians = watch();

  const selectedTechnicians = useMemo(() => {
    const leaderTechnicians = technicians.leaderTechnicians || [];
    const assistantTechnicians = technicians.assistantTechnicians || [];
    return [...leaderTechnicians, ...assistantTechnicians];
  }, [technicians]);

  const getTechnicianOptions = async (search: string) => {
    const { data } = await baseApi.get(`/admin/technicians?search=${search}`);
    return data.list.map((tech: MiscObj) => ({
      value: +tech.id,
      label: `${tech.name}${tech.vrn ? ` - ${tech.vrn}` : ""}`,
    }));
  };

  const promiseOptions = (inputValue: string) =>
    new Promise<any[]>((resolve) => {
      resolve(getTechnicianOptions(inputValue));
    });

  useEffect(() => {
    if (!targetItem) return;
    const upsellTechnicians: MiscObj[] = targetItem.itemUpsellTechnicians;
    if (!upsellTechnicians || upsellTechnicians.length == 0) {
      setDefaultValueAssistants([]);
      setDefaultValueLeaders([]);
      return;
    } else {
      setDefaultValueAssistants(
        upsellTechnicians
          .filter((at: any) => at.role != 1 && at.technician != null)
          .map((val: MiscObj) => ({
            value: +val.technician.id,
            label: `${val.technician.name}${
              val.technician.vrn ? ` - ${val.technician.vrn}` : ""
            }`,
          }))
      );
      setDefaultValueLeaders(
        upsellTechnicians
          .filter((at: any) => at.role == 1 && at.technician != null)
          .map((val: MiscObj) => ({
            value: +val.technician.id,
            label: `${val.technician.name}${
              val.technician.vrn ? ` - ${val.technician.vrn}` : ""
            }`,
          }))
      );
    }
  }, [targetItem]);

  useEffect(() => {
    if (defaultValueLeaders) {
      const initialLeaderNames = defaultValueLeaders.map((leader) =>
        leader.label.split("-")[0].trim()
      );
      setLeaderTechNames(initialLeaderNames);
    }
    if (defaultValueAssistants) {
      const initialAssistantNames = defaultValueAssistants.map((assistant) =>
        assistant.label.split("-")[0].trim()
      );
      setAssistantTechNames(initialAssistantNames);
    }
  }, [defaultValueLeaders, defaultValueAssistants]);

  useEffect(() => {
    setTechnicianNames([...leaderTechNames, ...assistantTechNames]);
  }, [leaderTechNames, assistantTechNames]);

  const saveUpsellTechnicians = handleSubmit(async (data) => {
    const { leaderTechnicians, assistantTechnicians } = data;
    if (!targetItem) return;
    try {
      const res = await baseApi.patch(
        `/admin/service-orders/${targetItem.order.id}/items/${targetItem.id}/upsell-technicians`,
        {
          upsellTechnicians: [
            ...leaderTechnicians.map((techId: any) => ({
              id: +techId,
              role: 1,
            })),
            ...assistantTechnicians.map((techId: any) => ({
              id: +techId,
              role: null,
            })),
          ],
        }
      );
      if (res.data.message === "Success") {
        toast.success("Successfully updated upsell technicians");
        modal.close();
      }
    } catch (err: any) {
      toast.error("Failed to update the technician. Please try again.");
      console.log(err);
    } finally {
      refetchOrder();
    }
  });

  return (
    <Modal
      heading={"Upselling Technicians"}
      visible={modal.visible}
      close={modal.close}
      width="30rem"
    >
      <div className="flex flex-col gap-6">
        <div>Edit the technician who Upselling the service</div>
        {defaultValueAssistants && defaultValueLeaders ? (
          <>
            <AsyncMultiSelectField
              control={control}
              fieldName="leaderTechnicians"
              loadOptions={promiseOptions}
              label={"Team Leaders"}
              defaultCfg={{
                value: defaultValueLeaders,
                setValue: setValue,
              }}
              onChange={(selectedOptions) => {
                if (!selectedOptions || selectedOptions.length === 0) {
                  setLeaderTechNames([]);
                } else {
                  const techNames = selectedOptions.map((option) =>
                    option.label.split("-")[0].trim()
                  );
                  setLeaderTechNames(techNames);
                }
              }}
              filterOption={({ value }) => !selectedTechnicians.includes(value)}
            />
            <AsyncMultiSelectField
              control={control}
              fieldName="assistantTechnicians"
              loadOptions={promiseOptions}
              label={"Assistants"}
              defaultCfg={{
                value: defaultValueAssistants,
                setValue: setValue,
              }}
              onChange={(selectedOptions) => {
                if (!selectedOptions || selectedOptions.length === 0) {
                  setAssistantTechNames([]);
                } else {
                  const techNames = selectedOptions.map((option) =>
                    option.label.split("-")[0].trim()
                  );
                  setAssistantTechNames(techNames);
                }
              }}
              filterOption={({ value }) => !selectedTechnicians.includes(value)}
            />
          </>
        ) : (
          <></>
        )}
        <Button
          confirm
          onClick={saveUpsellTechnicians}
          messageConfirmationTitle={`${
            isTechExist
              ? `Are you sure you want to assign ${technicianNames?.join(
                  ", "
                )} to this upsell?`
              : "Are you sure you want to do this ?"
          }`}
          messageConfirmationDesc={`${
            isTechExist
              ? "This action will replace the previous assignment."
              : "You cannot change your decision after confirming."
          }`}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default UpsellTechModal;
