import { useForm } from "@refinedev/react-hook-form"
import { Button } from "components/buttons"
import { BaseCard } from "components/cards"
import { AsyncMultiSelectField, AvatarUploadField, FormsGrid, TextField } from "components/forms"
import { LoadIcon } from "components/Svg"
import { baseApi } from "providers/customDataProvider"
import { useEffect, useState } from "react"
import { FaChevronLeft } from "react-icons/fa"
import { useNavigate, useParams } from "react-router-dom"
import { TECHNICIANS_CONFIG } from "resources-config/technicians-config"
import { toast } from "sonner"
import useSWR from "swr"
import { formatToFormData, getErrorNotif, getSuccessNotif } from "utils/data-formatter"


const ShowTechnician = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [skillsDefaults, setSkillsDefaults] = useState()
    const { refineCore: { onFinish, queryResult, mutationResult }, handleSubmit, control, setError, setValue } = useForm({
        refineCoreProps: {
            resource: TECHNICIANS_CONFIG.name,
            action: 'edit', id: id,
            successNotification(data) {
                let actionUrl = undefined
                if (data && data.data) {
                    actionUrl = `/technicians/${data.data.id}`
                }

                return getSuccessNotif('update', 'technician', actionUrl)
            },
            errorNotification(data) {
                return getErrorNotif('update', 'technician', data)
            },
        },

    })
    const { data, isLoading } = useSWR(`/admin/${id}`, async () => {
        try {
            const res = await baseApi.get(`admin/technicians/${id}`)
            return res.data
        } catch (err: any) {
            toast.error('Failed when getting technician details. ' + err.message)
            return null
        }
    })

    const onSubmit = handleSubmit(async (data) => {
        setIsSubmitting(true)
        try {
            const { password, confirmPassword } = data
            if (password && password != confirmPassword) {
                setError('confirmPassword', { message: "Password confimation doesn't match with the new password" })
                return
            }

            const formData = formatToFormData(data, ['profilePicture', 'skills'])
            await onFinish(formData)
        } catch (err: any) {
            console.log(err)
            toast.error(err.msg)
        } finally {
            setIsSubmitting(false)
        }

    })

    useEffect(() => {
        if (queryResult?.status == 'loading') {
            setSkillsDefaults(undefined)
            return
        }

        if (queryResult?.data && skillsDefaults === undefined)
            setSkillsDefaults(queryResult.data.data.skills.map((skill: any) => ({ value: +skill.id, label: skill.name })))
    }, [queryResult])


    const loadSkillOptions = async (inputValue: string) => {
        const skillRes = await baseApi.get(`/admin/technician-skills?search=${inputValue}`)
        return skillRes.data.map((skill: any) => ({ value: +skill.id, label: skill.name }))
    }
    return <form onSubmit={onSubmit} >
        <BaseCard>
            <Button onClick={() => navigate(-1)} shape="outline" color="gray">
                <FaChevronLeft />
                <span>Back</span>
            </Button>
            {!data || isLoading ?
                <div className="w-full grid place-items-center mt-8">
                    <LoadIcon className="loading-icon" width={36} />
                </div>
                :
                <>

                    <div className="flex items-center justify-between mt-6">
                        <h1 className="font-bold text-th-xl">Profile Settings</h1>
                        <Button isLoading={isSubmitting} shape="filled" type="submit">Save Changes</Button>
                    </div>
                    <section>
                        <div className="mt-8">
                            <AvatarUploadField defaultSrc={data.profilePicture && { src: data.profilePicture, id: data.id }} control={control} fieldName="profilePicture" />
                        </div>
                    </section>
                </>
            }
            <section className="mt-6 border-t py-4">
                <h3 className="font-bold">Profile</h3>
                <span className="text-light">Modify current admin profile data</span>
                <FormsGrid col={2} className="mt-4">
                    <TextField control={control} placeholder="Enter technician's name" label={'Technician Name'} required fieldName="name" />
                    {skillsDefaults &&
                        <AsyncMultiSelectField
                            control={control}
                            fieldName="skills"
                            loadOptions={loadSkillOptions}
                            creatable
                            label={'Skills'}
                            defaultCfg={{
                                value: skillsDefaults,
                                setValue: setValue
                            }}
                        />
                    }
                    <TextField control={control} placeholder="Enter technician's phone number" label={'Phone Number'} required fieldName="phoneNumber" />
                    <TextField control={control} placeholder="Enter technician's email" label={'Email'} fieldName="email" />
                </FormsGrid>
                <TextField control={control} className="mt-4" placeholder="Enter technician's VRN" label={'Vehicle Registration Number'} fieldName="vrn" />
            </section>
            <section className="mt-6">
                <h3 className="font-bold">Password</h3>
                <span className="text-light">Modify current password</span>
                <FormsGrid col={2} className="mt-4">
                    <TextField type="password" control={control} placeholder="Enter technician's new password" label={'New Password'} fieldName="password" />
                    <TextField type="password" control={control} placeholder="Re-enter technician's new password" label={'Confirm New Password'} fieldName="confirmPassword" />
                </FormsGrid>
            </section>
        </BaseCard>
    </form>
}

export default ShowTechnician